import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {Container, Row, Col} from 'react-bootstrap';
import { TextFieldAtom, ModalAtom } from "../../../../components/atoms/atom";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { HELP_MODES } from "../../../../utils/constant";

function AddGroupModal(props) {
    const { show, onClose, onSubmit, mode, groupInfo = {}  } = props;
    const { t } = useTranslation();
    const { helpMode } = useContext(AuthContext);

    const [nameObj, setNameObj] = useState({
        value: groupInfo?.name ? groupInfo.name: '',
        error: '',
    })

    const onAddGroup = () => {
        if (!nameObj.value) {
            setNameObj({...nameObj, error: 'error-group-name-required'});
            return;
        }   
        onSubmit({
            ...groupInfo,
            name: nameObj.value,
        });      
    }

    return (
        <ModalAtom 
            title={t(mode === 'add' ? 'add-group' : 'edit-group')}
            titleHelpText={helpMode === HELP_MODES.ADVANCE ? t('help-advance.add-user-modal-title') : ''}
            show={show} 
            handleClose={onClose}
            handleSubmit={onAddGroup}
            body={ <Container>
                <Row>
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            placeholder={t('name')}
                            required
                            value={nameObj.value}
                            error={nameObj.error}
                            onChange={(e) => {
                                setNameObj({...nameObj, 
                                    value: e.target.value, 
                                    error: e.target.value ? '' : 'error-group-name-required'
                                })
                            }}
                        />
                    </Col>
                </Row>
            </Container>}
        />
    );
}

export default AddGroupModal;
