import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ModalAtom } from '../../../../../../components/atoms/atom';
import { getProcessAttributesTableColumns } from './constant';
import AddAttributeModal from "../add/add-attribute";
import { FilterBar } from "../../../../../../components/molecule/molecule";
import { getSearchAddFilter } from '../../../../../../utils/get-filter-items';
import { searchData } from '../../../../../../utils/search';
import { showToast } from '../../../../../../utils/method';
import { setting } from '../../../../../../store/services/index';
import AttributeSortableList from './sortable-list';

function ProcessSubAttributesList(props) {
    const { selectedProcess, processAttribute, fetchProcessAttributes, attributeTypeList } = props;
    const { t } = useTranslation();
    const [subAttributeModalView, setSubAttributeModalView] = useState('');
    const [selectedSubAttribute, setSelectedSubAttribute] = useState({});
    const [showDeleteModal, setShowdeleteModal] = useState(false);
    const [subAttributesList, setSubAttributeList] = useState(processAttribute?.attributes || []);
    const [filteredSubAttributesList, setFilteredSubAttributesList] = useState(processAttribute?.attributes || []);
    const filterItems = getSearchAddFilter(t, { addTextKey: 'add-attribute' });

    const [leftFilterItems, setLeftFilterItems] = useState(filterItems.left || []);
    const [rightFilterItems] = useState(filterItems.right || []);

    useEffect(() => {
        setSubAttributeList(processAttribute?.attributes);
        setFilteredSubAttributesList(processAttribute?.attributes);
    }, [processAttribute?.attributes]);
    const onSubAttributeEdit = (row, rowIndex) => {
        setSelectedSubAttribute(row);
        setSubAttributeModalView('edit');
    };

    const onSubAttributeDelete = (row, rowIndex) => {
        setSelectedSubAttribute(row);
        setShowdeleteModal(true);
    }
    
    const onDeleteSubAttribute = async () => {
        try {
            const res = await setting.deleteAttributes(selectedSubAttribute?.id);
            showToast(t(res?.message), { type: 'success' });
            fetchProcessAttributes();
            setShowdeleteModal(false);
        } catch(error) {
            showToast(t(error?.message), { type: 'error' });
        }
    }

    const onAddSubAttribute = async (subAttributeInfo) => {
        try {
            const payload = {
                processId: selectedProcess?.id,
                attributeGroupId: processAttribute?.id || '',
                position: subAttributeInfo?.position,
                description: subAttributeInfo?.description,
                label: subAttributeInfo?.label,
                attributeTypeId: subAttributeInfo?.attributeType,
                isRequired: subAttributeInfo?.isRequired,
                isChangeableAV : subAttributeInfo?.isChangeableAV,
                isChangeableWorker : subAttributeInfo?.isChangeableWorker,
                externalSystemIdentifier: subAttributeInfo?.externalSystemIdentifier,
                attributeFormatId: subAttributeInfo?.attributeFormat || null,
                attributeOptions: subAttributeInfo?.attributeOptions || null,
                isVisibleSticky: subAttributeInfo?.isVisibleSticky || false,
                isVisibleDesktop: subAttributeInfo?.isVisibleDesktop || false,
                isVisibleHistory: subAttributeInfo?.isVisibleHistory || false,
            }
            if (subAttributeModalView === 'add') {
                const res = await setting.addAttributes(payload);
                showToast(t(res?.message), { type: 'success' });
               
            } else if (subAttributeModalView === 'edit') {
                const res = await setting.updateAttributes(payload, subAttributeInfo.id);
                showToast(t(res?.message), { type: 'success' });
            }
            fetchProcessAttributes();
            setSubAttributeModalView('');  
        } catch(error){
            showToast(t(error?.message), { type: 'error' });
        }
    }

    const subAttributeColumns = getProcessAttributesTableColumns(t, onSubAttributeEdit, onSubAttributeDelete);

    const onFilterAction = (index, key, info) => {
        if (key === 'search') {
            const localLeftFilterItems = [...leftFilterItems];
            localLeftFilterItems[0].value = info?.value;
            setLeftFilterItems(localLeftFilterItems);
            const filterProcessList = searchData(info?.value, subAttributesList, subAttributeColumns.slice(0, -1));
            setFilteredSubAttributesList(filterProcessList);
        }
        if (key === 'add') {
            setSelectedSubAttribute({});
            setSubAttributeModalView('add');
        }
    };

    const onListItemSortEnd = async (items) => {
        try {
            const res = await setting.reorderAttributes(items.map((item, index)=> {
                return {
                    attribute: item?.id,
                    position: index + 1,
                }
            }));
            showToast(t(res?.message), { type: 'success' });
            fetchProcessAttributes();
        } catch(error) {
            showToast(t(error?.message), { type: 'error' });
        }
    }

    const getPosition = () => {
        if (!filteredSubAttributesList.length) {
            return 1;
        }
        const positions = filteredSubAttributesList.map(attr => attr.position); 
        return Math.max(...positions) + 1;
    };

    return (
        <>
            <FilterBar 
                leftItems={leftFilterItems}
                rightItems={rightFilterItems}
                onFilterAction={onFilterAction} />
           
            <AttributeSortableList 
                listItems={filteredSubAttributesList}
                onListItemSortEnd={onListItemSortEnd}
                onEdit={onSubAttributeEdit}
                onDelete={onSubAttributeDelete}
            />

            {subAttributeModalView && <AddAttributeModal 
                mode={subAttributeModalView}
                subAttributeInfo={selectedSubAttribute}
                show
                onClose={() => {setSubAttributeModalView('');  setSelectedSubAttribute('')} }
                onSubmit={onAddSubAttribute}
                newAttributePosition={getPosition()}
                attributeTypeList={attributeTypeList}
            />}
            <ModalAtom 
                title={t('delete-sub-attribute')}
                body = {<p>{t('delete-sub-attribute-warning')}</p>}
                saveText = "delete"
                show={showDeleteModal} 
                handleClose={() => setShowdeleteModal(false) }
                handleSubmit={onDeleteSubAttribute}
            />
        </>
    );
}

export default ProcessSubAttributesList;
