import React, { useState,useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import FilterBar from "../../components/molecule/filter-bar/filter-bar";
import { AgGridAtom, ModalAtom } from '../../components/atoms/atom';
import {getResourceTableColumns } from './constant';
import { getSearchAddFilter } from '../../utils/get-filter-items';
import { searchData } from '../../utils/search';
import { setting } from '../../store/services/index';
import { showToast, getFormattedDate, showError } from '../../utils/method';
import AddResourceModal from './add/add-resource';
import { AuthContext } from '../../contexts/AuthProvider';
import './resource.scss';
    
function ResourcePage() {
    const { t } = useTranslation();
    const { auth, helpMode } = useContext(AuthContext);
    const { role } = auth;
    const [resouceModalView, setResouceModalView] = useState('');
    const [selectedResouce, setSelectedResouce] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showActivateModal, setShowActivateModal] = useState(false);
    const filterItems = getSearchAddFilter(t, { addTextKey: 'add-resource' });
    const [leftFilterItems, setLeftFilterItems] = useState(filterItems.left || []);
    const [resourceStatusFilter, setResourceStatusFilter] = useState('all');
    const rightItems = [
        {
            key: 'resourceStatus',
            type: 'dropdown',
            value: resourceStatusFilter,
            hideNoOptionSelect: true,
            sortOptions: false,
            options: [{
                value: 'all',
                label: t('all')
            }, {
                value: 'active',
                label: t('active')
            }, {
                value: 'deactive',
                label: t('deactive')
            }]
        }
    ];
    const [rightFilterItems, setRightFilterItems] = useState(rightItems);
    const [resourceList, setResourceList] = useState([]);

    const [filteredResourceList, setFilteredResourceList] = useState([]);
   
    const fetchResources = async () => {
        try{
            let result = await setting.getResource();
            result = (result?.data || []).map((record) => {
                return {
                    ...record,
                    provided: getFormattedDate(record.createdAt)
                }
            })
            setResourceList(result);
            setFilteredResourceList(result);
        } catch(error){
            showError(t, error);
        }
    }
    
    useEffect(() => {
        fetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setRightFilterItems(['SUPER_ADMIN', 'ADMIN'].includes(role) ?
            rightItems.concat(filterItems.right) : rightItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);

    const onResourceEdit = (row, rowIndex) => {
        setSelectedResouce(row);
        setResouceModalView('edit');
    };

    const onResourceDelete = (row, rowIndex) => {
        setSelectedResouce(row);
        setShowDeleteModal(true);
    };

    const onDeleteResource = async () => {
        try {
            const res = await setting.deleteResource(selectedResouce?.id);
            showToast(t(res?.message), { type: 'success' });
            fetchResources();
            setShowDeleteModal(false);
        } catch(error) {
            showToast(t(error?.message), { type: 'error' });
            setShowDeleteModal(false);
        }
    };

    const onResourceStatusChange = (row, rowIndex) => {
        setSelectedResouce(row);
        setShowActivateModal(true);
    }

    const updateResourceStatus = async () => {
        try {
            const payload = {
                name: selectedResouce.name,
                typeId: selectedResouce.type.id,
                locationId: selectedResouce.location.id,
                isActive: !+selectedResouce.isActive,
                externalSystemIdentifier: selectedResouce.externalSystemIdentifier,
            };
            const res = await setting.updateResource(payload, selectedResouce.id);
            showToast(t(res?.message), { type: 'success' });
            fetchResources();
            setShowActivateModal(false);
        } catch(error){
            showToast(t(error?.message), { type: 'error' });
        }
    }

    const onAddResource = async (resourceInfo) => {
        try {
            const payload = {
                name: resourceInfo.name,
                typeId: resourceInfo.type,
                locationId: resourceInfo.location,
                isActive: resourceInfo.isActive,
                externalSystemIdentifier: resourceInfo.externalSystemIdentifier,
            };
            if (resouceModalView === 'add') {
                const res = await setting.addResource(payload);
                showToast(t(res?.message), { type: 'success' });
            } else if (resouceModalView === 'edit') {
                const res = await setting.updateResource(payload, resourceInfo.id);
                showToast(t(res?.message), { type: 'success' });
            }  
            fetchResources();
            setResouceModalView('');  
        } catch(error){
            showToast(t(error?.message), { type: 'error' });
        }
    }
    const resourceColumns = getResourceTableColumns(t, onResourceEdit, onResourceDelete, onResourceStatusChange, role, helpMode);
    const resourceStatusModalText = +selectedResouce.isActive ? t('deactivate') : t('activate');

    const onFilterAction = (index, key, info) => {
        if (key === 'search') {
            const localLeftFilterItems = [...leftFilterItems];
            localLeftFilterItems[0].value = info?.value;
            setLeftFilterItems(localLeftFilterItems);
            const filteredRoleList = searchData(info?.value, resourceList, resourceColumns.slice(0, -1));
            setFilteredResourceList(filteredRoleList);
        }
        if (key === 'add') {
            setSelectedResouce({});
            setResouceModalView('add');
        }
        if (key === 'resourceStatus') {
            const localRightFilterItems = [...rightFilterItems];
            localRightFilterItems[0].value = info?.value;
            setRightFilterItems(localRightFilterItems);
            const { value } = info;
            if (value === 'all') {
                setFilteredResourceList(resourceList);
            } else if (value === 'active') {
                const filteredData = resourceList.filter((res) => +res.isActive);
                setFilteredResourceList(filteredData);
            } else if (value === 'deactive') {
                const filteredData = resourceList.filter((res) => !+res.isActive);
                setFilteredResourceList(filteredData);
            }
            setResourceStatusFilter(value);
        }
    };

    return (
        <>
            <FilterBar 
                leftItems={leftFilterItems}
                rightItems={rightFilterItems}
                onFilterAction={onFilterAction} />

            <div className='resource-grid'>
                <AgGridAtom 
                    columnDefs={resourceColumns}
                    rowData={filteredResourceList}
                />
            </div>
            

            {resouceModalView && <AddResourceModal
                mode={resouceModalView}
                resouceInfo={selectedResouce}
                show
                onClose={() => {setResouceModalView('');  setSelectedResouce('')} }
                onSubmit={onAddResource}
            />}
            <ModalAtom 
                title={t('resource-status-change-modal-title', {status: resourceStatusModalText})} 
                body={<p>{t('resource-status-change-warning', {status: resourceStatusModalText})}</p>}
                saveText={resourceStatusModalText}
                show={showActivateModal} 
                handleClose={() => setShowActivateModal(false) }
                handleSubmit={updateResourceStatus}
            />
            <ModalAtom 
                title={t('delete-resource')} 
                body={<p>{t('delete-resource-warning')}</p>}
                saveText = "delete"
                show={showDeleteModal} 
                handleClose={() => setShowDeleteModal(false) }
                handleSubmit={onDeleteResource}
            />
        </>
    );
}

export default ResourcePage;
