import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TextFieldAtom, ButtonAtom, BrandHeaderAtom } from '../../../components/atoms/atom';
import { auth, setting } from '../../../store/services/index';
import { AuthContext } from '../../../contexts/AuthProvider';
import './login.css';
import { showError } from '../../../utils/method';

function LoginPage() {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        auth: authData,
        setAuth,
    } = useContext(AuthContext);
    const [errorObj, setErrorObj] = useState({
        userName: '',
        password: '',
    });

    const navigateWorker = async (openOrder) => {
        if (!openOrder) {
            navigate('/operator');
        } else {
            const orderDetails = await setting.getOpenOrderDetails(openOrder);
            const orderDetail = orderDetails?.data;
            if (orderDetail) {
                const resourceId = orderDetail.orderProcessSteps[0]?.resource?.id;
                const processStepInfo = await setting.getStepByResource(resourceId);
                setAuth({...auth, isOperatorNavigationDisabled: true});
                navigate('/operator-detail', {
                    state:  {
                        orderDetail, 
                        attributes: processStepInfo.attributes,
                        resourceId,
                    }
                });
            } else {
                navigate('/operator');
            }
        }
    }

    const getUserInfo = async () => {
        let res = '';
        let role = authData?.role;
        let resources = authData?.resources;
        if (!role || !resources?.length) {
            res = await setting.getUserInfo();
            role = res.data.role;
            setAuth({ ...auth, role: res.data.role, name: res.data.name, resources: res.data.resources})
        }
        if (role !== 'WORKER') {
            localStorage.setItem('selectedMenu', 'order');
            navigate('/order/open');
            return;
        } else {
            localStorage.setItem('selectedMenu', 'operator');
            navigateWorker(res.data.openOrder);
            // navigate('/operator');
            return;
        }
    };

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getUserInfo();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLogin = async () => {
        let localErrorObj = {};
        if (!userName) {
            localErrorObj.userName = 'error-user-name-required';
        }
        if (!password) {
            localErrorObj.password = 'error-password-required';
        }
        setErrorObj({...localErrorObj});
        const hasError = Object.keys(localErrorObj).length ;
        if (!hasError){
            try {
                const result = await auth.signIn({
                    userName: userName,
                    password: password
                });
                if (result && result.data && result.data.token) {
                    localStorage.setItem('token', result.data.token);
                    const {role, name, resources} = result.data;
                    setAuth({ ...authData, token: result.data.token, role, name, resources });
                    if (role === 'WORKER') {
                        navigateWorker(result.data.openOrder);
                    } else {
                        navigate('/order/open');
                    }
                    localStorage.setItem('selectedMenu', 'order');

                }
            } catch (error) {
                showError(t, error);
            }
        }
    }
    return (
        <div className="auth">
            <div className="auth-wrapper">
                <BrandHeaderAtom />
                <div className="auth-inner">
                    <h3>{t('sign-in')}</h3>
                    <div className="mb-3">
                        <TextFieldAtom
                            label={t('username')}
                            type="text"
                            required
                            placeholder={t('enter-username')}
                            error={errorObj.userName}
                            value={userName}
                            onKeyUp={(e) => {
                                if (e.code === 'Enter') {
                                    onLogin();
                                }
                            }}
                            onChange={(e) => {
                                setUserName(e.target.value);
                                setErrorObj({ ...errorObj, userName: e.target.value ? '' : 'error-user-name-required' })
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <TextFieldAtom
                            label={t('password')}
                            type="password"
                            required
                            placeholder={t('enter-password')}
                            error={errorObj.password}
                            value={password}
                            onKeyUp={(e) => {
                                if (e.code === 'Enter') {
                                    onLogin();
                                }
                            }}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setErrorObj({ ...errorObj, password: e.target.value ? '' : 'error-password-required' })
                            }}
                        />
                    </div>
                    <div className="d-grid">
                        <ButtonAtom onClick={onLogin}>
                            {t('login')}
                        </ButtonAtom>
                    </div>
                    {/* <p className="forgot-password text-right">
                        <strong className="text-primary" onClick={() => navigate('/forgotPassword')}>{t('forgot-password')}</strong>
                    </p> */}
                </div>
            </div>
        </div>
    )
}
export default LoginPage;
