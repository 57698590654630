/* eslint-disable import/no-anonymous-default-export */
import request from '../request';

const getUser = () => request.get('users');
const addUser = (payload) => request.post('users', payload);
const updateUser = (payload, userId) => request.put(`users/${userId}`, payload);
const deleteUser = (userId) => request.delete(`users/${userId}`);
const getUserInfo = () => request.get('users/info');

const getRole = () => request.get('roles');
const addRole = (payload) => request.post('roles', payload);
const updateRole = (payload, roleId) => request.put(`roles/${roleId}`, payload);
const deleteRole = (roleId) => request.delete(`roles/${roleId}`);

const getLocation = () => request.get('locations');
const addLocation = (payload) => request.post('locations', payload);
const updateLocation = (payload, locationId) => request.put(`locations/${locationId}`, payload);
const deleteLocation = (locationId) => request.delete(`locations/${locationId}`);

const getDepartment = () => request.get('departments');
const addDepartment = (payload) => request.post('departments', payload);
const updateDepartment = (payload, departmentId) => request.put(`departments/${departmentId}`, payload);
const deleteDepartment = (departmentId) => request.delete(`departments/${departmentId}`);

const getResourceType = () => request.get('resource-types');
const addResourceType = (payload) => request.post('resource-types', payload);
const updateResourceType = (payload, resourceTypeId) => request.put(`resource-types/${resourceTypeId}`, payload);
const deleteResourceType = (resourceTypeId) => request.delete(`resource-types/${resourceTypeId}`);

const getGroup = () => request.get('groups');
const addGroup = (payload) => request.post('groups', payload);
const updateGroup = (payload, groupId) => request.put(`groups/${groupId}`, payload);
const deleteGroup = (groupId) => request.delete(`groups/${groupId}`);

const getResourceCategory = () => request.get('resource-categories');
const addResourceCategory = (payload) => request.post('resource-categories', payload);
const updateResourceCategory = (payload, categoryResourceId) => request.put(`resource-categories/${categoryResourceId}`, payload);
const deleteResourceCategory = (categoryResourceId) => request.delete(`resource-categories/${categoryResourceId}`);
const getResourceCategoryWithIcons = () => request.get('resource-categories?populateIcon=true');

const getResourceManufacture = () => request.get('resource-manufacturers');
const addResourceManufacture = (payload) => request.post('resource-manufacturers', payload);
const updateResourceManufacture = (payload, resourceManufactureId) => request.put(`resource-manufacturers/${resourceManufactureId}`, payload);
const deleteResourceManufacture = (resourceManufactureId) => request.delete(`resource-manufacturers/${resourceManufactureId}`);

const getResource = () => request.get('resources');
const addResource = (payload) => request.post('resources', payload);
const updateResource = (payload, resourceId) => request.put(`resources/${resourceId}`, payload);
const deleteResource = (resourceId) => request.delete(`resources/${resourceId}`);
const getStepByProcessStepId = (processStepId) => request.get(`resources/${processStepId}/process-step`);

const getProcess = () => request.get('processes');
const addProcess = (payload) => request.post('processes', payload);
const updateProcess = (payload, processId) => request.put(`processes/${processId}`, payload);
const deleteProcess = (processId) => request.delete(`processes/${processId}`);
const getProcessStepsByProcessId = (processId) => request.get(`processes/${processId}/process-steps`);
const getProcessAttributesByProcessId = (processId) => request.get(`processes/${processId}/attributes-groups`);

const getProcessSteps = () => request.get('process-steps');
const addProcessStep = (payload) => request.post('process-steps', payload);
const updateProcessStep = (payload, processStepId) => request.put(`process-steps/${processStepId}`, payload);
const deleteProcessStep = (processStepId) => request.delete(`process-steps/${processStepId}`);
const addResourceToProcessStep = (processStepId, payload) => request.put(`process-steps/${processStepId}/add-resources`, payload);
const updateProcessStepStatus = (processStepId, payload) => request.put(`orders/${processStepId}/update-process-step-status`, payload);

const getAttributeGroups = () => request.get('attribute-groups');
const addAttributeGroup = (payload) => request.post('attribute-groups', payload);
const updateAttributeGroup = (payload, groupAttributeId) => request.put(`attribute-groups/${groupAttributeId}`, payload);
const deleteAttributeGroups = (groupAttributeId) => request.delete(`attribute-groups/${groupAttributeId}`);
const reorderAttributeGroups = (payload) => request.post('attributes-groups/reorder', payload);

const getAttributeTypes = () => request.get('attribute-types');

const getAttributes = () => request.get('attributes');
const addAttributes = (payload) => request.post('attributes', payload);
const updateAttributes = (payload, attributeId) => request.put(`attributes/${attributeId}`, payload);
const deleteAttributes = (attributeId) => request.delete(`attributes/${attributeId}`);
const reorderAttributes = (payload) => request.post('attributes/reorder', payload);

const attributeFieldMapping = () => request.get('attributes-field-mappings');
const addAttributeFieldMapping = (payload) => request.put('attributes-field-mappings', payload);

const getOrders = (query) => request.get(`orders?${query}`);
const updateOrder = (payload) => request.put('orders', payload);
const updateOrderStatus = (payload) => request.put('orders/update-status', payload);
const orderUpdateProcessStep = (payload) => request.put('orders/add-process-steps', payload);
const getWorkerOrders = (query) => request.get(`orders/worker?${query}`);
const getOrderDetails = (payload) => request.get('orders', payload);
const getArchiveOrders = (query) => request.get(`orders/archive?${query}`);
const getOpenOrderDetails = (orderId, resourceId = null) => request.get(`orders/${orderId}/${resourceId}/details`);
const deleteOrders = (payload) => request.delete('orders', { data: payload });

const getGeneralSetting = () => request.get('system-settings');
const updateGeneralSetting = (payload, id) => request.put(`system-settings/${id}`, payload);

const getClientSetting = () => request.get('client-settings');
const updateClientSetting = (payload, id) => request.put(`client-settings/${id}`, payload);
const updateFilterSetting = (payload, id) => request.put(`process-archive-filter-settings/${id}`, payload);

const createNewOrder = (payload) => request.post('orders', payload);
const getTemplateList = (processId) => request.get(`templates?processId=${processId}`);

const downloadProcessExcel = (processId) => request.get(`processes/${processId}/download-excel`, { responseType: 'arraybuffer' });

const uploadExcelForProcessId = (payload, id) => request.post(`/orders/upload-excel/${id}`, payload, { responseType: 'arraybuffer' });

export default {
    getUser,
    getRole,
    getLocation,
    addRole,
    updateRole,
    addLocation,
    updateLocation,
    addDepartment,
    getDepartment,
    updateDepartment,
    getResourceType,
    addResourceType,
    updateResourceType,
    getGroup,
    getResourceCategory,
    addResourceCategory,
    updateResourceCategory,
    addGroup,
    updateGroup,
    getResourceManufacture,
    addResourceManufacture,
    updateResourceManufacture,
    getResource,
    addResource,
    updateResource,
    addUser,
    updateUser,
    getProcess,
    addProcess,
    updateProcess,
    getProcessStepsByProcessId,
    getProcessAttributesByProcessId,
    deleteUser,
    deleteRole,
    deleteLocation,
    deleteDepartment,
    deleteGroup,
    getProcessSteps,
    addProcessStep,
    updateProcessStep,
    deleteProcessStep,
    addResourceToProcessStep,
    deleteResource,
    deleteResourceType,
    deleteResourceCategory,
    deleteResourceManufacture,
    deleteProcess,
    getAttributeGroups,
    addAttributeGroup,
    updateAttributeGroup,
    deleteAttributeGroups,
    getAttributeTypes,
    getAttributes,
    addAttributes,
    updateAttributes,
    deleteAttributes,
    reorderAttributes,
    attributeFieldMapping,
    addAttributeFieldMapping,
    getUserInfo,
    reorderAttributeGroups,
    getResourceCategoryWithIcons,
    getOrders,
    updateOrder,
    updateOrderStatus,
    orderUpdateProcessStep,
    getWorkerOrders,
    getStepByProcessStepId,
    updateProcessStepStatus,
    getOrderDetails,
    getArchiveOrders,
    getGeneralSetting,
    updateGeneralSetting,
    getOpenOrderDetails,
    getClientSetting,
    updateClientSetting,
    updateFilterSetting,
    createNewOrder,
    getTemplateList,
    deleteOrders,
    downloadProcessExcel,
    uploadExcelForProcessId,
};