import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { setting } from '../../../store/services/index';
import { TextFieldAtom, ModalAtom, DropdownAtom } from "../../../components/atoms/atom";
import { showError } from "../../../utils/method";
import { EXTERNAL_SYSTEM_IDENTIFIER_REGEX, HELP_MODES } from "../../../utils/constant";
import { AuthContext } from "../../../contexts/AuthProvider";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


function AddResourceModal(props) {
    const { show, onClose, onSubmit, mode, resouceInfo = {} } = props;
    const { t } = useTranslation();
    const { helpMode } = useContext(AuthContext);
    const [name, setName] = useState(resouceInfo?.name ? resouceInfo.name: '');
    const [category, setCategory] = useState(resouceInfo?.category?.id ? resouceInfo.category?.id: '');
    const [type, setType] = useState(resouceInfo?.type?.id ? resouceInfo.type?.id: '');
    const [location, setLocation] = useState(resouceInfo?.location?.id ? resouceInfo.location?.id: '');
    const [externalSystemIdentifier, setExternalSystemIdentifier] = useState(resouceInfo?.externalSystemIdentifier || '');

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [filteredTypeOptions, setFilteredTypeOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const fetchDropDownOptions = async () => {
        try {
            const categoryResult = await setting.getResourceCategory();
            const catOptions = (categoryResult.data || []).map((category) => { 
                return {
                    label: category.name,
                    value: category.id,
                }
            });
            setCategoryOptions(catOptions);
            
            const typeResult = await setting.getResourceType();
            const resourceTypeList = typeResult?.data || [];
            const resourceTypeListOptions = resourceTypeList.map((type) => { 
                return {
                    label: type.name,
                    value: type.id,
                }
            });
            setTypeOptions(resourceTypeListOptions);
            setFilteredTypeOptions(resourceTypeListOptions);
            setTypeList(resourceTypeList);

            const locationResult = await setting.getLocation();
            setLocationOptions((locationResult.data || []).map((location) => { 
                return {
                    label: location.name,
                    value: location.id,
                }
            }));
                        
        } catch (error) {
            showError(t, error);
        }
    };

    useEffect(() => {
        fetchDropDownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [errorObj, setErrorObj] = useState({
        name: '',
        type: '',
        location: '',
        externalSystemIdentifier: '',
    });

    const onAddResource = () => {
        let localErrorObj = {};
        if (!name) {
            localErrorObj.name = 'error-resource-name-required';
        }
        if (!type) {
            localErrorObj.type = 'error-resource-type-required';
        }
        if (!location) {
            localErrorObj.location = 'error-resource-location-required';
        }
        if (!externalSystemIdentifier) {
            localErrorObj.externalSystemIdentifier = 'error-external-system-identifier-required';
        }
        setErrorObj({...localErrorObj});
        const hasError = Object.keys(localErrorObj).length ;
        if (!hasError){
            onSubmit({
                ...resouceInfo,
                name,
                type,
                location,
                isActive: true,
                externalSystemIdentifier,
            })
        }       
    };

    const filterTypeOptions = (e) => {
        const cat = e.target.value;
        setCategory(cat);
        setType('');
        if (!cat) {
            setFilteredTypeOptions(typeOptions);
            return;
        }
        const resourceTypeListOptions = typeList.filter((type) => cat === type.category?.id).map((type) => ({
            label: type.name,
            value: type.id,
        }));
        setFilteredTypeOptions(resourceTypeListOptions);
    };

    return (
        <ModalAtom 
            title={t(mode === 'add' ? 'add-resource' : 'edit-resource')}
            show={show} 
            handleClose={onClose}
            handleSubmit={onAddResource}
            body={ <Container>
                <Row>
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            placeholder={t('name')}
                            required
                            value={name}
                            error={errorObj.name}
                            onChange={(e) => {
                                setName(e.target.value);
                                setErrorObj({ ...errorObj, name: e.target.value ? '' : 'error-resource-name-required' })
                            }}
                        />
                    </Col>
                    <Col>
                        <DropdownAtom 
                            placeholder={t('select-location')}
                            value={location}
                            required
                            options= {locationOptions}
                            error={errorObj.location}
                            onChange={(e) => {
                                setLocation(e.target.value);
                                setErrorObj({ ...errorObj, location: e.target.value ? '' : 'error-resource-location-required' })
                            }}                       
                        />
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <DropdownAtom 
                            placeholder={t('select-category')}
                            value={category}
                            options= {categoryOptions}
                            error={errorObj.category}
                            onChange={filterTypeOptions}                        
                        />
                    </Col>
                    <Col>
                        <DropdownAtom 
                            placeholder={t('select-type')}
                            value={type}
                            required
                            options= {filteredTypeOptions}
                            error={errorObj.type}
                            onChange={(e) => {
                                setType(e.target.value);
                                setErrorObj({ ...errorObj, type: e.target.value ? '' : 'error-resource-type-required' })
                            }}                        
                        />
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={helpMode === HELP_MODES.ADVANCE ? <Tooltip>{t(`help-${helpMode}.resource-external-system-identifier`)}</Tooltip> : <></>}
                        >
                            <span>
                                <TextFieldAtom 
                                    type="text"
                                    placeholder={t('external-system-identifier')}
                                    required
                                    value={externalSystemIdentifier}
                                    error={errorObj.externalSystemIdentifier}
                                    onChange={(e) => {
                                        if (e.target.value.match(EXTERNAL_SYSTEM_IDENTIFIER_REGEX)) {
                                            setExternalSystemIdentifier(e.target.value.toUpperCase());
                                            setErrorObj({...errorObj, 
                                                externalSystemIdentifier: e.target.value ? '' : 'error-external-system-identifier-required'
                                            })
                                        }
                                    }}
                                />
                            </span>
                        </OverlayTrigger>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </Container>}
        />
    );
}

export default AddResourceModal;
