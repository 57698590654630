import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import TableActionButtonAtom from '../../../components/atoms/table-action-button/table-action-button';
import { HELP_MODES } from '../../../utils/constant';

const getGroupTableColumns = (t, onGroupEdit, onGroupDelete, helpMode = HELP_MODES.BASIC) => {
    return [
        {
            field: 'provided',
            headerName: t('provided'),
            unSortIcon: true,
        },
        {
            field: 'name',
            headerName: t('name'),
            unSortIcon: true,
        },
        {
            headerName: t('action'),
            sortable: false,
            maxWidth: 120,
            cellRenderer: (props) => {
                const {data, rowIndex} = props;
                return <div className="d-flex ag-action-cell">
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faEdit}
                        onClickAction={() => onGroupEdit(data, rowIndex)}
                        tooltipTitle='action-edit'
                    />
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faTrash}
                        className="mx-2"
                        onClickAction={() => onGroupDelete(data, rowIndex)}
                        tooltipTitle='action-delete'
                    />
                </div>
            }
        }
    ];
};

export {
    getGroupTableColumns,
}