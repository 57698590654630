import React, { useState, useContext } from "react";
import Accordion from 'react-bootstrap/Accordion';
import "./settings-menu.scss";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import {getMenuItems} from "./constant";
import MenuItem from "../../../components/atoms/menu-item/menu-item";
import { AuthContext } from '../../../contexts/AuthProvider';


function SettingsMenu() {
    const { t } = useTranslation();
    const location = useLocation();
    const { auth } = useContext(AuthContext);
    // eslint-disable-next-line no-unused-vars
    const [selectedMenuItem, setSelectedMenuItem] = useState(() => {
        if (location.pathname.includes('settings')) {
            const pathname = location?.pathname?.substring(1);
            const path = pathname.split('/');
            if (!path.length) {
                return pathname;
            }
            return path[path.length - 1];
        }
        return 'user';
    });

    const currentSelection = t(`menu-${selectedMenuItem}`);

    return (
        <Accordion className="mt-4 settings-accordian">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <span className="accordian-label">{t('menu-setup')}</span>
                    <span className="accordian-title">{currentSelection}</span>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="accordian-body-container container mx-0">
                        <div className="row align-items-start">
                            <MenuItem
                                menuItems={getMenuItems(t, auth.role)}
                                selectedMenuItem={selectedMenuItem}
                            />
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default SettingsMenu;