import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TextFieldAtom, ModalAtom, DropdownAtom } from "../../../../components/atoms/atom";
import { setting } from "../../../../store/services";
import { showError } from "../../../../utils/method";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { HELP_MODES } from "../../../../utils/constant";

function AddTypeModal(props) {
    const { show, onClose, onSubmit, mode, typeInfo = {} } = props;
    const { t } = useTranslation();
    const { helpMode } = useContext(AuthContext);
    const [name, setName] = useState(typeInfo?.name || '');
    const [category, setCategory] = useState(typeInfo?.category?.id || '');
    const [manufacturer, setManufacturer] = useState(typeInfo?.manufacturer?.id || '');
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [manufactureOptions, setManufactureOptions] = useState([]);

    const [errorObj, setErrorObj] = useState({
        name: '',
        category: '',
        manufacturer: '',
    });

    const onAddType = () => {
        let localErrorObj = {};
        if (!name) {
            localErrorObj.name = 'error-type-required';
        }
        if (!category) {
            localErrorObj.category = 'error-category-required';
        }
        if (!manufacturer) {
            localErrorObj.manufacture = 'error-manufacturer-required';
        }
        setErrorObj({...localErrorObj});
        const hasError = Object.keys(localErrorObj).length ;
        if (!hasError) {
            onSubmit({
                ...typeInfo,
                name: name,
                category,
                manufacturer,
            }); 
        }     
    }

    const fetchDropDownOptions = async () => {
        try {
            const categoryResult = await setting.getResourceCategory();
            setCategoryOptions((categoryResult.data || []).map((category) => { 
                return {
                    label: category.name,
                    value: category.id,
                }
            }));

            const manufactureResult = await setting.getResourceManufacture();
            setManufactureOptions((manufactureResult.data || []).map((manufacture) => { 
                return {
                    label: manufacture.name,
                    value: manufacture.id,
                }
            }));
                        
        } catch (error) {
            showError(t, error);
        }
    };

    useEffect(() => {
        fetchDropDownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ModalAtom 
            title={t(mode === 'add' ? 'add-type' : 'edit-type')}
            titleHelpText={helpMode === HELP_MODES.ADVANCE ? t('help-advance.add-type-modal-title') : ''}
            show={show} 
            handleClose={onClose}
            handleSubmit={onAddType}
            body={ <Container>
                <Row>
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            placeholder={t('name')}
                            required
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                setErrorObj({...errorObj, 
                                    name: e.target.value ? '' : 'error-type-required'
                                });
                            }}
                            error={errorObj.name}
                        />
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <DropdownAtom 
                            placeholder={t('select-category')}
                            value={category}
                            required
                            options= {categoryOptions}
                            error={errorObj.category}
                            onChange={(e) => {
                                setCategory(e.target.value);
                                setErrorObj({ ...errorObj, category: e.target.value ? '' : 'error-category-required' })
                            }}                        
                        />
                    </Col>
                    <Col>
                        <DropdownAtom 
                            type="text"
                            placeholder={t('select-manufacturer')}
                            value={manufacturer}
                            required
                            options= {manufactureOptions}
                            error={errorObj.manufacture}
                            onChange={(e) => {
                                setManufacturer(e.target.value);
                                setErrorObj({ ...errorObj, manufacturer: e.target.value ? '' : 'error-manufacture-required' })
                            }}
                        />
                    </Col>
                </Row>
            </Container>}
        />
    );
}

export default AddTypeModal;
