import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {Container, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap';
import clone from 'rfdc/default';

import { FilterBar } from "../../../../components/molecule/molecule";
import SettingsMenu from '../../settings-menu/settings-menu';
import { TextFieldAtom, DropdownAtom, ButtonAtom, CheckboxAtom } from '../../../../components/atoms/atom';
import { showError, showSuccess } from "../../../../utils/method";
import { setting } from '../../../../store/services/index';
import './archive-filter.scss';
import { AuthContext } from "../../../../contexts/AuthProvider";
import { HELP_MODES } from "../../../../utils/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

function ArchiveFilter(props) {
    const { selectedProcess, onBack } = props;
    const { t } = useTranslation();
    const { helpMode } = useContext(AuthContext);
    const [duration, setDuration] = useState();
    const [showCreatedAtDate, setShowCreatedAtDate] = useState(false);
    const [errorObj, setErrorObj] = useState({
        duration: '',
    });
    const [systemSettingId, setSystemSettingId] = useState(null);
    const [archiveFilterSettings, setArchiveFilterSettings] = useState(selectedProcess?.archiveFilterSettings);
    const [attributeOne, setAttributeOne] = useState('');
    const [attributeTwo, setAttributeTwo] = useState('');
    const [attributeThree, setAttributeThree] = useState('');
    const [attributes, setAttributes] = useState([]);

    const leftFilterItems = [
        {
            type: 'backButton',
            key: 'back'
        },
        { 
            type: 'button', 
            key: 'add',
            disabled: true,
            label: <div className="d-flex">
                <p className="text-uppercase m-0">{selectedProcess?.name}</p>
            </div>, 
            className: "selected-process filter-bar-btn"
        }
    ];

    const fetchAttributes = async () => {
        try {
            let attributeGroupResult = await setting.getProcessAttributesByProcessId(selectedProcess?.id);
            attributeGroupResult = attributeGroupResult?.result || [];
            const attributesResult = attributeGroupResult.reduce((acc, ag) => {
                acc.push(...ag.attributes);
                return acc
            }, []);
            const attrOptions = attributesResult.map((category) => { 
                return {
                    label: category.label,
                    value: category.id,
                }
            });
            setAttributes(attrOptions);
                        
        } catch (error) {
            showError(t, error);
        }
    }

    const fetchGeneralSettings = async () => {
        try {
            let response = archiveFilterSettings || {};
            setSystemSettingId(response?.id || null);
            setDuration(response?.archiveDuration);
            setShowCreatedAtDate(response?.showCreatedAtDate);
            setAttributeOne(response?.orderFilter1 || '');
            setAttributeTwo(response?.orderFilter2 || '');
            setAttributeThree(response?.orderFilter3 || '');
        } catch (error) {
            showError(t, error);
        }
    };
    useEffect(() => {
        fetchAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setArchiveFilterSettings(selectedProcess?.archiveFilterSettings);
        fetchGeneralSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProcess])

    const onSave = async () => {
        if (!duration || duration < 1) {
            setErrorObj({...errorObj, duration: !duration ? 'error-duration-required': 'error-greaterthan-one' });
            return
        }
        try {
            const archiveFilterPayload = {
                archiveDuration: duration,
                showCreatedAtDate: showCreatedAtDate,
                orderFilter1: attributeOne || null,
                orderFilter2: attributeTwo || null,
                orderFilter3: attributeThree || null,
            }
            const response = await setting.updateFilterSetting(archiveFilterPayload, systemSettingId);
            // updateOrderMasterData(archiveFilterPayload);
            showSuccess(t, response?.message);
        } catch (error) {
            showError(t, error);
        }

    }

    const getAttributeOptions = (attributeKeyIndex) => {
        let selectedAtttribute = [attributeOne, attributeTwo, attributeThree ]
        selectedAtttribute.splice(attributeKeyIndex, 1);
        selectedAtttribute = selectedAtttribute.filter((att) => (att || '').length)
        if (selectedAtttribute.length) {
            let attOptions = clone(attributes);
            return attOptions.filter((att) => !selectedAtttribute.includes(att.value));
        }
        return attributes;        
    }
    
    const onFilterAction = (index, key, info) => {
        if (key === 'back') {
            onBack();
        }
    };

    return (
        <>
            <SettingsMenu />
            <FilterBar 
                leftItems={leftFilterItems}
                // rightItems={filters.right}
                onFilterAction={onFilterAction} 
            />
            <Container fluid style={{ marginTop: 20, padding: 0 }} className="archive-filter-container">
                <Row>
                    <Col>
                        <p>{t('archive-settings')}&nbsp;
                            {helpMode === HELP_MODES.ADVANCE && <OverlayTrigger overlay={<Tooltip>{t('help-advance.process-setting-archive-setting')}</Tooltip>}>
                                <FontAwesomeIcon icon={faCircleInfo} />
                            </OverlayTrigger>}
                        </p>
                        <Row>
                            <Col>
                                <TextFieldAtom 
                                    type="number"
                                    label={t('archive-duration')}
                                    placeholder={t('duration')}
                                    required
                                    value={duration}
                                    error={errorObj.duration}
                                    onChange={(e) => {
                                        setDuration(e.target.value);
                                        setErrorObj({ ...errorObj, duration: e.target.value ? e.target.value < 1 ? 'error-greaterthan-one' : '' : 'error-duration-required' })
                                    }}
                                />
                            </Col>
                            <Col /> 
                        </Row>
                    </Col>
                    <Col>
                        <p>{t('order-table-settings')}&nbsp;
                            {helpMode === HELP_MODES.ADVANCE && <OverlayTrigger overlay={<Tooltip>{t('help-advance.process-setting-order-table-setting')}</Tooltip>}>
                                <FontAwesomeIcon icon={faCircleInfo} />
                            </OverlayTrigger>}
                        </p>
                        <Row>
                            <CheckboxAtom
                                label={t('order-table-date-column')}
                                checked={showCreatedAtDate}
                                onChange={() => setShowCreatedAtDate(!showCreatedAtDate)} />
                        </Row>
                    </Col>
                </Row>
                <br />
                <p>{t('sort-filters')}&nbsp;
                    {helpMode === HELP_MODES.ADVANCE && <OverlayTrigger overlay={<Tooltip>{t('help-advance.process-setting-standard-sorting-setting')}</Tooltip>}>
                        <FontAwesomeIcon icon={faCircleInfo} />
                    </OverlayTrigger>}
                </p>
                <Row className="mt-4 mb-4 align-items-center d-flex">
                    <label>{t('order-default-sort-attributes')}</label>
                    <Col>
                        <DropdownAtom 
                            placeholder={t('select-attribute')}
                            value={attributeOne}
                            options= {getAttributeOptions(0)}
                            onChange={(e) => {
                                setAttributeOne(e.target.value)
                            }}                        
                        />
                    </Col>
                    <Col>
                        <DropdownAtom 
                            placeholder={t('select-attribute')}
                            value={attributeTwo}
                            options= {getAttributeOptions(1)}
                            onChange={(e) => {
                                setAttributeTwo(e.target.value);
                            }}                        
                        />
                    </Col>
                    <Col>
                        <DropdownAtom 
                            placeholder={t('select-attribute')}
                            value={attributeThree}
                            options= {getAttributeOptions(2)}
                            onChange={(e) => {
                                setAttributeThree(e.target.value);
                            }}                        
                        />
                    </Col>
                    <Col />
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <ButtonAtom 
                            onClick={() => onSave()}>
                            {t('save')}
                        </ButtonAtom>
                        <ButtonAtom 
                            style={{ marginLeft: 4 }}
                            className="btn btn-secondary"
                            onClick={() => onBack()}>
                            {t('cancel')}
                        </ButtonAtom>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ArchiveFilter;
