import React from "react";
import { useTranslation } from "react-i18next";
import { DropdownAtom } from "../../../../components/atoms/atom";

function CustomDropdown(props) {
    const { data, defaultSelectedRow, onProcessStepResourceUpdate } = props;
    const { t } = useTranslation();

    const dropdownOptions = data.resources.map((dept) => (
        {
            label: dept.name,
            value: dept.id,
        }
    ));

    const processStep = defaultSelectedRow.find((op) => op.processStepId === data.id);
    let selectedOption = '';
    let disabled = '';
    if (processStep) {
        selectedOption = processStep.resourceId;
    } else {
        disabled = 'disabled';
    }

    const onChange = (event) => {
        const selectedRows = [...defaultSelectedRow];
        const rowToUpdate = selectedRows.find((row) => row.processStepId === data.id);
        if (rowToUpdate) {
            rowToUpdate.resourceId = event.target.value;
        }
        onProcessStepResourceUpdate(selectedRows);
    };

    const customClass = processStep && !selectedOption ? 'error form-control' : null;

    return (
        <DropdownAtom
            placeholder={t('select-resource')}
            options= {dropdownOptions}
            value={selectedOption}
            onChange={onChange}
            disabled={disabled}
            className={customClass}
        />
    )
}

export default CustomDropdown;