import React from "react";
import { useTranslation } from "react-i18next";

function DropdownAtom(props) {
    const { label, className, placeholder, value, options = [], hideNoOptionSelect = false, onChange, error ='', required = false, sortOptions = true, disabled = '', shouldTranslate = false } = props;
    const { t } = useTranslation();

    const getOptions = () => {
        if (!sortOptions) {
            return options;
        }
        return options.sort((op1, op2) => {
            return op1.label.localeCompare(op2.label);
        })
    }

    return (
        <>
            {label ? <label>{label}</label> : ''}
            
            <select 
                className={className || "form-control"} 
                value={value}
                disabled={disabled}
                onChange={onChange}>
                {hideNoOptionSelect ? '' : <option value=''>{(required ? `${placeholder}*` : placeholder) || 'Select'}</option>}
                {getOptions().map((option) => {
                    return  <option value={option.value} key={option.value}>{shouldTranslate ? t(option.label) : option.label}</option>
                })}
            </select>
            {error ? <p className="text-danger">{ t(error)}</p> : '' }
        </>
    );
}

export default DropdownAtom;
