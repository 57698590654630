import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import { ModalAtom, AgGridAtom } from "../../../../../components/atoms/atom";
import { getProcessStepResourceTableColumns } from './constant';
import { setting } from '../../../../../store/services/index';
import { showError } from "../../../../../utils/method";
import { AuthContext } from "../../../../../contexts/AuthProvider";
import { HELP_MODES } from "../../../../../utils/constant";

function ProcessStepResourceModal(props) {
    const { show, onClose, onSubmit, defaultSelectedRow = [] } = props;
    const { t } = useTranslation();
    const { helpMode } = useContext(AuthContext);
    const [processStepResource, setProcessStepResource] = useState([]);
    const [selectedRow, setSelectedRow] = useState(defaultSelectedRow || []);

    const fetchResources = async () => {
        try{
            let result = await setting.getResource();
            setProcessStepResource(result.data);
        } catch(error) {
            showError(t, error);
        }
    }
    
    useEffect(() => {
        fetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSaveProcessStepResource = () => {
        onSubmit(selectedRow);
    }

    const processStepResourceColumns = getProcessStepResourceTableColumns(t, selectedRow);

    const onRowSelection = (selectedRows) => {
        const selectedIds = selectedRows.map((row) => row.id);
        setSelectedRow(selectedIds);
    }

    return (
        <ModalAtom 
            title={t('resource')}
            titleHelpText={helpMode === HELP_MODES.ADVANCE ? t('help-advance.process-step-resource-title') : ''}
            show={show} 
            handleClose={onClose}
            handleSubmit={onSaveProcessStepResource}
            body={ <Container>
                <AgGridAtom 
                    columnDefs={processStepResourceColumns}
                    rowData={processStepResource || []}
                    rowSelection={'multiple'}
                    onRowSelection={onRowSelection}
                />
            </Container>}
        />
    );
}

export default ProcessStepResourceModal;
