import React, { useMemo, useRef, useState, useEffect, useImperativeHandle, forwardRef } from "react";
import {AgGridReact} from 'ag-grid-react';
import "./ag-grid.scss";
import AG_GRID_LOCALE_EN from "./locale.en";
import AG_GRID_LOCALE_DE from "./locale.de";
import {
    iconSortUnSort,
    iconSortAscending,
    iconSortDescending,
} from '../../../assets/images/ag-grid';

function AgGridAtom(props, ref) {
    const gridRef = useRef();
    const { columnDefs, onRowSelection = () => {}, gridHeight = '55vh', updateRowOrder = () => {}, ...rest } = props;
    let { rowData } = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const gridStyle = useMemo(() => ({ height: gridHeight, width: '100%' }), []);
    
    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            flex: 1,
            minWidth: 100,
        };
    }, []);
    const [key, setKey] = useState(Math.random());
    const locale = localStorage.getItem('i18nextLng');
    const [localeText, setLocaleText] = useState(locale === 'de' ? AG_GRID_LOCALE_DE : AG_GRID_LOCALE_EN);

    useEffect(() => {
        const locale = localStorage.getItem('i18nextLng');
        setKey(Math.random());
        setLocaleText(locale === 'de' ? AG_GRID_LOCALE_DE : AG_GRID_LOCALE_EN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem('i18nextLng')]);

    useImperativeHandle(ref, () => ({
        filterData: (filterText) => {
            gridRef.current.api.setQuickFilter(filterText);
        }
    }));

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (onRowSelection) {
            onRowSelection(selectedRows);
        }
    };

    const onRowDragEnd = () => {
        var itemsToUpdate = [];

        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            const data = rowNode.data;
            data.position = index + 1;
            itemsToUpdate.push(data);
        });
      
        updateRowOrder(itemsToUpdate);
    }
    
    return (
        <div style={gridStyle} className="mt-3 ag-theme-alpine">
            <AgGridReact
                key={key}
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={rowData}
                defaultColDef={defaultColDef}
                suppressRowHoverHighlight={true}
                suppressCellFocus={true}
                onSelectionChanged={onSelectionChanged}
                localeText={localeText}
                tooltipShowDelay={0}
                stopEditingWhenCellsLoseFocus={true}
                onRowDragEnd={onRowDragEnd}
                suppressRowClickSelection={true}
                icons={{
                    sortAscending: `<img src="${iconSortAscending}" style="height: 16px; width: 16px;"/>`,
                    sortDescending: `<img src="${iconSortDescending}" style="height: 16px; width: 16px;"/>`,
                    sortUnSort: `<img src="${iconSortUnSort}" style="height: 16px; width: 16px;"/>`,
                }}
                {...rest}
            />
        </div>
    );
}

export default forwardRef(AgGridAtom);
