import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Helmet } from "react-helmet";
import {ErrorBoundary} from 'react-error-boundary';
import LoginPage from "./pages/auth/login/login";
import AuthProvider from './contexts/AuthProvider';
import ForgotPassword from './pages/auth/forgot-password/forgot-password';
import ResetPassword from './pages/auth/reset-password/reset-password';
import AuthenticatedRoutes from "./pages/authenticated-routes/authenticated-routes";

// Setting Page Routes here
import UserSettingPage from "./pages/settings/user/user";
import RoleSettingPage from './pages/settings/role/role';
import LocationSettingPage from './pages/settings/location/location';
import GroupSettingPage from './pages/settings/group/group';
import DepartmentSettingPage from "./pages/settings/department/department";
import ResourceCategoryPage from "./pages/settings/category/category";
import ResourceManufacturePage from "./pages/settings/manufacturer/manufacturer";
import ResourceTypePage from "./pages/settings/type/type";
import ResourcePage from "./pages/asset/resource";
import ProcessSettingPage from "./pages/settings/process/process";

import DashboardPage from './pages/dashboard/dashboard';
import OrdersPage from './pages/order/orders';
import ArchivePage from "./pages/order/archive/archive";
import OperatorPage from './pages/operator/operator';
import { Container } from "react-bootstrap";
import OperatorDetailPage from "./pages/operator/operator-detail";
import SettingPage from "./pages/settings/settings";
import RootOperator from "./pages/operator/root-operator";
import OpenOrderPage from './pages/order/open-order/open-order';
import ClientSettingsPage from "./pages/settings/client-settings/settings";
import ErrorFallback from './pages/error/error';
import ImportExportPage from './pages/import-export/import-export';
import { isAndroidDevice } from "./utils/method";
import "./App.css";

function App() {
    const [explode, setExplode] = useState(false);

    const onTouchEnd = () => {
        if (window?.visualViewport?.scale > 0.75) {
            const viewportmeta = document.querySelector('meta[name=viewport]');
            viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.1, minimum-scale=1.1");
            viewportmeta.setAttribute('content', "width=device-width, initial-scale=0.75, minimum-scale=0.75");
        }
    };

    useEffect(() => {
        if (isAndroidDevice()) {
            window.addEventListener('touchend', onTouchEnd);
        }

        return () => {
            window.removeEventListener('touchend', onTouchEnd);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthProvider>
            <Helmet>
                {isAndroidDevice()
                    ? <meta name="viewport" content="width=device-width, initial-scale=0.75, minimum-scale=0.75" />
                    : <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                }
            </Helmet>
            <ToastContainer
                position="bottom-center"
                theme="colored"
                autoClose={2000}
                pauseOnFocusLoss={false}
            />
            <Router>
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onReset={() => setExplode(false)}
                    resetKeys={[explode]}
                >
                    <Routes>
                        <Route path="/" element={<LoginPage />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                        <Route path="/resetPassword" element={<AuthenticatedRoutes element={<ResetPassword />}/>} />

                        <Route path="/operator" element={<AuthenticatedRoutes element={<RootOperator><OperatorPage /></RootOperator>} showHeader={false} />}/>
                        <Route path="/operator-detail" element={<AuthenticatedRoutes element={<RootOperator><OperatorDetailPage /></RootOperator> } showHeader={false} />}/>

                        <Route path="/dashboard" element={<AuthenticatedRoutes element={<DashboardPage />}/>}/>

                        <Route path="order" element={<div><AuthenticatedRoutes element={<OrdersPage />}/><Outlet /></div>}>
                            <Route path="open" element={<Container fluid><OpenOrderPage/> </Container>} />
                            <Route path="archive" element={<Container fluid><ArchivePage /></Container>}/>    
                        </Route>

                        <Route path="asset" element={<AuthenticatedRoutes element={<ResourcePage />}/>} />

                        {/* Setting Page Routes here */}
                        <Route path="/settings/user" element={<AuthenticatedRoutes element={<SettingPage pagekey="user"><UserSettingPage /></SettingPage>}/>} />
                        <Route path="/settings/location" element={<AuthenticatedRoutes element={<SettingPage pagekey="location"><LocationSettingPage /></SettingPage>}/>} />
                        <Route path="/settings/group" element={<AuthenticatedRoutes element={<SettingPage pagekey="group"><GroupSettingPage /></SettingPage>}/>} />
                        <Route path="/settings/department" element={<AuthenticatedRoutes element={<SettingPage pagekey="department"><DepartmentSettingPage /></SettingPage>}/>} />
                        <Route path="/settings/process" element={<AuthenticatedRoutes element={<SettingPage pagekey="process"><ProcessSettingPage /></SettingPage>}/>} />
                        <Route path="/settings/role" element={<AuthenticatedRoutes element={<SettingPage pagekey="role"><RoleSettingPage /></SettingPage>}/>} />
                        <Route path="/settings/category" element={<AuthenticatedRoutes element={<SettingPage pagekey="category"><ResourceCategoryPage /></SettingPage>}/>} />
                        <Route path="/settings/manufacturer" element={<AuthenticatedRoutes element={<SettingPage pagekey="manufacturer"><ResourceManufacturePage /></SettingPage>}/>} />
                        <Route path="/settings/type" element={<AuthenticatedRoutes element={<SettingPage pagekey="type"><ResourceTypePage /></SettingPage>}/>} />
                        <Route path="/settings/client-settings" element={<AuthenticatedRoutes element={<SettingPage pagekey="client-settings"><ClientSettingsPage /></SettingPage>}/>} />
                        
                        {/* Import-Export Routes here */}
                        <Route path="/import-export" element={<AuthenticatedRoutes element={<ImportExportPage />}/>} />
                    </Routes>
                </ErrorBoundary>
            </Router>
        </AuthProvider>    
    );
}

export default App;
