import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { HELP_MODES } from '../../../utils/constant';

const getUserTableColumns = (t, onUserEdit, onUserDelete, helpMode = HELP_MODES.BASIC) => {
    return [
        {
            field: 'provided',
            headerName: t('provided'),
            headerTooltip: t('provided'),
            unSortIcon: true,
            maxWidth: 120,
        },
        {
            field: 'userName',
            headerName: t('username'),
            headerTooltip: t('username'),
            unSortIcon: true,
        },
        {
            field: 'name',
            headerName: t('name'),
            headerTooltip: t('name'),
            unSortIcon: true,
            cellRenderer: (props) => {
                const {firstName, lastName} = props.data;
                return `${lastName}, ${firstName}`;
            },
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                const nameA = `${nodeA?.data?.lastName}, ${nodeA?.data?.firstName}`;
                const nameB = `${nodeB?.data?.lastName}, ${nodeB?.data?.firstName}`;
                if (nameA === nameB) return 0;
                return (nameA > nameB) ? 1 : -1;
            }
        },
        {
            field: 'contactNumber',
            sortable: false,
            headerName: t('contact-data'),
            headerTooltip: t('contact-data'),
            cellRenderer: (props) => {
                const { contactNumber, email } = props.data;
                return <div className="contact-data-cell">
                    <span>{contactNumber}</span>
                    <span>{email}</span>
                </div>;
            }
        },
        {
            field: 'role.name',
            sortable: false,
            headerName: t('role'),
            headerTooltip: t('role'),
            maxWidth: 100,
        },
        {
            field: 'department.name',
            sortable: false,
            headerName: t('department'),
            headerTooltip: t('department'),
            maxWidth: 100,
        },
        {
            field: 'group.name',
            sortable: false,
            headerName: t('group'),
            headerTooltip: t('group'),
            maxWidth: 100,
        },
        {
            field: 'operatingMaterial',
            sortable: false,
            headerName: t('operating-material'),
            headerTooltip: t('operating-material'),
            cellRenderer: (props) => {
                const { resources } = props.data;
                const names = (resources || []).map((material) => material.name);
                return names.join(', ');
            },
        },
        {
            headerName: t('action'),
            headerTooltip: t('action'),
            sortable: false,
            maxWidth: 120,
            cellRenderer: (props) => {
                const {data, rowIndex} = props;
                return <div className="d-flex ag-action-cell">
                    <p onClick={() => onUserEdit(data, rowIndex)} title={t(`help-${helpMode}.action-edit`)}>
                        <FontAwesomeIcon icon={faEdit} className="fa-lg" />
                    </p>
                    <p onClick={() => onUserDelete(data, rowIndex)}
                        className="mx-2" title={t(`help-${helpMode}.action-delete`)}>
                        <FontAwesomeIcon icon={faTrash} className="fa-lg" />
                    </p>
                </div>
            },
        }
    ];
}

export {
    getUserTableColumns,
}