import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TextFieldAtom, ModalAtom, IconUpload } from "../../../../components/atoms/atom";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { HELP_MODES } from "../../../../utils/constant";

function AddCategoryModal(props) {
    const { show, onClose, onSubmit, mode, categoryInfo = {} } = props;
    const { t } = useTranslation();
    const { helpMode } = useContext(AuthContext);
    const [name, setName] = useState(categoryInfo?.name || '');
    const [iconFile, setIconFile] = useState(categoryInfo?.icon || null);
    const [iconPreview, setIconPreview] = useState(categoryInfo?.icon || null);

    const [errorObj, setErrorObj] = useState({
        name: '',
        iconFile: '',
    });

    const onAddCategory = () => {
        let localErrorObj = {};
        if (!name) {
            localErrorObj.name = 'error-category-name-required';
        }
        if (!iconFile) {
            localErrorObj.iconFile =  'error-icon-required';
        }
        setErrorObj({...localErrorObj});
        const hasError = Object.keys(localErrorObj).length ;
        if (!hasError) {
            onSubmit({
                ...categoryInfo,
                name: name,
                iconFile
            }); 
        }    
    }

    const onFileUpload = (file) => {
        setIconFile(file);
        const objectUrl = URL.createObjectURL(file);
        setIconPreview(objectUrl);
        setErrorObj({
            ...errorObj, 
            logoFile: ''
        });
    }

    return (
        <ModalAtom 
            title={t(mode === 'add' ? 'add-category' : 'edit-category')}
            titleHelpText={helpMode === HELP_MODES.ADVANCE ? t('help-advance.add-category-modal-title') : ''}
            show={show} 
            handleClose={onClose}
            handleSubmit={onAddCategory}
            body={ <Container>
                <Row>
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            placeholder={t('name')}
                            required
                            value={name}
                            error={errorObj.name}  
                            onChange={(e) => {
                                setName(e.target.value);
                                setErrorObj({...errorObj, 
                                    name: e.target.value ? '' : 'error-category-name-required'
                                })
                            }}
                        />
                    </Col>
                    <Col>
                        <IconUpload
                            label={t('icon')}
                            iconFile={iconFile}
                            iconPreview={iconPreview}
                            error={errorObj.iconFile}
                            onIconUpload={onFileUpload}
                        />
                    </Col>
                </Row>
            </Container>}
        />
    );
}

export default AddCategoryModal;
