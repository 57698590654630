import React from "react";
import { useTranslation } from "react-i18next";

function TextAreaAtom(props) {
    const { label, className, placeholder, value, onChange, error = '', required = false, errorTextPlaceholder = {}, ...rest } = props;
    const { t } = useTranslation();
    return (
        <>
            {label ? <label>{label}</label> : ''}
            <textarea 
                value={value}
                className={className || "form-control"}
                placeholder={(required ? `${placeholder}*` : placeholder) || ''}
                onChange={onChange}
                {...rest} /> 
           
            {error ? <p className="text-danger">{t(error, errorTextPlaceholder)}</p> : ''}
        </>
    );
}

export default TextAreaAtom;
