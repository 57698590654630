import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BrandHeaderAtom, ButtonAtom } from '../../components/atoms/atom';
import './error.scss';

function ErrorFallback({error, resetErrorBoundary}) {
    const { t } = useTranslation();
    return (
        <>
            <BrandHeaderAtom />
            <Container fluid className='error-container pt-3'>
                <strong>{t('error-component-title')}</strong>
                <br />
                <br />
                <pre>{error.stack}</pre>
                <ButtonAtom onClick={resetErrorBoundary}>{t('try-again')}</ButtonAtom>
            </Container>
        </>
    )
}

export default ErrorFallback;