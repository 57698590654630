const getMenuItems = (t, role) => {
    let menuItems = [
        {
            label: t('menu-group-general'),
            key: 'general',
            menus: [
                {
                    label: t('menu-user'),
                    key: 'user',
                    url: '/settings/user',
                },
                {
                    label: t('menu-department'),
                    key: 'department',
                    url: '/settings/department',
                },
                {
                    label: t('menu-location'),
                    key: 'location',
                    url: '/settings/location',
                },
                {
                    label: t('menu-group'),
                    key: 'group',
                    url: '/settings/group',
                },
                {
                    label: t('menu-role'),
                    key: 'role',
                    url: '/settings/role',
                },
            ]
        },
        {
            label: t('menu-group-order'),
            key: 'order',
            menus: [
                {
                    label: t('menu-process'),
                    key: 'process',
                    url: '/settings/process',
                },
            ],
        },
        // {
        //     label: t('others'),
        //     key: 'others',
        //     menus: [
        //         {
        //             label: t('archive-filter'),
        //             key: 'archive-filter',
        //             url: '/settings/archive-filter',
        //         },
        //     ]
        // },
    ];
    if (['SUPER_ADMIN', 'ADMIN'].includes(role)) {
        menuItems = [...menuItems,  {
            label: t('menu-group-asset'),
            key: 'asset',
            menus: [
                {
                    label: t('menu-category'),
                    key: 'category',
                    url: '/settings/category',
                },
                {
                    label: t('menu-manufacturer'),
                    key: 'manufacturer',
                    url: '/settings/manufacturer',
                },
                {
                    label: t('menu-type'),
                    key: 'type',
                    url: '/settings/type',
                },
            ]
        }]
    }
    if (['SUPER_ADMIN'].includes(role)) {
        menuItems = [...menuItems,  {
            label: t('client'),
            key: 'client',
            menus: [
                {
                    label: t('settings'),
                    key: 'client-settings',
                    url: '/settings/client-settings',
                },
            ]
        }]
    }
    return menuItems;
}

export {
    getMenuItems,
}
