const EXTERNAL_SYSTEM_IDENTIFIER_REGEX = /^([a-zA-Z0-9_]*)$/;
const DATETIME_IDENTIFIER_REGEX = /^[0-9]{4}(-[0-9]{2}){2}T([0-9]{2}:){2}[0-9]{2}\+[0-9]{2}:[0-9]{2}$/;

const HELP_MODES = {
    BASIC: 'basic',
    ADVANCE: 'advance'
}

export {
    EXTERNAL_SYSTEM_IDENTIFIER_REGEX,
    DATETIME_IDENTIFIER_REGEX,
    HELP_MODES
}