import React from 'react';
import Select from 'react-select';
import { useTranslation } from "react-i18next";

export default function MultiSelectAtom(props) {

    const { selectedOption = [], placeholder, options = [], onChange, error = '', required = false, ...rest } = props;
    const { t } = useTranslation();

    return (
        <>
            <Select
                defaultValue={selectedOption}
                onChange={onChange}
                options={options}
                isMulti
                placeholder={(required ? `${placeholder}*` : placeholder) || ''}
                {...rest}
            />
            {error ? <p className="text-danger">{ t(error)}</p> : '' }
        </>
    );
}