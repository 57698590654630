import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonAtom, TabAtom } from '../../components/atoms/atom';
import { getOperationTabs } from './constant';
import "./operations.scss";
import { AuthContext } from '../../contexts/AuthProvider';
import { setting } from '../../store/services/index';
import { useLocation } from 'react-router-dom';
import { showError } from "../../utils/method";

function OrdersPage() {
    const location = useLocation();
    const locState = location.pathname.split('/')[2] === 'archive' ? { type: 'archive' } :  location.state || { type: 'planning' };
    const [selectedTab, setSelectedTab] = useState(locState.type);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { auth, setAuth, setOrderMasterData } = useContext(AuthContext);

    const getUserInfo = async () => {
        let role = auth?.role;
        if (!role) {
            const res = await setting.getUserInfo();
            role = res.data.role;
            setAuth({ ...auth, role: role, name: res.data.name});
        }
        if(role === 'OPERATOR') {
            navigate('/operator');     
        }
    };

    const fetchProcess = async () => {
        try {
            let processResult = await setting.getProcess();
            processResult = processResult?.data;

            setOrderMasterData({
                orderMasterDataloaded: true,
                processList: processResult || [],
            });
        } catch(error) {
            showError(t, error);
        }
    }

    useEffect(() => {
        getUserInfo();
        fetchProcess();
        return () => {
            setOrderMasterData({
                orderMasterDataloaded: false,
                processList: [],
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onArchive = () => {
        setSelectedTab('archive');
        navigate('/order/archive');
    };

    return <div className=" d-flex justify-content-between mt-3 operations ">
        <TabAtom 
            tabs={getOperationTabs(t)}
            selectedTab={selectedTab}
            onTabClick={(tabKey) => {
                navigate('/order/open',  {
                    state:  {
                        type: tabKey
                    }
                })
                setSelectedTab(tabKey);
            }} />

        <ButtonAtom 
            className={selectedTab === 'archive' ? "btn btn-primary text-white" : "btn btn-seconday" }
            onClick={() => onArchive()}>
            {t('archive')}
        </ButtonAtom>
    </div>

}

export default OrdersPage;
