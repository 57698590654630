import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

function FileUpload(props) {
    const fileUploadRef = useRef();
    const { label, excelFile = null, error = '', onExcelUpload = () => {} } = props;
    const { t } = useTranslation();

    const handleUpload = () => {
        fileUploadRef.current?.click();
    }

    const onFileUpload = () => {
        const file = fileUploadRef.current?.files;
        if (!file?.length) {
            return;
        }
        onExcelUpload(file[0]);
    }

    return <>
        <label>{label}:*</label>
        <input
            ref={fileUploadRef}
            className="d-none"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={onFileUpload}
        />
        <button onClick={handleUpload} className="btn btn-outline-primary mx-3">
            {t('browse')}
        </button>
        {excelFile && <span className="text-secondary">{excelFile?.name}</span>}
        {error ? <p className="text-danger">{t(error)}</p> : ''}
    </>
}

export default FileUpload;
