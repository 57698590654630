import React, { useContext, useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {navBarItemsMobile} from './constant';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import headerImage from '../../../assets/images/steel_suite_icon.png';
import './nav-bar-mobile.scss';
import { AuthContext } from "../../../contexts/AuthProvider";
import { useEffect } from "react";
import { VERSION_NUMBER } from "../../../constant/versionInfo";

function NavBarMobile(props) {
    const { onNavigationItemClick } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { auth } = useContext(AuthContext);
    const [expanded, setExpanded] = useState(false);

    const onMenuItemClick = (menuKey, url) => {
        setExpanded(false);
        if (menuKey === 'logout') {
            navigate('/');
            localStorage.setItem('token', '');
            localStorage.setItem('selectedMenu', '');
            localStorage.setItem('filterObj', JSON.stringify({}));
            return;
        }
        localStorage.setItem('selectedMenu', menuKey);
        onNavigationItemClick(url);
    };

    useEffect(() => {
        if (auth.isOperatorNavigationDisabled) {
            setExpanded(false);
        }
    }, [auth.isOperatorNavigationDisabled]);

    return (
        <>
            <Navbar
                bg="dark" variant="dark"
                expand="xl" sticky="top"
                className="nav-bar-mobile" expanded={expanded}
            >
                <Container>
                    <Navbar.Brand href="/operator">
                        <img alt='' src={headerImage} />
                        <div className="headline">steelsuite Operator</div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbar-nav-mobile" onClick={() => setExpanded(expanded ? false : "expanded")} disabled={auth.isOperatorNavigationDisabled} />
                    <Navbar.Collapse id="navbar-nav-mobile">
                        <Nav>
                            <Nav.Link className="user-name" onClick={() => {} }>
                                <FontAwesomeIcon icon={faUser} />{' '}{auth.name}
                            </Nav.Link>
                            {
                                navBarItemsMobile.map((navBar) => 
                                    <Nav.Link disabled={auth.isOperatorNavigationDisabled}
                                        onClick={() => onMenuItemClick(navBar.key, navBar.url) }
                                        key={navBar.key}
                                    >
                                        {navBar.icon}{' '}
                                        {t(navBar.label)}
                                    </Nav.Link>)
                            }
                        </Nav>
                        <Navbar.Text className="version-info">
                            {VERSION_NUMBER}
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>

    );
}

export default NavBarMobile;
