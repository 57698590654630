import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {Container, Row, Col} from 'react-bootstrap';

import SettingsMenu from '../settings-menu/settings-menu';
import { TextFieldAtom, ButtonAtom } from '../../../components/atoms/atom';
import { showError, showSuccess } from "../../../utils/method";
import { setting } from '../../../store/services/index';

function ClientSettingsPage() {
    const { t } = useTranslation();
    const [numberOfProcesses, setNumberOfProcesses] = useState();
    const [clientName, setClientName] = useState();
    const [clientId, setClientId] = useState();
    const [errorObj, setErrorObj] = useState({
        clientName: '',
        numberOfProcesses: '',
    });

    const fetchClientSettings = async () => {
        try {
            let response = await setting.getClientSetting();
            // let response = getClientSetting();
            response = response?.data || {};
            setClientId(response?.id);
            setClientName(response?.clientName);
            setNumberOfProcesses(response?.processCount);
        } catch (error) {
            showError(t, error);
        }
    };
    useEffect(() => {
        fetchClientSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSave = async () => {
        if (!clientId) {
            return;
        }
        if (!clientName) {
            setErrorObj({...errorObj, clientName: !clientName ? 'error-client-name-required': '' });
            return;
        }
        if (!numberOfProcesses || numberOfProcesses < 1) {
            setErrorObj({ ...errorObj, numberOfProcesses: numberOfProcesses ? numberOfProcesses < 1 ? 'error-process-count-greaterthan-one' : '' : 'error-process-count-required' });
            return;

        }
        try {
            const response = await setting.updateClientSetting({
                clientName,
                processCount: numberOfProcesses,
            }, clientId);
            showSuccess(t, response?.message);
            fetchClientSettings();  
        } catch (error) {
            showError(t, error);
        }

    }

    return (
        <>
            <SettingsMenu />
            <Container fluid style={{ marginTop: 20, padding: 0 }} className="archive-filter-container">
                <p>{t('menu-client-settings')}</p>
                <Row>
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            label={t('client-name')}
                            placeholder={t('client-name')}
                            required
                            value={clientName}
                            error={errorObj.clientName}
                            onChange={(e) => {
                                setClientName(e.target.value);
                                setErrorObj({ ...errorObj, clientName: e.target.value ? '' : 'error-client-name-required' })
                            }}
                        />
                    </Col>
                    <Col>
                        <TextFieldAtom 
                            type="number"
                            label={t('number-processes')}
                            placeholder={t('number-processes')}
                            required
                            value={numberOfProcesses}
                            error={errorObj.numberOfProcesses}
                            onChange={(e) => {
                                setNumberOfProcesses(e.target.value);
                                setErrorObj({ ...errorObj, numberOfProcesses: e.target.value ? e.target.value < 1 ? 'error-process-count-greaterthan-one' : '' : 'error-process-count-required' })
                            }}
                        />
                    </Col>
                    <Col />
                    <Col />
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <ButtonAtom 
                            onClick={() => onSave()}>
                            {t('save')}
                        </ButtonAtom>
                        <ButtonAtom 
                            style={{ marginLeft: 4 }}
                            className="btn btn-secondary"
                            onClick={() => onSave()}>
                            {t('cancel')}
                        </ButtonAtom>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ClientSettingsPage;
