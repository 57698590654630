import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const showToast = (toastText, options) => {
    toast(toastText, options);
};

export const isAndroidDevice = () => {
    return navigator.userAgent.match(/Android/);
};

export const isMobileDevice = () => {
    const toMatch = [
        /Android/i,
        /iPhone/i,
        /iPod/i,
        /Windows Phone/i
    ];
    
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export const showInfo = (t, message) => {
    if (message) {
        const options = { type: 'info' };
        if (isMobileDevice()) {
            options.position = toast.POSITION.TOP_CENTER;
        }
        showToast(t(message), options);
        return;
    }
    console.log(message);
};

export const showSuccess = (t, message) => {
    if (message) {
        showToast(t(message), { type: 'success' });
        return;
    }
    console.log(message);
};

export const showError = (t, error) => {
    const message = error?.message || 'API_ERROR';
    if (message) {
        showToast(t(message), { type: 'error' });
        return;
    }
    console.log(error);
};

const DATE_FORMAT = 'DD.MM.YYYY';
const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';
const CURRENCY_FORMAT_OPTION = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
};

export const getFormattedDate = (inputDate, containTime = false) => {
    if (!inputDate) {
        return '';
    }
    let outputDateFormat = containTime ? DATE_TIME_FORMAT : DATE_FORMAT;
    return dayjs(new Date(inputDate)).format(outputDateFormat);
};

export const getWeekdayName = (inputDate) => {
    if (!inputDate) {
        return '';
    }
    return inputDate.toLocaleDateString('de-DE', { weekday: 'long' });
}

export const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
  
        reader.onload = () => {
            resolve(reader.result);
        };
  
        reader.onerror = reject;
        reader.readAsDataURL(file);
    })
}

export const formatValueToLocale = (data, isCurrency = false, minimumFractionDigits = 2) => {
    const num = Number.parseFloat(data);
    if (Number.isNaN(num)) {
        return '';
    }
    return num.toLocaleString('de-DE', isCurrency ? CURRENCY_FORMAT_OPTION : {
        minimumFractionDigits,
    });
};

export const extractValues = (mappings) => {
    return Object.keys(mappings);
};

export const lookupValue = (mappings, key) => {
    return mappings[key];
};

export const getCellValue = (fieldValue, attribute) => {
    let value = fieldValue;
    if (attribute?.attributeType?.name === 'INPUT') {
        if (attribute?.attributeFormat?.name === 'DATE') {
            value = getFormattedDate(value);
        }
        if (attribute?.attributeFormat?.name === 'DATETIME') {
            value = getFormattedDate(value, true);
        }
        if (attribute?.attributeFormat?.name === 'NUMBER') {
            if (attribute?.externalSystemIdentifier === 'WEIGHT_P') {
                value = formatValueToLocale(value, false, 3)
            }
        }
        if (attribute?.attributeFormat?.checkbox === 'CHECKBOX') {
            value = !!+value;
        }
    }
    return value || '';
};

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
  
export const descendingComparator = (a, b, orderBy) => {
    let aVal = a ? a.orderInfo[orderBy].value : null;
    let bVal = b ? b.orderInfo[orderBy].value : null;
  
    if (typeof aVal === 'string' || aVal instanceof String) {
        aVal = aVal.toLowerCase();
    }
  
    if (typeof bVal === 'string' || bVal instanceof String) {
        bVal = bVal.toLowerCase();
    }
  
    if (bVal < aVal) {
        return -1;
    }
    if (bVal > aVal) {
        return 1;
    }
    return 0;
}
  
export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}