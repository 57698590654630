import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faBarsProgress, faSitemap, faRightFromBracket, faFileExcel } from '@fortawesome/free-solid-svg-icons';

const navBarItems = [
    // {
    //     label: 'dashboard',
    //     key: 'dashboard',
    //     icon: <FontAwesomeIcon icon={faChartPie} />,
    //     url: 'dashboard',
    // },
    {
        label: 'order',
        key: 'order',
        icon: <FontAwesomeIcon icon={faBarsProgress} />,
        url: 'order/open',
    },
    {
        label: 'asset',
        key: 'asset',
        icon: <FontAwesomeIcon icon={faSitemap} />,
        url: 'asset',
    },
    {
        label: 'settings',
        key: 'settings',
        icon: <FontAwesomeIcon icon={faGear} />,
        url: 'settings/user',
    },
    {
        label: 'import-export',
        key: 'import-export',
        icon: <FontAwesomeIcon icon={faFileExcel} />,
        url: 'import-export',
    },
];

const navBarItemsMobile = [
    {
        label: 'order',
        key: 'order',
        icon: <FontAwesomeIcon icon={faBarsProgress} />,
        url: 'operator',
    },
    {
        label: 'logout',
        key: 'logout',
        icon: <FontAwesomeIcon icon={faRightFromBracket} />,
        url: '',
    },
];

export {
    navBarItems,
    navBarItemsMobile,
}
