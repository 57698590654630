import React, { useContext } from "react";
import { Nav } from "react-bootstrap";
import {navBarItems} from './constant';
import { useTranslation } from "react-i18next";
import { NavigationBarAtom } from '../../../components/atoms/atom';
import { AuthContext } from '../../../contexts/AuthProvider';

function NavBar(props) {
    const { onNavigationItemClick } = props;
    const { t } = useTranslation();
    const activeMenuItem = localStorage.getItem('selectedMenu');
    const { auth } = useContext(AuthContext);

    const onMenuItemClick = (menuKey, url) => {
        localStorage.setItem('selectedMenu', menuKey);
        onNavigationItemClick(url);
    };

    const getNavItems = () => {
        let navItems = [...navBarItems];
        if (auth?.token) {
            if(!['ADMIN', 'SUPER_ADMIN'].includes(auth.role)) {
                navItems =  navItems.filter((navItem) => {
                    return navItem.key !== 'settings';
                })
            }
        }
        return navItems;
    };

    return (
        <NavigationBarAtom>
            {
                getNavItems().map((navBar) => 
                    <Nav.Link
                        onClick={() => onMenuItemClick(navBar.key, navBar.url) }
                        key={navBar.key}
                        className={activeMenuItem === navBar.key ? 'active-menu' : ''}
                    >
                        {navBar.icon}{' '}
                        {t(navBar.label)}
                    </Nav.Link>
                )
            }
        </NavigationBarAtom>
    );
}

export default NavBar;
