/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import clone from 'rfdc/default';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { io } from "socket.io-client";
import { faLockOpen, faLock, faQuestion, faCheck, faFileLines } from '@fortawesome/free-solid-svg-icons';
import SOCKET_RESPONSE from '../../../constant/socket';

import "./open-order.scss";
import DetailModal from "../detail/detail";
import StepItem from '../step-item/step-item';
import ActionBar from '../action-bar/action-bar';
import { setting } from '../../../store/services/index';
import { getFormattedDate, showError, showSuccess } from '../../../utils/method';
import InfoBoxModal from "../info-box/info-box";
import { dynamicSortMultipleParams, getFiltersFromStorage, getOrderTableAttributes } from "../utils/method";
import FilterBar from "../order-filter-bar/order-filter-bar";
import { AgGridAtom, ModalAtom } from '../../../components/atoms/atom';
import { getPlanningFilter, getTableColumns, actionButton, newCopyActionButton } from '../constant';
import AddProcessStepModal from '../process-step/process-step';
import { AuthContext } from '../../../contexts/AuthProvider';
import { searchOrderData } from '../../../utils/search';
import debounce from 'lodash.debounce';
import { useStateWithRef } from "../../../utils/useStateWithRef";
import NewOrderModals from "./new-order-modals";
import { SOCKET_URL } from "../../../constant/url";
import SelectPriorityModal from "../select-priority/select-priority";

function OpenOrderPage() {
    const { orderMasterData, helpMode } = useContext(AuthContext);
    const { orderMasterDataloaded, processList = []} = orderMasterData;
    const { t } = useTranslation();
    const location = useLocation();
    const initialConfirmDialogInfo = {
        type: '',
        visible: false,
        title: '',
        body: '',
    };
    const [isScreenLoaded, setIsScreenLoaded] = useState(false);
    const [showInfoBox, setShowInfoBox] = useState(false);
    
    const orderType = location?.state?.type || 'planning';
    const [selectedRowInfo, setSelectedRowInfo] = useState({});    
    const [showDetailModal, setShowDetailModal] = useState(false);

    const [processStepFilters, setProcessStepFilters] = useState(JSON.parse(localStorage.getItem('stepFilter')) || []);
    const [filterItems, setFilterItems] = useState(getPlanningFilter(t, getFiltersFromStorage()));
    const [showAddProcessStep, setShowAddProcessStep] =  useState(false);
    const [showConfirmDialogInfo, setShowConfirmDialogInfo] = useState(initialConfirmDialogInfo);
    const [attributes, setAttributes] = useState([]);
    const [selAttribute, setSelAttribute] = useState(JSON.parse(localStorage.getItem('sortFilter')) || []);
    const [defaultSortFilters, setDefaultSortFilters] = useState([]);
    const [attributeMasterData, setAttributeMasterData] = useState([]);

    const [newOrderData, setNewOrderData] = useState({});
    const [showNewOrderModal, setShowNewOrderModal] = useState(false);
    const [showOrderTemplateModal, setShowOrderTemplateModal] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [selectedProcessId, setSelectedProcessId] = useState(null);
    const [processStepList, setProcessStepList] = useState([]);
    const [attributeGroupsList, setAttributeGroupsList] = useState([]);

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [showSelectPriorityModal, setShowSelectPriorityModal] = useState(false);

    // state variables with ref support
    const [orders, setOrders, ordersRef] = useStateWithRef([]);
    const [selectedOrders, setSelectedOrders, selectedOrdersRef] = useStateWithRef([]);
    const [filteredOrders, setFilteredOrders, filteredOrdersRef] = useStateWithRef([]);
    const [currentOrderType, setCurrentOrderType, currentOrderTypeRef] = useStateWithRef(orderType);
    const [currentProcessId, setCurrentProcessId, currentProcessIdRef] = useStateWithRef(selectedProcessId);
    
    const listeningForSocketResponseRef = useRef(true);
    const socketRef = useRef();
    const newStatusRef = useRef();

    const { auth } = useContext(AuthContext);

    const orderUpdateListener = (response) => {
        if (listeningForSocketResponseRef.current) {
            checkSelectedDataFromSocketResponse(response?.data);
            // showInfo(t, 'data-synced');
        }

        listeningForSocketResponseRef.current = true;
    };

    const statusUpdateListener = (response) => {
        if (listeningForSocketResponseRef.current) {
            checkLocalOrderFromOrderType(response?.data, newStatusRef.current);
            // showInfo(t, 'data-synced');
        }
        newStatusRef.current = null;
        listeningForSocketResponseRef.current = true;
    };

    const newOrderListener = (response) => {
        const newOrder = response?.data || [];
        if (newOrder.length) {
            const localOrder = clone(ordersRef.current);
            const updatedOrders = [...newOrder, ...localOrder];
            setOrders(updatedOrders);
        }
        // showInfo(t, 'data-synced');
        listeningForSocketResponseRef.current = true;
    };

    const deleteOrderListener = (response) => {
        if (response) {
            const localOrder = clone(ordersRef.current);
            const localFilteredOrder = clone(filteredOrdersRef.current);
            const localSelectedOrder = clone(selectedOrdersRef.current);
            let shouldModalClose = false;
    
            response.forEach((orderId) => {
                let index = localOrder.findIndex((orderObj) => orderObj.id === orderId);
                if (index > -1) {
                    localOrder.splice(index, 1);
                }
                index = localFilteredOrder.findIndex((orderObj) => orderObj.id === orderId);
                if (index > -1) {
                    localFilteredOrder.splice(index, 1);
                } 
                index = localSelectedOrder.findIndex((orderObj) => orderObj.id === orderId);
                if (index > -1) {
                    localSelectedOrder.splice(index, 1);
                    shouldModalClose = true;
                }
            })

            setOrders(localOrder);
            setFilteredOrders(localFilteredOrder);
            setSelectedOrders(localSelectedOrder);
            if (shouldModalClose) {
                setShowDetailModal(false);
                setShowAddProcessStep(false);
                setShowInfoBox(false);
            }
        }
    };

    useEffect(() => {
        socketRef.current = io(SOCKET_URL, {transports: ['websocket'], upgrade: false, autoConnect: true});

        socketRef.current.on(SOCKET_RESPONSE.CONNECT, () => {
            // console.log(SOCKET_RESPONSE.CONNECT, socketRef.current.id);
            socketRef.current.emit(SOCKET_RESPONSE.JOIN_ROOM, '', `order-list/${currentProcessIdRef.current}/${currentOrderTypeRef.current}`, (roomIdLeft, roomIdJoined) => {
                // console.log('roomIdLeft', roomIdLeft);
                // console.log('roomIdJoined', roomIdJoined);
            });
        });

        socketRef.current.on(SOCKET_RESPONSE.CONNECT_ERROR, () => {
            setTimeout(() => socketRef.current.connect(), 5000);
        });

        socketRef.current.on(SOCKET_RESPONSE.DISCONNECT, () => {
            // console.log(SOCKET_RESPONSE.DISCONNECT);
        });

        socketRef.current.on(SOCKET_RESPONSE.ORDER, orderUpdateListener);
        socketRef.current.on(SOCKET_RESPONSE.STATUS, statusUpdateListener);
        socketRef.current.on(SOCKET_RESPONSE.NEW_ORDER, newOrderListener);
        socketRef.current.on(SOCKET_RESPONSE.DELETE_ORDER, deleteOrderListener);

        return () => {
            socketRef.current.off(SOCKET_RESPONSE.ORDER, orderUpdateListener);
            socketRef.current.off(SOCKET_RESPONSE.STATUS, statusUpdateListener);
            socketRef.current.off(SOCKET_RESPONSE.NEW_ORDER, newOrderListener);
            socketRef.current.on(SOCKET_RESPONSE.DELETE_ORDER, deleteOrderListener);
            socketRef.current.close();
        };
    }, []);

    const mapAttributeOptions = (selectedId, attr = attributes) => {
        const att = clone(attr);
        return selectedId.map(id => att.find(o => o.value === id));
    }

    const fetchGeneralSettings = async (attr = attributes) => {
        try {
            let response = processList.find((process) => process.id === selectedProcessId)?.archiveFilterSettings;
            const localDefaultSortFilters = [];
            if (response?.orderFilter1) {
                localDefaultSortFilters.push(response?.orderFilter1);
            }
            if (response?.orderFilter2) {
                localDefaultSortFilters.push(response?.orderFilter2);
            }
            if (response?.orderFilter3) {
                localDefaultSortFilters.push(response?.orderFilter3);
            }
            setDefaultSortFilters(localDefaultSortFilters);
            if (!selAttribute.length) {
                setSelAttribute(mapAttributeOptions(localDefaultSortFilters, attr));
            }
        } catch (error) {  
            showError(t, error);
        }
    };

    const emptyProcessStepFiltersOnProcessChange = () => {
        setProcessStepFilters([]);
        localStorage.setItem('stepFilter', JSON.stringify([]));

        setSelAttribute([]);
        localStorage.setItem('sortFilter', JSON.stringify([]));
    }

    useEffect(() => {
        if (selectedProcessId) {
            fetchProcessStepsAndAttributes();
        }
    }, [selectedProcessId]);

    const populateProcesssListAndFetchOrders = async () => {
        try {
            const fItems = clone(filterItems);
            const processFilter = fItems.find((f) => f.key === 'processId');
            const processId = processFilter?.value || processList[0]?.id;
            const processOptions = [];
            processList?.forEach((re) => processOptions.push({ label: re?.name, value: re?.id }));

            processFilter.value = processId;
            processFilter.options = processOptions;
            setSelectedProcessId(processId);
            setFilterItems(fItems);
            // fetchOrders(fItems);
            setIsScreenLoaded(true);
        } catch(error) {
            showError(t, error);
        }
    }

    useEffect(() => {
        const filObj = {};
        filterItems.forEach((item) => filObj[item.key] = item.value);
        localStorage.setItem('filterObj', JSON.stringify(filObj));
    }, [filterItems]);

    useEffect(() => {
        if (orderMasterDataloaded && processList.length) {
            populateProcesssListAndFetchOrders();
        }
    }, [orderMasterDataloaded]);

    useEffect(() => {
        let localFilteredOrders = [...orders];
        if (processStepFilters.length) {
            processStepFilters.forEach((stepFilter) => {
                if (stepFilter.resourceId !== 'all') {
                    localFilteredOrders = localFilteredOrders.filter((ord) => {
                        return ord.orderProcessSteps.find((pStep) => {
                            return pStep.processStep.id === stepFilter.processStepId && pStep.resource.id === stepFilter.resourceId;
                        });
                    })
                }
            })
        }
        const searchText = filterItems.find((fi) => fi.key === 'search')?.value;
        if (searchText) {
            localFilteredOrders = searchOrderData(searchText, localFilteredOrders, attributeMasterData);
        }
        if (selAttribute.length) {
            // TODO: Commented this code bec this is useless
            // selAttribute.forEach((sAttribute) => {
            //     localFilteredOrders = localFilteredOrders.filter((ord) => {
            //         return Object.values(ord.orderInfo).some((subOrderInfo) => {
            //             return subOrderInfo.id === sAttribute?.value;
            //         });
            //     })
            // });
            localFilteredOrders = localFilteredOrders.sort(dynamicSortMultipleParams(selAttribute));
        }
        setFilteredOrders(localFilteredOrders);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders, processStepFilters, selAttribute]);

    const onStatusClick = (selectedInfo) => {
        setShowInfoBox(true);
        setSelectedRowInfo(selectedInfo);
    }

    const getColumnDef = () => {
        const archiveSettings = processList.find((process) => process.id === selectedProcessId)?.archiveFilterSettings;
        const planningTableColumns = getTableColumns(t, selectedOrders, onStatusClick, archiveSettings?.showCreatedAtDate, helpMode);
        const dynamicColumn = getOrderTableAttributes(attributeGroupsList);
        return [...planningTableColumns.slice(0, -1), ...dynamicColumn, planningTableColumns[planningTableColumns.length-1]];
    };

    const fetchOrders = async (fItems = filterItems) => {
        const lFilterItems = clone(fItems);
        try {
            const filters = lFilterItems.reduce((acc, {key, value, endDate}) => {
                if (['date', 'timePeriod', 'status', 'processId'].includes(key)) {
                    if (key === 'date') {
                        value = getFormattedDate(value);
                        if (endDate) {
                            endDate = getFormattedDate(endDate);
                            acc.push(`endDate=${endDate}`)
                        }
                    }
                    acc.push(`${key}=${value}`);
                }
                return acc;
            }, []);
            let queryString = filters.join('&');
            if (queryString.length) {
                queryString += '&';
            }
            const ordStatus = orderType === 'planning' ? 'NEW' : orderType === 'release' ? 'RELEASE' :  orderType === 'implement' ? 'IMPLEMENT' : '';
            queryString += `orderStatus=${ordStatus}`;
            let result = await setting.getOrders(queryString);
            setOrders(result?.data || []);
        } catch(error){
            showError(t, error);
        }
    }

    useEffect(() => {
        if (isScreenLoaded) {
            socketRef.current.emit(SOCKET_RESPONSE.JOIN_ROOM, `order-list/${currentProcessId}/${currentOrderType}`, `order-list/${selectedProcessId}/${orderType}`, (roomIdLeft, roomIdJoined) => {
                console.log('roomIdLeft', roomIdLeft);
                console.log('roomIdJoined', roomIdJoined);
            });
            setCurrentProcessId(selectedProcessId);
            setCurrentOrderType(orderType);
            fetchOrders();
            setSelectedOrders([]);
        }
    }, [orderType, selectedProcessId]);

    const deboundedSearch = debounce((searchText) => {
        let filteredOrderList = searchOrderData(searchText, orders, attributeMasterData);
        filteredOrderList = filteredOrderList.sort(dynamicSortMultipleParams(selAttribute));
        setFilteredOrders(filteredOrderList);
    }, 500);

    const fetchProcessStepsAndAttributes = async () => {
        let processStepResult = await setting.getProcessStepsByProcessId(selectedProcessId);
        processStepResult = processStepResult?.data;
        setProcessStepList(processStepResult);

        let attributeGroupResult = await setting.getProcessAttributesByProcessId(selectedProcessId);
        attributeGroupResult = attributeGroupResult?.result || [];
        const attributesResult = attributeGroupResult.reduce((acc, ag) => {
            acc.push(...ag.attributes);
            return acc
        }, []);
        const attrOptions = attributesResult.map((attr) => { 
            return {
                label: attr.label,
                value: attr.id,
            }
        });
        setAttributes(attrOptions);
        fetchGeneralSettings(attrOptions);
        setAttributeMasterData(attributesResult.filter((a) => a.isVisibleDesktop || a.isVisibleSticky));

        setAttributeGroupsList(attributeGroupResult);
    };
    
    const onFilterAction = (index, key, info) => {
        if (['date', 'timePeriod', 'status', 'processId', 'search'].includes(key)) {
            const value = info?.value;
            const localLeftFilterItems = clone(filterItems);
            const filterToUpdate = localLeftFilterItems.find((filter) => filter.key === key);
            if (!filterToUpdate) {
                return;
            }
            if (key === 'timePeriod') {
                const dateFilter = localLeftFilterItems.find((filter) => filter.key === 'date');
                dateFilter.isDateRangePicker = false;
                dateFilter.endDate = new Date();
                if (info?.value === 'variable') {
                    dateFilter.isDateRangePicker = true;
                }
            }
            if (key === 'date') {
                if (Array.isArray(value)) {
                    const [start, end] = value;
                    filterToUpdate.value = start;
                    filterToUpdate.endDate = end;
                } else {
                    filterToUpdate.value = value;
                }
            } else {
                filterToUpdate.value = value;
            }
            setFilterItems(localLeftFilterItems);
            if (key === 'search') {
                deboundedSearch(value);
                return;
            }
            if ('processId' === key) {
                setSelectedProcessId(value);
                emptyProcessStepFiltersOnProcessChange();
            }
            fetchOrders(localLeftFilterItems);
        }
        if (key === 'processStepFilter') {
            const { processStepId, resourceId } = info;
            let cloneProcessFilters = [...processStepFilters];
            const elemIndex = cloneProcessFilters.findIndex((pf) => pf.processStepId === processStepId);
            if (elemIndex === -1) {
                cloneProcessFilters.push(info);
            }
            else {
                if (resourceId !== 'all') {
                    cloneProcessFilters[elemIndex].resourceId = resourceId;
                } else {
                    cloneProcessFilters.splice(elemIndex, 1);
                }
            }
            localStorage.setItem('stepFilter', JSON.stringify(cloneProcessFilters));
            setProcessStepFilters(cloneProcessFilters);
        }
    };

    const fieldIndex =  (inputArrray, fieldKey) => inputArrray.findIndex((bu) => bu.key === fieldKey);

    const isEditDisableValue = (selRows) => {
        let isEditDisable = true;
        if (selRows?.length === 1) {
            isEditDisable = false;
        } 
        if (selRows?.length > 1) {
            if (['implement', 'release'].includes(orderType)) {
                isEditDisable = true;
            } else {
                const val = selRows[0]?.missingValues || [];
                isEditDisable = !selRows.every(order => (order?.missingValues || []).join() === val.join());
            }
        }
        return isEditDisable;
    };

    const getActButtons = () => {
        const deButtons = clone(actionButton);
        const additionalActionButtons = clone(newCopyActionButton);
        const editIndex = fieldIndex(deButtons, 'edit');
        deButtons[editIndex].tooltip = `edit-order`;
        if (['release', 'implement'].includes(orderType)) {
            deButtons[editIndex].icon = faFileLines;
            deButtons[editIndex].tooltip = 'action-details';
        }
        if (orderType === 'planning') {
            deButtons.push(...additionalActionButtons);
        }

        if (!selectedOrders.length) {
            return clone(deButtons);
        }
        let localButtons = clone(deButtons);
        const areOrdersLocked = selectedOrders.every((ord) => auth.role !== 'SALES' && ord.orderStatus !== 4 && ord.isLocked);
        const areOrdersUnLocked = selectedOrders.every((ord) => auth.role !== 'SALES' && ord.orderStatus !== 4 && !ord.isLocked);
        const areOrdersCleared = selectedOrders.every((ord) => auth.role !== 'SALES' && ord.orderStatus !== 4 && ord.isUnderClarification);
        const areOrdersUnCleared = selectedOrders.every((ord) => auth.role !== 'SALES' && ord.orderStatus !== 4 && !ord.isUnderClarification);
        if (areOrdersLocked || areOrdersUnLocked) {
            const lockIndex = fieldIndex(localButtons, 'lock');
            localButtons[lockIndex].disabled = false;
            localButtons[lockIndex].icon = areOrdersLocked ? faLockOpen : faLock;
            localButtons[lockIndex].isLocked = !areOrdersLocked;
            localButtons[lockIndex].tooltip = areOrdersLocked ? `unlock-order` : `lock-order`;
        }
        
        if (areOrdersCleared || areOrdersUnCleared) {
            const clearIndex = fieldIndex(localButtons, 'clarification');
            localButtons[clearIndex].disabled = false;
            localButtons[clearIndex].icon = areOrdersCleared ? faCheck : faQuestion;
            localButtons[clearIndex].isUnderClarification = !areOrdersCleared;
            localButtons[clearIndex].tooltip = areOrdersCleared ? `order-clarified` : `order-under-clarification`;
        }
        if (auth.role !== 'SALES' && selectedOrders.length) {
            const priorityIndex = fieldIndex(localButtons, 'select-priority');
            localButtons[priorityIndex].disabled = false;
            localButtons[priorityIndex].tooltip = `select-priority`;
        }
        if (auth.role !== 'SALES' && orderType === 'planning') {
            if (selectedOrders.length === 1) {
                const copyIndex = fieldIndex(localButtons, 'copy');
                localButtons[copyIndex].disabled = false;
                localButtons[copyIndex].tooltip = `copy-order`;
            }
            if (!areOrdersLocked) {
                const deleteIndex = fieldIndex(localButtons, 'delete');
                localButtons[deleteIndex].disabled = false;
                localButtons[deleteIndex].tooltip = `delete-order`;
            }
        }
       
        localButtons[editIndex].disabled = isEditDisableValue(selectedOrders);
        return localButtons;
    }

    const getRowClass = params => {
        if (params.data.missingRequiredValues) {
            return 'row-missing-values';
        }
    };

    const onSaveInfo = async (stepInformation) => {
        listeningForSocketResponseRef.current = false;
        const getStepInfo = () => {
            return stepInformation?.steps?.map((stepInfo) => {
                return  {
                    id: "",
                    comment: stepInfo?.comment,
                    orderProcessStepId: stepInfo?.orderProcessStepId,
                }
            });
        }
        try {
            const response = await setting.updateOrder({
                orders: [selectedRowInfo?.id], 
                orderProcessStepInfo: getStepInfo(),
                mode: 'OPERATOR',
            });
            setSelectedOrders(response?.data || []);
            updateLocalOrderState(response?.data || []);
            setShowInfoBox(false);
            showSuccessToaster(response, 'info');
        } catch(error) {
            showError(t, error);
        }
    }
    
    const isDropdownButtonDisabled = () => {
        if (!selectedOrders.length || auth.role === 'SALES') {
            return true;
        }
        if (orderType === 'planning')
            return selectedOrders.findIndex((order) => order?.missingRequiredValues || order?.isLocked) > -1;
        
        if (orderType === 'release')
            return !selectedOrders.every((so) => !so.isLocked);
        
        if (orderType === 'implement')
            return !selectedOrders.every((so) => so.orderProcessSteps.every((ps) => 
                (ps.status === 1 || ps.status === 2)) && !so.isLocked)
    };

    const getDefaultActionButton = () => {
        if (orderType === 'planning') {
            return { id: 'RELEASE', name: 'PLANNED'}
        }
        if (orderType === 'release')
            return { id: 'IMPLEMENT', name: 'IMPLEMENT'};
        
        if (orderType === 'implement')
            return {};
    };

    const onAction = (actionKey, info) => {
        if (actionKey === 'info') {
            setShowInfoBox(false);
        } else if (actionKey === 'edit') {
            setShowDetailModal(true);
        } else if (['hoverDropDown', 'lock', 'clarification'].includes(actionKey)) {
            const addInfo = actionKey === 'hoverDropDown' ? {
                type: 'orderStatus',
                orderStatus: info.value,
            }: {};
            setShowConfirmDialogInfo({
                type: actionKey,
                visible: true,
                title: t('update-order-status'),
                body: 'confirm-order-status-text',
                ...addInfo
            });
        } else if (actionKey === 'select-priority') {
            setShowSelectPriorityModal(true);
        } else if (actionKey === 'new') {
            setNewOrderData(initializeNewOrderObject());
            setShowNewOrderModal(true);
        } else if (actionKey === 'copy') {
            setNewOrderData(copyAndInitializeOrderObject(selectedOrders[0]));
            setShowNewOrderModal(true);
        } else if (actionKey === 'new-order-template') {
            fetchTemplates();
        } else if (actionKey === 'delete') {
            setShowConfirmDeleteDialog(true);
        }
    };

    const onOrderUpdate = async (updatedInfo) => {
        listeningForSocketResponseRef.current = false;
        try {
            const response = await setting.updateOrder({
                orders: selectedOrders.map((ord) => ord.id ), 
                orderInfo: updatedInfo.filter((od) => od.isUpdated).map((info) => {
                    let updatedValue = '';
                    if (info.attributeFormat === 'DATE') {
                        updatedValue = getFormattedDate(info.value);
                    } else if (info.attributeFormat === 'DATETIME') {
                        updatedValue = getFormattedDate(info.value, true);
                    } else {
                        updatedValue = info.value.toString();
                    }
                    return { id: info.id, value: updatedValue}
                }),
                mode: 'OPERATOR',
            });
            updateLocalOrderState(response?.data || []);
            setSelectedOrders(response.data || []);
            setShowDetailModal(false);
            showSuccessToaster(response, 'edit');
        } catch(error) {
            showError(t, error);
        }
    };

    const showSuccessToaster = (res, payload) => {
        // TODO: Ancofer wanted to remove the success message on order listing
        // check git history for the older implementation
        return;
    }

    const updateOrderStatus = async (payload = {}, reFetchOrder) => {
        listeningForSocketResponseRef.current = false;
        try {
            const response = await setting.updateOrderStatus(payload);
            setSelectedOrders([]);
            if (reFetchOrder) {
                fetchOrders();
            } else {
                updateLocalOrderState(response?.data || []);
            }
            setShowSelectPriorityModal(false);
            setShowConfirmDialogInfo(initialConfirmDialogInfo);
            showSuccessToaster(response, payload);
        } catch(error) {
            showError(t, error);
        }
    };

    const updateOrders = () => {
        let payload = {
            orders: selectedOrders.map((ord) => ord.id),
        };
        const atButtons = getActButtons();
        if (showConfirmDialogInfo.type === 'orderStatus') {
            payload.status =  showConfirmDialogInfo?.orderStatus;
            newStatusRef.current = showConfirmDialogInfo?.orderStatus;

        } else if (showConfirmDialogInfo.type === 'lock') {
            const lockIndex = fieldIndex(atButtons, 'lock');
            payload.isLocked = atButtons[lockIndex].isLocked;
        
        } else if (showConfirmDialogInfo.type === 'clarification') {
            const clarIndex = fieldIndex(atButtons, 'clarification');
            payload.isUnderClarification = atButtons[clarIndex].isUnderClarification;
        }
        updateOrderStatus(payload, showConfirmDialogInfo.type === 'orderStatus');
    };

    const updateLocalOrderState = (updatedOrders) => {
        const localOrder = clone(ordersRef.current);
        const localFilteredOrder = clone(filteredOrdersRef.current);
        for (const order of updatedOrders) {
            let index = localOrder.findIndex((orderObj) => orderObj.id === order.id);
            if (index > -1) {
                localOrder[index] = order;
            }
            index = localFilteredOrder.findIndex((orderObj) => orderObj.id === order.id);
            if (index > -1) {
                localFilteredOrder[index] = order;
            }
        }
        setOrders(localOrder);
        setFilteredOrders(localFilteredOrder);
    };

    const getDropDownOptions = () => {
        if (orderType === 'release')
            return [{ id: 'NEW', name: 'PLANNED'}];
        
        if (orderType === 'implement')
            return [{ id: 'RELEASE', name: 'RELEASE'}, { id: 'NEW', name: 'PLANNED'}];
        
        return []
    };

    const onAddProcessStep = async (selectedRow) => {
        listeningForSocketResponseRef.current = false;
        try {
            const response = await setting.orderUpdateProcessStep({
                orders: selectedOrders.map((ord) => ord.id),
                processSteps: selectedRow,
            });
            if (response && response?.data) {
                setSelectedOrders(response?.data);
                updateLocalOrderState(response.data);
                setShowAddProcessStep(false);
                showSuccessToaster(response, 'update-process-step')
            }
        } catch(error) {
            showError(t, error);
        }
    }

    const updateProcessSteps = (orderInfo = {}) => {
        const localSelectedOrders = clone(selectedOrders);
        localSelectedOrders[0] = orderInfo;
        setSelectedOrders(localSelectedOrders);
        updateLocalOrderState([orderInfo]);
    }

    const checkLocalOrderFromOrderType = (updatedOrders, status) => {
        const getOrderStatusString = (_status) => {
            if (_status === 'NEW') {
                return 'planning';
            } else if (_status === 'RELEASE') {
                return 'release';
            } else if (_status === 'IMPLEMENT') {
                return 'implement';
            }
        };
        
        const localOrder = clone(ordersRef.current);
        const localFilteredOrder = clone(filteredOrdersRef.current);
        const localSelectedOrder = clone(selectedOrdersRef.current);
        let shouldModalClose = false;

        for (const order of updatedOrders) {
            const isDifferentOrderType = getOrderStatusString(status) !== currentOrderTypeRef.current;
            let index = localOrder.findIndex((orderObj) => orderObj.id === order.id);
            if (index > -1 && isDifferentOrderType) {
                localOrder.splice(index, 1);
            } else if (index === -1 && isDifferentOrderType) {
                localOrder.unshift(order);
            }
            index = localFilteredOrder.findIndex((orderObj) => orderObj.id === order.id);
            if (index > -1 && isDifferentOrderType) {
                localFilteredOrder.splice(index, 1);
            } else if (index === -1 && isDifferentOrderType) {
                localFilteredOrder.unshift(order);
            }
            index = localSelectedOrder.findIndex((orderObj) => orderObj.id === order.id);
            if (index > -1 && isDifferentOrderType) {
                localSelectedOrder.splice(index, 1);
                shouldModalClose = true;
            }
        }
        setOrders(localOrder);
        setFilteredOrders(localFilteredOrder);
        setSelectedOrders(localSelectedOrder);
        if (shouldModalClose) {
            setShowDetailModal(false);
            setShowAddProcessStep(false);
            setShowInfoBox(false);
        }
    };

    const checkSelectedDataFromSocketResponse = (response) => {
        const localSelectedOrders = selectedOrdersRef.current;
        const localResponseOrders = response || [];
        if (localSelectedOrders.length === 0) {
            updateLocalOrderState(localResponseOrders);
            setSelectedOrders([]);
            setSelectedRowInfo({});
        } else {
            let newSelectedOrder = [];
            for (const responseObj of localResponseOrders) {
                const localOrder = localSelectedOrders.find((f) => f.id === responseObj.id);
                if (localOrder) {
                    newSelectedOrder.push(responseObj);
                }
            }
            updateLocalOrderState(localResponseOrders);
            if (newSelectedOrder.length) {
                setSelectedOrders(newSelectedOrder);
                setSelectedRowInfo(newSelectedOrder[0]);
            }
        }
    }

    // This is for new order / copy order popups
    const initializeNewOrderObject = () => {
        const initialObject = {};
        initialObject.processId = selectedProcessId;
        initialObject.processSteps = [];
        initialObject.orderProcessStepInfo = [];
        initialObject.orderInfo = {};
        initialObject.isTemplate = false;
        initialObject.templateName = '';
        attributeGroupsList.forEach((attributeGroup) => {
            attributeGroup.attributes.forEach((attribute) => {
                initialObject.orderInfo[attribute.id] = '';
            })
        })
        return initialObject;
    }

    // This is exclusively for copy order popups
    const copyAndInitializeOrderObject = (data) => {
        const initialObject = {
            processId: data?.process?.id,
            processSteps: [],
            orderProcessStepInfo: [],
            orderInfo: {},
            isTemplate: false,
            templateName: ''
        };
        const user = `${data?.orderProcessStepInfo[0]?.user?.firstName} ${data?.orderProcessStepInfo[0]?.user?.lastName}`;

        data?.orderProcessSteps?.forEach((ops) => {
            const processStepId = ops?.processStep?.id;
            const resourceId = ops?.resource?.id;
            initialObject.processSteps.push({
                processStepId,
                resourceId,
                processStepPosition: ops?.processStepPosition,
            });
            initialObject.orderProcessStepInfo.push({
                createdAt: ops?.orderProcessStepInfo[0]?.createdAt,
                user,
                comment: ops?.orderProcessStepInfo[0]?.comment,
                processStepId,
                resourceId,
            });
        })
        Object.keys(data?.orderInfo).forEach((externalIdentifier) => {
            initialObject.orderInfo[externalIdentifier] = data?.orderInfo[externalIdentifier]?.value;
        })
        return initialObject;
    }

    const fetchTemplates = async () => {
        const selectedProcess = filterItems.find((fi) => fi.key === 'processId');
        const processId = selectedProcess?.value;
        if (processId) {
            try {
                const response = await setting.getTemplateList(processId);
                if (!response?.data?.length) {
                    throw new Error('no-template-exists');
                } else {
                    setTemplateList(response?.data);
                    setShowOrderTemplateModal(true);
                }
            } catch(error) {
                showError(t, error);
            }
        }
    }

    const deleteOrders = async () => {
        const localSelectedOrders = clone(selectedOrders);
        const orderIDs = localSelectedOrders.map(order => order.id);
        try {
            const response = await setting.deleteOrders({ orders: orderIDs });
            deleteOrderListener(response?.data)
            showSuccess(t, response?.message);
        } catch (error) {
            showError(t, error)
        }
        setShowConfirmDeleteDialog(false);
    }

    const onSelectedPriority = async (priority) => {
        const payload = {
            orders: selectedOrders.map((ord) => ord.id),
            priority: Number(priority),
        };
        updateOrderStatus(payload);
    }

    const onAttributeSelection = (selectedAtt) => {
        localStorage.setItem('sortFilter', JSON.stringify(selectedAtt));
        setSelAttribute(selectedAtt)
    };

    return <div className="planning-container">
        <div className="d-flex mt-2 filterbar light">
            <FilterBar
                items={filterItems}
                onFilterAction={onFilterAction}
                processStepsCount={processStepList.length}
            />
            <div className="d-flex w-100">
                {processStepList?.map((processStep) => {
                    const selectedItem = processStepFilters.find((stFilter) => stFilter.processStepId === processStep.id);
                    return <StepItem
                        selectedKey={selectedItem ? selectedItem?.resourceId : ''}
                        data={processStep}
                        key={processStep.id}
                        onFilterAction={onFilterAction}
                    />
                })}
            </div>
        </div>
        <ActionBar
            onAttributeSelection={onAttributeSelection}
            selectedAttribute={selAttribute}
            attributes={attributes}
            onAction={onAction}
            filteredOrderCount={filteredOrders.length}
            selectedOrderCount={selectedOrders.length}
            isDropdownButtonDisabled={isDropdownButtonDisabled()}
            defaultActionButton={getDefaultActionButton()}
            dropdownButtonOptions={getDropDownOptions()}
            actions={getActButtons()}
            onAttributeReset={() => setSelAttribute(mapAttributeOptions(defaultSortFilters))}
            showNewOrderButton={orderType === 'planning'}
        />
        {processList?.length ? 
            <div className='order-grid'>
                <AgGridAtom
                    columnDefs={getColumnDef()}
                    rowData={filteredOrders}
                    onRowSelection={(selectedRows) => {
                        setSelectedOrders(selectedRows);
                    }}
                    rowSelection={'multiple'}
                    getRowClass={getRowClass}
                    enableBrowserTooltips
                />
            </div> : 'No Process'}

        {showInfoBox && <InfoBoxModal
            show={showInfoBox}
            selectedRowInfo={selectedRowInfo}
            onClose={() => setShowInfoBox(false)}
            onSubmit={onSaveInfo}
            selectedOrders={selectedOrders}
        />
        }

        {showDetailModal && <DetailModal
            orderType={orderType}
            attributeGroups={attributeGroupsList}
            selectedOrders={selectedOrders}
            show={showDetailModal}
            onClose={() => {
                setShowDetailModal(false);
            }}
            onSubmit={(info) => {
                if (orderType !== 'planning') {
                    setShowDetailModal(false);
                } else {
                    onOrderUpdate(info);
                }
            }}
            hideFooter
            onAddProcessStepClick={() => {
                setShowAddProcessStep(true);
            }} 
            onClickInfo={() => onStatusClick(selectedOrders[0])}
            updateLocalProcessStep={(orInfo) => {
                if (orderType === 'implement') {
                    updateProcessSteps(orInfo);
                }
            }}
            socketId={socketRef.current.id}
        />}
        
        {showConfirmDialogInfo.visible &&  <ModalAtom 
            title={t('update-order-status')}
            show 
            handleClose={() => setShowConfirmDialogInfo(initialConfirmDialogInfo)}
            handleSubmit={() => updateOrders()}
            body={<>{t('confirm-order-status-text')}</>}
        />}
        
        {showAddProcessStep && <AddProcessStepModal 
            show={showAddProcessStep}
            onClose={() => setShowAddProcessStep(false)}
            onSubmit={onAddProcessStep}
            selectedOrders={selectedOrders[0]}
            processSteps={processStepList} />}

        {showConfirmDeleteDialog && <ModalAtom 
            title={t('delete-order')}
            show 
            handleClose={() => setShowConfirmDeleteDialog(false)}
            handleSubmit={deleteOrders}
            body={<>{t('confirm-order-delete-text')}</>}
        />}
        
        {showSelectPriorityModal && <SelectPriorityModal 
            show={showSelectPriorityModal}
            onClose={() => setShowSelectPriorityModal(false)}
            onSubmit={onSelectedPriority}
            selectedOrders={selectedOrders}
        />}

        <NewOrderModals
            orderType={orderType}
            attributeGroupsList={attributeGroupsList}
            processSteps={processStepList}
            newOrderData={newOrderData}
            setNewOrderData={setNewOrderData}
            showNewOrderModal={showNewOrderModal}
            setShowNewOrderModal={setShowNewOrderModal}
            initializeNewOrderObject={initializeNewOrderObject}
            copyAndInitializeOrderObject={copyAndInitializeOrderObject}
            showOrderTemplateModal={showOrderTemplateModal}
            setShowOrderTemplateModal={setShowOrderTemplateModal}
            templateList={templateList}
        />
    </div>
}

export default OpenOrderPage;
