import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setting } from '../../store/services/index';
import { AuthContext } from '../../contexts/AuthProvider';

function SettingPage(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const navigate = useNavigate();

    const getUserInfo = async () => {
        let res = '';
        let role = auth?.role;
        if (!role) {
            res = await setting.getUserInfo();
            role = res.data.role;
            setAuth({ ...auth, role: res.data.role, name: res.data.name})
        }
        if (!['ADMIN', 'SUPER_ADMIN'].includes(role)) {
            localStorage.setItem('selectedMenu', 'order');
            navigate('/order/open');
            return;
        }
        if (!['SUPER_ADMIN'].includes(role)) {
            if (['category', 'manufacturer', 'type', 'archive-filter'].includes(props.pagekey)) {
                localStorage.setItem('selectedMenu', 'order');
                navigate('/order/open');
            }
        }
    };

    useEffect(() => {
        getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{props.children}</>

}

export default SettingPage;
