function CustomCheckbox(props) {
    const { value, column, node } = props;

    const checkedHandler = (event) => {
        let checked = event.target.checked;
        let colId = column.colId;
        node.setDataValue(colId, {
            disabled: value.disabled,
            value: checked
        });
    }

    return (
        <input 
            type="checkbox" 
            onChange={checkedHandler}
            disabled={value.disabled}
            checked={value.value}
        />
    )
}

export default CustomCheckbox;