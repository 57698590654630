import React from 'react';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import { ButtonAtom } from '../../../../../../components/atoms/atom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import './sortable-list.scss';

const DragHandle = sortableHandle(() => <FontAwesomeIcon icon={faBars} />);

const arrayMove = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};


const SubAttributeSortableList = (props) => {
    const { listItems, onListItemSortEnd, onEdit, onDelete } = props;

    const onSortEnd = ({oldIndex, newIndex}) => {
        onListItemSortEnd(arrayMove(listItems, oldIndex, newIndex));
    };

    const SortableItem = sortableElement(({item}) => {
        return  <div className='sortable-item'>
            <DragHandle />
            <div style={{ width: '100%', cursor: 'pointer', marginLeft: 10 }}> {item?.label}</div>
            <div className='d-flex'>
                <ButtonAtom 
                    className="btn btn-primary text-white mx-2"
                    onClick={() => onEdit(item)}>
                    <FontAwesomeIcon icon={faEdit} />
                </ButtonAtom>
                <ButtonAtom 
                    className="btn btn-primary text-white mx-2"
                    onClick={() => onDelete(item)}>
                    <FontAwesomeIcon icon={faTrash} />
                </ButtonAtom>
            </div>
        </div>
    });
    

    const SortableList = sortableContainer(({items}) => {
        return (
            <div className='sortable-container'>
                {items.map((item, index) => (
                    <SortableItem key={`item-${item?.id}`} index={index} item={item} />
                ))}
            </div>
        );
    });

    return <SortableList items={listItems} onSortEnd={onSortEnd} useDragHandle />;
}
export default SubAttributeSortableList;