import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import { ModalAtom, TextFieldAtom } from "../../../components/atoms/atom";

function SaveAsTemplateModal(props) {
    const { show, onClose, onSubmit, changeTemplateName } = props;
    const { t } = useTranslation();
    const [templateNameObj, setTemplateNameObj] = useState({
        value: '',
        error: '',
    });

    const onSaveTemplate = () => {
        if (!templateNameObj.value) {
            setTemplateNameObj({...templateNameObj, error: 'error-template-name-required'});
            return;
        }   
        onSubmit();      
    }
    
    return (
        <ModalAtom 
            title={t('save-as-template')}
            show={show} 
            handleClose={onClose}
            handleSubmit={onSaveTemplate}
            body={<Container>
                <div className='body-inner'>
                    <span>{t('type-template-name')}</span><span>
                        <TextFieldAtom
                            className='form-control'
                            value={templateNameObj.value}
                            type='text'
                            onChange={(e) => {
                                const value = e.target.value;
                                setTemplateNameObj({...templateNameObj, 
                                    value: value, 
                                    error: value ? '' : 'error-template-name-required'
                                });
                                changeTemplateName(value);
                            }} 
                            error={templateNameObj.error}
                        />
                    </span>
                </div>
            </Container>}
        />
    );
}

export default SaveAsTemplateModal;
