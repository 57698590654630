import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const getProcessAttributesTableColumns = (t, onSubAttributeEdit, onSubAttributeDelete) => {
    return [
        {
            dataField: 'provided',
            text: t('provided'),
            sort: true
        }, {
            dataField: 'label',
            text: t('name'),
            sort: true
        },
        {
            text: t('action'),
            sortable: false,
            formatter: (cell, row, rowIndex) => {
                return <div className="d-flex text-uppercase ag-action-cell">   
                    <p role="button" 
                        onClick={() => onSubAttributeEdit(row, rowIndex)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </p>
                    <p role="button" 
                        onClick={() => onSubAttributeDelete(row, rowIndex)}
                        className="mx-2">
                        <FontAwesomeIcon icon={faTrash} />
                    </p>
                </div>
            }
        }
    ];
}

export {
    getProcessAttributesTableColumns
}