/* eslint-disable no-template-curly-in-string */
const AG_GRID_LOCALE_DE = {
    // Set Filter
    selectAll: '(Alles auswählen)',
    selectAllSearchResults: '(Alle Suchergebnisse auswählen)',
    searchOoo: 'Suche...',
    blanks: '(Leerzeichen)',
    noMatches: 'Keine Treffer',
  
    // Number Filter & Text Filter
    filterOoo: 'Filter...',
    equals: 'Entspricht',
    notEqual: 'Nicht gleich',
    blank: 'Leer',
    notBlank: 'Nicht leer',
    empty: 'Wähle eine',
  
    // Number Filter
    lessThan: 'Kleiner als',
    greaterThan: 'Größer als',
    lessThanOrEqual: 'Kleiner als oder gleich',
    greaterThanOrEqual: 'Größer als oder gleich',
    inRange: 'Im Bereich',
    inRangeStart: 'von',
    inRangeEnd: 'bis',
  
    // Text Filter
    contains: 'Enthält',
    notContains: 'Enthält nicht',
    startsWith: 'Beginnt mit',
    endsWith: 'Endet mit',
  
    // Date Filter
    dateFormatOoo: 'jjjj-mm-tt',
  
    // Filterbedingungen
    andCondition: 'AND',
    orCondition: 'OR',
  
    // Filter Buttons
    applyFilter: 'Anwenden',
    resetFilter: 'Zurücksetzen',
    clearFilter: 'Löschen',
    cancelFilter: 'Abbrechen',
  
    // Filter Titles
    textFilter: 'Text-Filter',
    numberFilter: 'Zahlenfilter',
    dateFilter: 'Datumsfilter',
    setFilter: 'Filter setzen',
  
    // Side Bar
    columns: 'Columns',
    filters: 'Filter',
  
    // columns tool panel
    pivotMode: 'Pivot-Modus',
    groups: 'Zeilengruppen',
    rowGroupColumnsEmptyMessage: 'Ziehen Sie hier, um Zeilengruppen festzulegen',
    values: 'Werte',
    valueColumnsEmptyMessage: 'Ziehen Sie hier, um zu aggregieren',
    Pivots: 'Spaltenbezeichnungen',
    pivotColumnsEmptyMessage: 'Ziehen Sie hier, um Spaltenbeschriftungen festzulegen',
  
    // Header of the Default Group Column
    group: 'Gruppe',
  
    // Row Drag
    rowDragRows: 'rows',
  
    // Other
    loadingOoo: 'Loading...',
    noRowsToShow: 'Keine anzuzeigenden Zeilen',
    enabled: 'Aktiviert',
  
    // Menu
    pinColumn: 'Pin-Spalte',
    pinLeft: 'Pin Links',
    pinRight: 'Anstecknadel rechts',
    noPin: 'Keine Anstecknadel',
    valueAggregation: 'Wert-Aggregation',
    autosizeThiscolumn: 'Diese Spalte automatisch vergrößern',
    autosizeAllColumns: 'Alle Spalten automatisch vergrößern',
    groupBy: 'Gruppieren nach',
    ungroupBy: 'Gruppierung aufheben nach',
    addToValues: '${variable} zu den Werten hinzufügen',
    removeFromValues: '${Variable} aus den Werten entfernen',
    addToLabels: 'Füge ${variable} zu Etiketten hinzu',
    removeFromLabels: '${variable} aus Beschriftungen entfernen',
    resetColumns: 'Spalten zurücksetzen',
    expandAll: 'Alles aufklappen',
    collapseAll: 'Alle schließen',
    copy: 'Kopieren',
    ctrlC: 'Strg+C',
    copyWithHeaders: 'Kopieren mit Kopfzeilen',
    copyWithGroupHeaders: 'Kopieren mit Gruppenkopfzeilen',
    paste: 'Einfügen',
    ctrlV: 'Strg+V',
    export: 'Exportieren',
    csvExport: 'CSV-Export',
    excelExport: 'Excel-Export',
  
    // Enterprise Menu Aggregation and Status Bar
    sum: 'Summe',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    avg: 'Durchschnitt',
    filteredRows: 'Gefiltert',
    selectedRows: 'Ausgewählt',
    totalRows: 'Gesamtzeilen',
    totalAndFilteredRows: 'Zeilen',
    more: 'Mehr',
    to: 'bis',
    of: 'von',
    page: 'Seite',
    nextPage: 'Nächste Seite',
    lastPage: 'Letzte Seite',
    firstPage: 'Erste Seite',
    previousPage: 'Vorherige Seite',
  
    // Pivoting
    pivotColumnGroupTotals: 'Gesamt',
  
    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot-Diagramm und Pivot-Modus',
    pivotChart: 'Pivot-Diagramm',
    chartRange: 'Diagrammbereich',
  
    columnChart: 'Säule',
    groupedColumn: 'Gruppiert',
    stackedColumn: 'Gestapelt',
    normalizedColumn: '100% gestapelt',
  
    barChart: 'Balken',
    groupedBar: 'Gruppiert',
    stackedBar: 'Gestapelt',
    normalizedBar: '100% gestapelt',
  
    pieChart: 'Pie',
    pie: 'Torte',
    doughnut: 'Krapfen',
  
    line: 'Linie',
  
    xyChart: 'X Y (Streuung)',
    scatter: 'Streuung',
    bubble: 'Blase',
  
    areaChart: 'Fläche',
    area: 'Fläche',
    stackedArea: 'Gestapelt',
    normalizedArea: '100% gestapelt',
  
    histogramChart: 'Histogramm',
  
    combinationChart: 'Kombination',
    columnLineCombo: 'Spalte & Linie',
    AreaColumnCombo: 'Fläche & Säule',
  
    // Charts
    pivotChartTitle: 'Pivot-Diagramm',
    rangeChartTitle: 'Bereichsdiagramm',
    settings: 'Einstellungen',
    data: 'Daten',
    format: 'Format',
    categories: 'Kategorien',
    defaultCategory: '(None)',
    series: 'Reihe',
    xyValues: 'X Y Werte',
    paired: 'Gepaarter Modus',
    axis: 'Achse',
    navigator: 'Navigator',
    color: 'Farbe',
    thickness: 'Dicke',
    xType: 'X-Typ',
    automatic: 'Automatisch',
    category: 'Kategorie',
    number: 'Zahl',
    time: 'Zeit',
    autoRotate: 'Automatische Drehung',
    xRotation: 'X-Drehung',
    yRotation: 'Y-Drehung',
    ticks: 'Ticks',
    width: 'Breite',
    height: 'Höhe',
    length: 'Länge',
    padding: 'Füllung',
    spacing: 'Abstand',
    chart: 'Diagramm',
    title: 'Titel',
    titlePlaceholder: 'Diagrammtitel - Doppelklick zum Bearbeiten',
    background: 'Hintergrund',
    font: 'Schriftart',
    top: 'Oben',
    right: 'Rechts',
    bottom: 'Unten',
    links: 'Links',
    labels: 'Beschriftungen',
    size: 'Größe',
    minSize: 'Mindestgröße',
    maxSize: 'Maximale Größe',
    legend: 'Legende',
    position: 'Position',
    markerSize: 'Größe der Markierung',
    markerStroke: 'Marker-Strich',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Element-Abstand',
    itemPaddingX: 'Element-Padding X',
    itemPaddingY: 'Element-Padding Y',
    layoutHorizontalSpacing: 'Horizontaler Abstand',
    layoutVerticalSpacing: 'Vertikaler Abstand',
    strokeWidth: 'Strichstärke',
    offset: 'Versatz',
    Offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    Markierungen: 'Markierungen',
    Schatten: 'Schatten',
    blur: 'Unschärfe',
    xOffset: 'X-Offset',
    yOffset: 'Y-Offset',
    lineWidth: 'Linienbreite',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Kursiv',
    boldItalic: 'Fett kursiv',
    predefined: 'Vordefiniert',
    fillOpacity: 'Füll-Deckkraft',
    strokeOpacity: 'Linientransparenz',
    histogramBinCount: 'Bin Count',
    columnGroup: 'Säule',
    barGroup: 'Balken',
    pieGroup: 'Torte',
    lineGroup: 'Linie',
    scatterGroup: 'X Y (Streuung)',
    areaGroup: 'Fläche',
    histogramGroup: 'Histogramm',
    combinationGroup: 'Kombination',
    groupedColumnTooltip: 'Gruppiert',
    stackedColumnTooltip: 'Gestapelt',
    normalizedColumnTooltip: '100% gestapelt',
    groupedBarTooltip: 'Gruppiert',
    stackedBarTooltip: 'Gestapelt',
    normalizedBarTooltip: '100% gestapelt',
    pieTooltip: 'Torte',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Linie',
    groupedAreaTooltip: 'Fläche',
    stackedAreaTooltip: 'Gestapelt',
    normalizedAreaTooltip: '100% gestapelt',
    scatterTooltip: 'Streuung',
    bubbleTooltip: 'Blase',
    histogramTooltip: 'Histogramm',
    columnLineComboTooltip: 'Säule & Linie',
    areaColumnComboTooltip: 'Fläche & Säule',
    customComboTooltip: 'Benutzerdefinierte Kombination',
    noDataToChart: 'Es sind keine Daten für das Diagramm verfügbar.',
    pivotChartRequiresPivotMode: 'Pivot-Diagramm erfordert aktivierten Pivot-Modus.',
    chartSettingsToolbarTooltip: 'Menü',
    chartLinkToolbarTooltip: 'Mit Raster verbunden',
    chartUnlinkToolbarTooltip: 'Nicht mit dem Raster verknüpft',
    chartDownloadToolbarTooltip: 'Diagramm herunterladen',
    seriesChartType: 'Seriendiagrammtyp',
    seriesType: 'Reihentyp',
    secondaryAxis: 'Sekundäre Achse',
  
    // ARIA
    ariaChecked: 'geprüft',
    ariaColumn: 'Column',
    ariaColumnGroup: 'Spaltengruppe',
    ariaColumnList: 'Spaltenliste',
    ariaColumnSelectAll: 'Alle Spalten umschalten',
    ariaDateFilterInput: 'Datumsfilter-Eingabe',
    ariaDefaultListName: 'Liste',
    ariaFilterColumnsInput: 'Filter-Spalten-Eingabe',
    ariaFilterFromValue: 'Filter von Wert',
    ariaFilterInput: 'Filter Eingabe',
    ariaFilterList: 'Filter Liste',
    ariaFilterToValue: 'Nach Wert filtern',
    ariaFilterValue: 'Wert filtern',
    ariaFilteringOperator: 'Filterungs-Operator',
    ariaHidden: 'versteckt',
    ariaIndeterminate: 'unbestimmt',
    ariaInputEditor: 'Eingabe-Editor',
    ariaMenuColumn: 'Drücken Sie CTRL ENTER, um das Spaltenmenü zu öffnen.',
    ariaRowDeselect: 'Drücken Sie SPACE, um die Auswahl dieser Zeile aufzuheben',
    ariaRowSelectAll: 'Drücken Sie die Leertaste, um die Auswahl aller Zeilen umzuschalten',
    ariaRowToggleSelection: 'Drücken Sie die Leertaste, um die Zeilenauswahl umzuschalten',
    ariaRowSelect: 'Drücken Sie die Leertaste, um diese Zeile auszuwählen',
    ariaSearch: 'Suchen',
    ariaSortableColumn: 'Drücken Sie ENTER zum Sortieren',
    ariaToggleVisibility: 'Drücken Sie SPACE, um die Sichtbarkeit umzuschalten',
    ariaUnchecked: 'nicht abgehakt',
    ariaVisible: 'sichtbar',
    ariaSearchFilterValues: 'Suchfilterwerte',
  
    // ARIA Labels for Drop Zones
  
    ariaRowGroupDropZonePanelLabel: 'Zeilengruppen',
    ariaValuesDropZonePanelLabel: 'Werte',
    ariaPivotDropZonePanelLabel: 'Spaltenbezeichnungen',
    ariaDropZoneColumnComponentDescription: 'Zum Entfernen DELETE drücken',
    ariaDropZoneColumnValueItemDescription:
      'Drücken Sie ENTER, um den Aggregationstyp zu ändern',
  
    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Column Menu',
    ariaLabelCellEditor: 'Zellen-Editor',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Auswahlfeld',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Kontextmenü',
    ariaLabelSubMenu: 'UnterMenü',
    ariaLabelAggregationFunction: 'Aggregationsfunktion',
  
    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: '.',
    decimalSeparator: ',',
};

export default AG_GRID_LOCALE_DE;