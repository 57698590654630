import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from 'react-bootstrap';
import './hover-dropdown.scss';

function HoverDropdownAtom(props) {
    const {
        label = '',
        value = '',
        menuItems = [],
        error = '',
        onChange = () => {},
        titleData = {},
        hideAllOption = false,
        disabled = false,
    } = props;
    const { t } = useTranslation();

    const onSelect = (eventKey) => {
        onChange(eventKey);
    };

    const titleContainer = () => {
        if (!value) {
            return DEFAULT_OPTION;
        }
        const keyPropExists = menuItems.filter((menu) => menu.hasOwnProperty('key'));
        let menuItem;
        if (keyPropExists.length) {
            menuItem = menuItems.find((menu) => menu.key === value);
        } else {
            menuItem = menuItems.find((menu) => menu.id === value);
        }

        if (!menuItem) {
            return DEFAULT_OPTION;
        }
        return <div className="title-container">
            <img className="logo-preview" src={menuItem?.icon} alt='' />
            <div>
                <span>{menuItem?.name}</span>
                <span className="subtitle">{menuItem?.locationName} {menuItem?.processStepName && `[${menuItem?.processStepName}]`}</span>
            </div>
        </div>
    };

    const DEFAULT_OPTION = <div className="title-container">
        <img className="logo-preview" src={titleData.icon} alt='' />
        <div>
            {t('all')}
        </div>
    </div>;

    return (
        <>
            {label ? <label>{label}</label> : ''}
            
            <Dropdown
                className="hover-dropdown"
                onSelect={onSelect}
                disabled={disabled}
            >
                <Dropdown.Toggle
                    className="main-style"
                    disabled={disabled}
                >
                    {titleContainer()}
                </Dropdown.Toggle>

                <Dropdown.Menu className="form-control" disabled={disabled}>
                    {!hideAllOption && <Dropdown.Item key='all' eventKey='all'>
                        {DEFAULT_OPTION}
                    </Dropdown.Item>}
                    {menuItems.map((menu) => <Dropdown.Item key={menu.key || menu.id} eventKey={menu.key || menu.id}>
                        <div className="title-container">
                            <img className="logo-preview" src={menu.icon} alt='' />
                            <div>
                                <span>{menu.name}</span>
                                <span className="subtitle">{menu.locationName} {menu?.processStepName && `[${menu?.processStepName}]`}</span>
                            </div>
                        </div>
                    </Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
            {error ? <p className="text-danger">{ t(error)}</p> : '' }
        </>
    );
}

export default HoverDropdownAtom;

