import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FilterBar } from "../../components/molecule/molecule";
import {  AgGridAtom } from '../../components/atoms/atom';
import { getImportExportTableColumns } from './constant';
import { getSearchAddFilter } from '../../utils/get-filter-items';
import { searchData } from '../../utils/search';
import { setting } from '../../store/services/index';
import {  getFormattedDate, showError, showInfo, showSuccess } from '../../utils/method';
import UploadExcelModal from "./upload-modal";
import { AuthContext } from "../../contexts/AuthProvider";

function ImportExportPage() {
    const { t } = useTranslation();
    const { helpMode } = useContext(AuthContext);
    const filterItems = getSearchAddFilter(t, { addTextKey: 'add-process' });
    const [leftFilterItems, setLeftFilterItems] = useState(filterItems.left || []);
    const [processList, setProcessList] = useState([]);
    const [filteredProcessList, setFilteredProcessList] = useState([]);
    const [showUploadFileModal, setShowUploadFileModal] = useState(false);
    const [selectedProcessId, setSelectedProcessId] = useState(null);

    const fetchProcess = async () => {
        try{
            let result = await setting.getProcess();
            result = (result?.data || []).map((record) => {
                return {
                    ...record,
                    provided: getFormattedDate(record.createdAt)
                }
            })
            setProcessList(result);
            setFilteredProcessList(result);
        } catch(error){
            showError(t, error);
        }
    }
    
    useEffect(() => {
        fetchProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDownload = async (data) => {
        await setting.downloadProcessExcel(data?.id);
        // const url = window.URL.createObjectURL(new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', `${data.name}.xlsx`);
        // document.body.appendChild(link);
        // link.click();
    }

    const onUpload = (data) => {
        setShowUploadFileModal(true);
        setSelectedProcessId(data?.id);
    }

    const uploadExcelFile = async (file, processId) => {
        try {
            const formData = new FormData();
            formData.append('files', file);
            let result = await setting.uploadExcelForProcessId(formData, processId);
            if (result) {
                showSuccess(t, result)
            } else {
                showInfo(t, 'ORDER_IMPORT_ERROR')
            }
            setShowUploadFileModal(false);
        } catch(error){
            showError(t, error);
        }
    }
    
    const processColumns = getImportExportTableColumns(t, onDownload, onUpload, helpMode);

    const onFilterAction = (index, key, info) => {
        if (key === 'search') {
            const localLeftFilterItems = [...leftFilterItems];
            localLeftFilterItems[0].value = info?.value;
            setLeftFilterItems(localLeftFilterItems);
            const filterProcessList = searchData(info?.value, processList, processColumns.slice(0, -1));
            setFilteredProcessList(filterProcessList);
        }
    };

    const closeUploadFileModal = () => {
        setShowUploadFileModal(false);
        setSelectedProcessId(null);
    }

    return (
        <>
            <FilterBar 
                leftItems={leftFilterItems}
                onFilterAction={onFilterAction}
            />
            <AgGridAtom 
                columnDefs={processColumns}
                rowData={filteredProcessList}
            />
            {showUploadFileModal && <UploadExcelModal
                show={showUploadFileModal}
                onClose={closeUploadFileModal}
                onSubmit={uploadExcelFile}
                selectedProcessId={selectedProcessId}
            />}
        </>
    );
}

export default ImportExportPage;
