import { faEdit, faTrash, faList, faGear } from '@fortawesome/free-solid-svg-icons';
import processIcon from '../../../../assets/images/process.svg';
import TableActionButtonAtom from '../../../../components/atoms/table-action-button/table-action-button';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HELP_MODES } from '../../../../utils/constant';

const getProcessTableColumns = (t, onProcessEdit, onProcessDelete, onProcessStep, onProcessAttributes, onProcessSetting, helpMode = HELP_MODES.BASIC) => {
    return [
        {
            field: 'provided',
            headerName: t('provided'),
            unSortIcon: true,
        },
        {
            field: 'name',
            headerName: t('name'),
            unSortIcon: true,
        },
        {
            field: 'externalSystemIdentifier',
            headerName: t('external-system-identifier'),
            unSortIcon: true,
        },
        {
            headerName: t('action'),
            sortable: false,
            autoHeight: true,
            maxWidth: 300,
            cellRenderer: (props) => {
                const {data, rowIndex} = props;
                return <div className="d-flex ag-action-cell flex-wrap">   
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faEdit}
                        onClickAction={() => onProcessEdit(data, rowIndex)}
                        tooltipTitle='action-edit'
                    />
                    <OverlayTrigger overlay={helpMode === HELP_MODES.ADVANCE ? <Tooltip>{t(`help-${helpMode}.action-process-step`)}</Tooltip> : <></>}>
                        <p className="mx-2" style={{ width: '100px', background: '#C2C2C2' }}
                            onClick={() => onProcessStep(data, rowIndex)}>
                            <img src={processIcon} alt='' />
                        </p>
                    </OverlayTrigger>
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faList}
                        className="mx-2"
                        onClickAction={() => onProcessAttributes(data, rowIndex)}
                        tooltipTitle='action-attribute'
                    />
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faGear}
                        className="mx-2"
                        onClickAction={() => onProcessSetting(data, rowIndex)}
                        tooltipTitle='action-setting'
                    />
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faTrash}
                        className="mx-2"
                        onClickAction={() => onProcessDelete(data, rowIndex)}
                        tooltipTitle='action-delete'
                    />
                </div>
            }
        }
    ];
}

export {
    getProcessTableColumns
}