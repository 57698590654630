import {
    iconFinishInTime,
    iconFinishDeliveredLate,
    iconLocked,
    iconLockedDeliveryDateExceeded,
    iconLockedCommissionDateExceeded,
    iconUnderClarification,
    iconUnderClarificationCommissionDateExceeded,
    iconUnderClarificationDeliveryDateExceeded,
    iconOpenInTime,
    iconOpenCommissionDateExceeded,
    iconOpenDeliveryDateExceeded,
    iconReleasedUnderClarification,
    iconReleasedUnderClarificationCommissionDateExceeded,
    iconReleasedUnderClarificationDeliveryDateExceeded,
    iconReleased,
    iconReleasedCommissionDateExceeded,
    iconReleasedDeliveryDateExceeded,
    iconInProgressInTime,
    iconInProgressCommissionDateExceeded,
    iconInProgressDeliveryDateExceeded,
    iconInProgressUnderClarification,
    iconInProgressUnderClarificationCommissionDateExceeded,
    iconInProgressUnderClarificationDeliveryDateExceeded,
    iconInProgressLocked,
    iconInProgressLockedCommissionDateExceeded,
    iconInProgressLockedDeliveryDateExceeded,
} from '../../../assets/images/status-icons';

const getStatusIcon = ({orderStatus, isLocked = false, isUnderClarification = false, startOnTime = false, completionOnTime = false, startTime = null}, t) => {
    if (orderStatus === 1 || orderStatus === 2) {
        if (isLocked) {
            if (completionOnTime === false) {
                return {
                    icon: iconLockedDeliveryDateExceeded,
                    title: t('status-tooltip-locked-delivery-date-exceeded'),
                };
            }
            if (startOnTime === false) {
                return {
                    icon: iconLockedCommissionDateExceeded,
                    title: t('status-tooltip-locked-commission-date-exceeded'),
                };
            }
            return {
                icon: iconLocked,
                title: t('status-tooltip-locked'),
            };
        }
        if (isUnderClarification) {
            if (completionOnTime === false) {
                return {
                    icon: iconUnderClarificationDeliveryDateExceeded,
                    title: t('status-tooltip-under-clarification-delivery-date-exceeded')
                };
            }
            if (startOnTime === false) {
                return {
                    icon: iconUnderClarificationCommissionDateExceeded,
                    title: t('status-tooltip-under-clarification-commission-date-exceeded')
                };
            }
            return {
                icon: iconUnderClarification,
                title: t('status-tooltip-under-clarification')
            };
        }
        if (completionOnTime && startOnTime) {
            return {
                icon: iconOpenInTime,
                title: t('status-tooltip-open-in-time'),
            };
        }
        if (completionOnTime && startOnTime === false) {
            return {
                icon: iconOpenCommissionDateExceeded,
                title: t('status-tooltip-open-commission-date-exceeded'),
            };
        }
        if (completionOnTime === false) {
            return {
                icon: iconOpenDeliveryDateExceeded,
                title: t('status-tooltip-open-delivery-date-exceeded'),
            };
        }
        if (startOnTime === false) {
            return {
                icon: iconOpenCommissionDateExceeded,
                title: t('status-tooltip-open-commission-date-exceeded'),
            };
        }
    } else if (orderStatus === 3) {
        if (isLocked) {
            if (!startTime) {
                if (completionOnTime === false) {
                    return {
                        icon: iconLockedDeliveryDateExceeded,
                        title: t('status-tooltip-locked-delivery-date-exceeded'),
                    };
                }
                if (startOnTime === false) {
                    return {
                        icon: iconLockedCommissionDateExceeded,
                        title: t('status-tooltip-locked-commission-date-exceeded'),
                    };
                }
                return {
                    icon: iconLocked,
                    title: t('status-tooltip-locked'),
                };
            } else {
                if (completionOnTime === false) {
                    return {
                        icon: iconInProgressLockedDeliveryDateExceeded,
                        title: t('status-tooltip-in-progress-locked-delivery-date-exceeded'),
                    };
                }
                if (startOnTime === false) {
                    return {
                        icon: iconInProgressLockedCommissionDateExceeded,
                        title: t('status-tooltip-in-progress-locked-commission-date-exceeded'),
                    };
                }
                return {
                    icon: iconInProgressLocked,
                    title: t('status-tooltip-in-progress-locked'),
                };
            }
        }
        if (isUnderClarification) {
            if (!startTime) {
                if (completionOnTime === false) {
                    return {
                        icon: iconReleasedUnderClarificationDeliveryDateExceeded,
                        title: t('status-tooltip-released-under-clarification-delivery-date-exceeded'),
                    };
                }
                if (startOnTime === false) {
                    return {
                        icon: iconReleasedUnderClarificationCommissionDateExceeded,
                        title: t('status-tooltip-released-under-clarification-commission-date-exceeded'),
                    };
                }
                return {
                    icon: iconReleasedUnderClarification,
                    title: t('status-tooltip-released-under-clarification'),
                };
            } else {
                if (completionOnTime === false) {
                    return {
                        icon: iconInProgressUnderClarificationDeliveryDateExceeded,
                        title: t('status-tooltip-in-progress-under-clarification-delivery-date-exceeded'),
                    };
                }
                if (startOnTime === false) {
                    return {
                        icon: iconInProgressUnderClarificationCommissionDateExceeded,
                        title: t('status-tooltip-in-progress-under-clarification-commission-date-exceeded'),
                    };
                }
                return {
                    icon: iconInProgressUnderClarification,
                    title: t('status-tooltip-in-progress-under-clarification'),
                };
            }
        }
        if (!startTime) {
            if ((completionOnTime === undefined && startOnTime === undefined)
                || (completionOnTime && startOnTime)
            ) {
                return {
                    icon: iconReleased,
                    title: t('status-tooltip-released'),
                };
            }
            if (completionOnTime && startOnTime === false) {
                return {
                    icon: iconReleasedCommissionDateExceeded,
                    title: t('status-tooltip-released-commission-date-exceeded'),
                };
            }
            if (completionOnTime === false && startOnTime === false) {
                return {
                    icon: iconReleasedDeliveryDateExceeded,
                    title: t('status-tooltip-released-delivery-date-exceeded')
                };
            }
        } else {
            if (completionOnTime && startOnTime) {
                return {
                    icon:iconInProgressInTime,
                    title: t('status-tooltip-in-progress-in-time'),
                };
            }
            if (completionOnTime && startOnTime === false) {
                return {
                    icon: iconInProgressCommissionDateExceeded,
                    title: t('status-tooltip-in-progress-commission-date-exceeded'),
                };
            }
            return {
                icon: iconInProgressDeliveryDateExceeded,
                title: t('status-tooltip-in-progress-delivery-date-exceeded'),
            };
        }
    } else if (orderStatus === 4) {
        if (completionOnTime && startOnTime) {
            return {
                icon: iconFinishInTime,
                title: t('status-tooltip-finish-in-time')
            };
        }
        return {
            icon: iconFinishDeliveredLate,
            title: t('status-tooltip-finish-delivered-late')
        };
    }
    return {
        icon: '',
        title: '',
    }
};

export {
    getStatusIcon,
}