import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { HELP_MODES } from "../../../utils/constant";

function TableActionButtonAtom(props) {
    const { t, helpMode = HELP_MODES.BASIC, icon = '', onClickAction = () => {}, tooltipTitle = '', ...rest } = props;
    const tooltipText = t(`help-${helpMode}.${tooltipTitle}`);
    return (
        <p onClick={() => onClickAction()} {...rest}>
            <OverlayTrigger overlay={(tooltipText && helpMode === HELP_MODES.ADVANCE)
                ? <Tooltip>{tooltipText}</Tooltip>
                : <></>
            }>
                <FontAwesomeIcon icon={icon} className="fa-lg" />
            </OverlayTrigger>
        </p>
    );
}

export default TableActionButtonAtom;
