import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ButtonAtom, MultiSelectAtom } from '../../../components/atoms/atom';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import "./action-bar.scss";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { AuthContext } from "../../../contexts/AuthProvider";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { HELP_MODES } from "../../../utils/constant";

function ActionBar(props) {
    const { t } = useTranslation();
    const { auth, helpMode } = useContext(AuthContext);

    const {
        onAction,
        filteredOrderCount,
        selectedOrderCount,
        defaultActionButton = {},
        dropdownButtonOptions = [],
        isDropdownButtonDisabled = false,
        actions = [],
        attributes= [],
        selectedAttribute = [],
        onAttributeSelection,
        onAttributeReset,
        showNewOrderButton = false,
    } = props;
    return (
        <div className="d-flex mt-4 mb-2 action">
            <div className="d-flex action-left me-2">
                <Dropdown as={ButtonGroup} className="me-2">
                    <Button variant="primary" disabled={isDropdownButtonDisabled} onClick={() => onAction('hoverDropDown', { value: defaultActionButton.id })}>{t(defaultActionButton.name)}</Button>
                    {dropdownButtonOptions.length > 0 &&
                        <>
                            <Dropdown.Toggle split disabled={isDropdownButtonDisabled} variant="primary" id="dropdown-split-basic" />
                            <Dropdown.Menu>
                                {
                                    dropdownButtonOptions.map((opt, index) => <Dropdown.Item key={index} variant="primary" onClick={() => onAction('hoverDropDown', { value: opt.id })}>{t(opt.name)}</Dropdown.Item>)
                                }
                            </Dropdown.Menu>
                        </>
                    }
                </Dropdown>
                {showNewOrderButton && 
                <OverlayTrigger overlay={helpMode === HELP_MODES.ADVANCE ? <Tooltip>{t(`help-${helpMode}.new-order`)}</Tooltip> : <></>}>
                    <Dropdown as={ButtonGroup} className="me-2">
                        <Button variant="primary" disabled={auth.role === 'SALES'} onClick={() => onAction('new')} title={t('action-new-order')}><FontAwesomeIcon icon={faPlus} /></Button>
                        <>
                            <Dropdown.Toggle split disabled={auth.role === 'SALES'} variant="primary" id="dropdown-split-basic" />
                            <Dropdown.Menu>
                                <Dropdown.Item variant="primary" onClick={() => onAction('new-order-template')}>{t('order-using-template')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </>
                    </Dropdown>
                </OverlayTrigger>
                }

                {actions.map((action, index) =>
                    <OverlayTrigger overlay={helpMode === HELP_MODES.ADVANCE ? <Tooltip>{t(`help-${helpMode}.${action.tooltip}`)}</Tooltip> : <></>}>
                        <span className="d-inline-block">
                            <ButtonAtom key={index} disabled={action.disabled} className="btn btn-secondary me-2" onClick={() => onAction(action.key)}>
                                <FontAwesomeIcon icon={action.icon} />
                            </ButtonAtom>
                        </span>
                    </OverlayTrigger>
                )}

                <MultiSelectAtom
                    value={selectedAttribute}
                    placeholder={t('select-attribute')}
                    options={attributes}
                    required
                    onChange={onAttributeSelection}
                    className="attribute-sort-filter"
                />
                <OverlayTrigger overlay={helpMode === HELP_MODES.ADVANCE ? <Tooltip>{t('help-advance.reset-sort-filters')}</Tooltip> : <></>}>
                    <span className="d-inline-block">
                        <ButtonAtom 
                            className="btn btn-primary ms-2"
                            onClick={() => onAttributeReset()}
                        >
                            {t('reset')}
                        </ButtonAtom>
                    </span>
                </OverlayTrigger>
                
            </div>
            <div className="d-flex">
                <div className="action-item mx-2">
                    <FontAwesomeIcon icon={faClone} />
                    <span className="mx-2">{t('filtered')}: {filteredOrderCount}</span>
                </div>
                <div className="action-item mx-2">
                    <FontAwesomeIcon icon={faClone} />
                    <span className="mx-2">{t('selected')}: {selectedOrderCount}</span>
                </div>
            </div>
        </div>

    );
}

export default ActionBar;
