import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { FilterBar } from "../../../../../components/molecule/molecule";
import { ModalAtom, AgGridAtom } from '../../../../../components/atoms/atom';
import { getProcessStepTableColumns } from './constant';
import AddProcessStepModal from '../add/add-process-step';
import { getSearchAddFilter } from '../../../../../utils/get-filter-items';
import { searchData } from '../../../../../utils/search';
import { setting } from '../../../../../store/services/index';
import { readFileAsync, showError, showToast } from '../../../../../utils/method';
import ProcessStepResourceModal from "../resource/process-step-resource";
import SettingsMenu from "../../../settings-menu/settings-menu";
import { AuthContext } from "../../../../../contexts/AuthProvider";

function ProcessStepList(props) {
    const { selectedProcess, onBack } = props;
    const { t } = useTranslation();
    const { helpMode } = useContext(AuthContext);
    const [processStepModalView, setProcessStepModalView] = useState('');
    const [selectedProcessStep, setSelectedProcessStep] = useState({});
    const [showDeleteModal, setShowdeleteModal] = useState(false);
    const [showResourceModal, setShowResourceModal] = useState(false);

    const filterItems = getSearchAddFilter(t, { addTextKey: 'add-process-step' });

    const leftItems = [
        {
            type: 'backButton',
            key: 'back'
        },
        {
            type: 'button',
            key: 'add',
            disabled: true,
            label: <div className="d-flex">
                <p className="text-uppercase m-0">{selectedProcess?.name}</p>
            </div>,
            className: "selected-process filter-bar-btn"
        }
    ];

    const [leftFilterItems, setLeftFilterItems] = useState(leftItems.concat(filterItems.left) || []);
    const [rightFilterItems] = useState(filterItems.right || []);
    const [processStepList, setProcessStepList] = useState([]);
    const [filteredProcessStep, setFilteredProcessStep] = useState([]);
    const [processAttributeList, setProcessAttributeList] = useState([]);
    const [resourceList, setResourceList] = useState([]);

    const fetchProcessStep = async () => {
        try {
            const result = await setting.getProcessStepsByProcessId(selectedProcess?.id);
            setProcessStepList(result?.data || []);
            setFilteredProcessStep(result?.data || []);
        } catch (error) {
            showError(t, error);
        }
    }

    const fetchProcessAttributes = async () => {
        try {
            let result = await setting.getProcessAttributesByProcessId(selectedProcess?.id);
            setProcessAttributeList(result?.result || []);
        } catch (error) {
            showError(t, error);
        }
    }

    const fetchResources = async () => {
        try {
            const result = await setting.getResource();
            setResourceList(result?.data || []);
        } catch (error) {
            showError(t, error);
        }
    }

    useEffect(() => {
        fetchProcessStep();
        fetchProcessAttributes();
        fetchResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onProcessStepEdit = (row, rowIndex) => {
        const resource = (row?.resources || []).map((resource) => {
            return {
                label: resource?.name,
                value: resource?.id
            }
        });
        setSelectedProcessStep({ ...row, resource });
        setProcessStepModalView('edit');
    };

    const onProcessStepDelete = (row, rowIndex) => {
        setSelectedProcessStep(row);
        setShowdeleteModal(true);
    }

    const onProcessStepResource = (row) => {
        setSelectedProcessStep(row);
        setShowResourceModal(true);
    }

    const onAddProcessStep = async (processStepInfo) => {
        let contentBuffer = null;
        if (processStepInfo?.iconFile) {
            if (typeof processStepInfo?.iconFile === 'string') {
                contentBuffer = processStepInfo?.iconFile;
            } else {
                contentBuffer = await readFileAsync(processStepInfo?.iconFile);
            }
        }
        try {
            const payload = {
                position: processStepInfo?.position,
                description: processStepInfo?.description,
                label: processStepInfo?.label,
                icon: contentBuffer,
                attributes: processStepInfo?.attributes || [],
                processId: selectedProcess?.id,
                resources: processStepInfo?.resource,
                externalSystemIdentifier: processStepInfo?.externalSystemIdentifier,
            };
            if (processStepModalView === 'add') {
                const res = await setting.addProcessStep(payload);
                showToast(t(res?.message), { type: 'success' });
            } else if (processStepModalView === 'edit') {
                const res = await setting.updateProcessStep(payload, processStepInfo.id);
                showToast(t(res?.message), { type: 'success' });
            }
            fetchProcessStep();
            setProcessStepModalView('');
        } catch (error) {
            showError(t, error);
        }
    }
    const processStepColumns = getProcessStepTableColumns(t, onProcessStepEdit, onProcessStepDelete, onProcessStepResource, helpMode);

    const onFilterAction = (index, key, info) => {
        if (key === 'search') {
            const localLeftFilterItems = [...leftFilterItems];
            const searchIndex = localLeftFilterItems.findIndex(item => item.type === 'search');
            localLeftFilterItems[searchIndex].value = info?.value;
            setLeftFilterItems(localLeftFilterItems);
            const filterProcessStepList = searchData(info?.value, processStepList, processStepColumns.slice(0, -1));
            setFilteredProcessStep(filterProcessStepList);
        }
        if (key === 'add') {
            setSelectedProcessStep({});
            setProcessStepModalView('add');
        }
        if (key === 'back') {
            onBack();
        }
    };

    const onDeleteProcessStep = async () => {
        try {
            const res = await setting.deleteProcessStep(selectedProcessStep?.id);
            showToast(t(res?.message), { type: 'success' });
            fetchProcessStep();
            setShowdeleteModal(false);
        } catch (error) {
            showError(t, error);
        }
    }

    const onAddResourceToProcessStep = async (payload) => {
        try {
            const res = await setting.addResourceToProcessStep(selectedProcessStep?.id, payload);
            showToast(t(res?.message), { type: 'success' });
            fetchProcessStep();
            setShowResourceModal(false);
        } catch (error) {
            showError(t, error);
        }
    }

    const getPosition = () => {
        if (!filteredProcessStep.length) {
            return 1;
        }
        const positions = filteredProcessStep.map(attr => attr.position); 
        return Math.max(...positions) + 1;
    };

    return (
        <>
            <SettingsMenu />
            <FilterBar
                leftItems={leftFilterItems}
                rightItems={rightFilterItems}
                onFilterAction={onFilterAction} />

            <AgGridAtom
                columnDefs={processStepColumns}
                rowData={filteredProcessStep}
            />

            {processStepModalView && <AddProcessStepModal
                mode={processStepModalView}
                processStepInfo={selectedProcessStep}
                show
                onClose={() => { setProcessStepModalView(''); setSelectedProcessStep('') }}
                onSubmit={onAddProcessStep}
                attributeList={processAttributeList}
                resourceList={resourceList}
                newProcessStepPosition={getPosition()}
            />}
            <ModalAtom
                title={t('delete-process-step')}
                body={<p>{t('delete-process-step-warning')}</p>}
                saveText="delete"
                show={showDeleteModal}
                handleClose={() => setShowdeleteModal(false)}
                handleSubmit={onDeleteProcessStep}
            />
            {showResourceModal && <ProcessStepResourceModal
                show={showResourceModal}
                defaultSelectedRow={selectedProcessStep?.resources?.map(r => r.id)}
                onClose={() => setShowResourceModal(false)}
                onSubmit={(payload) => onAddResourceToProcessStep({
                    resources: payload,
                })}
            />}
        </>
    );
}

export default ProcessStepList;
