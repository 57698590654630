import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
    faSquareCaretDown,
    faSquareCaretUp,
    faCirclePlay,
    faCircleNotch,
    faCircle,
    faCirclePause,
    faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import "./operator.scss";
import { Col, Row, Table, Button } from "react-bootstrap";
import { io } from "socket.io-client";
import { AuthContext } from '../../contexts/AuthProvider';
import { setting } from '../../store/services';
import { getCellValue, getComparator, getFormattedDate, getWeekdayName, showError, showInfo, stableSort } from '../../utils/method';
import { DatePickerAtom, HoverDropdownAtom } from '../../components/atoms/atom';
import "bootstrap/js/src/collapse.js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SOCKET_RESPONSE from '../../constant/socket';
import { useStateWithRef } from '../../utils/useStateWithRef';
import { getStatusIcon } from '../order/utils/status';
import { SOCKET_URL } from '../../constant/url';
import clone from 'rfdc/default';
import { iconSortAscending, iconSortDescending, iconSortUnSort } from '../../assets/images/ag-grid';

function OperatorPage() {
    const filterObj = JSON.parse(localStorage.getItem('filterObj')) || {};
    const { auth } = useContext(AuthContext);
    const [resources, setResources] = useState([]);
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState(orders || []);
    const [rowsExpanded, setRowsExpanded] = useState(false);
    const [steps, setSteps] = useState([]);
    // const [processSteps, setProcessSteps] = useState([]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [attributeFilterTypes, setAttributeFilterTypes] = useState({});
    const socketRef = useRef();

    // state with ref
    const [resourceFilter, setResourceFilter, resourceFilterRef] = useStateWithRef(filterObj?.resourceFilter || null);
    const [dateFilter, setDateFilter, dateFilterRef] = useStateWithRef(() => {
        const dateValue = filterObj?.dateFilter;
        return dateValue ? new Date(dateValue) : new Date()
    });
    const [processStepFilter, setProcessStepFilter, processStepFilterRef] = useStateWithRef(filterObj?.processStepFilter || null);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const fetchOperatorData = () => {
        fetchOrders();
        showInfo(t, 'data-synced');
    };

    useEffect(() => {
        socketRef.current = io(SOCKET_URL, {transports: ['websocket'], upgrade: false, autoConnect: true});

        socketRef.current.on(SOCKET_RESPONSE.CONNECT, () => {
            // console.log(SOCKET_RESPONSE.CONNECT, socketRef.current.id);
            if (resourceFilterRef.current) {
                socketRef.current.emit(SOCKET_RESPONSE.JOIN_ROOM, '', `r/${resourceFilterRef.current}/${processStepFilterRef.current}`, (roomIdLeft, roomIdJoined) => {
                    console.log('roomIdLeft', roomIdLeft);
                    console.log('roomIdJoined', roomIdJoined);
                });
            }
        });

        socketRef.current.on(SOCKET_RESPONSE.CONNECT_ERROR, () => {
            setTimeout(() => socketRef.current.connect(), 5000);
        });

        socketRef.current.on(SOCKET_RESPONSE.DISCONNECT, () => {
            // console.log(SOCKET_RESPONSE.DISCONNECT);
        });

        socketRef.current.on(SOCKET_RESPONSE.OPERATOR_ORDER_LIST, fetchOperatorData);
        return () => {
            socketRef.current.off(SOCKET_RESPONSE.OPERATOR_ORDER_LIST, fetchOperatorData);
            socketRef.current.close();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        localStorage.setItem('filterObj', JSON.stringify({ dateFilter, resourceFilter, processStepFilter}))
    }, [dateFilter, resourceFilter, processStepFilter]);
    
    const fetchOrders = async () => {
        try {
            const queryArray = ['orderStatus=IMPLEMENT'];
            if (dateFilterRef.current) {
                queryArray.push(`date=${getFormattedDate(dateFilterRef.current)}`);
            }
            if (resourceFilterRef.current) {
                queryArray.push(`resource=${resourceFilterRef.current}`);
            }
            if (processStepFilterRef.current) {
                queryArray.push(`processStep=${processStepFilterRef.current}`);
            }
            let result = await setting.getWorkerOrders(queryArray.join('&'));
            setOrders(result?.data);
        } catch(error) {
            showError(t, error);
        }
    }

    const fetchProcessStepByProcessStepId = async () => {
        try {
            let result = await setting.getStepByProcessStepId(processStepFilter);
            setSteps(result.attributes);
        } catch(error) {
            showError(t, error);
        }
    }

    const fetchAllProcessSteps = async () => {
        try {
            let result = await setting.getProcessSteps();
            // setProcessSteps(result.data);
            return result.data;
        } catch(error) {
            showError(t, error);
        }
    }

    const filterResourcesAccordingToProcessStepPosition = (resourcesList, processSteps) => {
        const localResources = clone(resourcesList);
        const resourceDetail = [];
        localResources.forEach((localResource) => {
            processSteps?.forEach((processStep) => {
                const isResourceFoundInProcessStep = processStep.resources.some((resource) => resource.id === localResource.id);
                if (isResourceFoundInProcessStep) {
                    resourceDetail.push({
                        processStepPosition: processStep.position,
                        processStepName: processStep.label,
                        processStepId: processStep.id,
                        id: localResource.id,
                        name: localResource.name,
                        icon: localResource.icon,
                        locationName: localResource.locationName,
                        key: `${localResource.id}/${processStep.id}`
                        // This has been introduced to see to it that the id of a particular resource must be unique.
                    })
                }
            })
        })
        resourceDetail.sort((a, b) => {
            if (a.processStepPosition - b.processStepPosition !== 0) {
                return a.processStepPosition - b.processStepPosition;
            } else {
                let A = a.name.toUpperCase();
                let B = b.name.toUpperCase();
                return (A < B) ? -1 : (A > B) ? 1 : 0;
            }
        });
        console.log('resourceDetail', resourceDetail)
        return resourceDetail;
    }

    useEffect(() => {
        const localResources = auth?.resources;
        fetchAllProcessSteps()
            .then((processSteps) => {
                if (localResources?.length && processSteps?.length) {
                    const filteredResources = filterResourcesAccordingToProcessStepPosition(localResources, processSteps);
                    setResources(filteredResources);
                    // setResources(resources);
                    if (!resourceFilter) {
                        setResourceFilter(filteredResources[0]?.id);
                        setProcessStepFilter(filteredResources[0]?.processStepId);
                        socketRef.current.emit(SOCKET_RESPONSE.JOIN_ROOM, '', `r/${filteredResources[0]?.id}/${filteredResources[0]?.processStepId}`, (roomIdLeft, roomIdJoined) => {
                            console.log('roomIdLeft', roomIdLeft);
                            console.log('roomIdJoined', roomIdJoined);
                        });
                    }
                }
            });
        // console.log(resources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.resources]);

    useEffect(() => {
        if (resourceFilter && dateFilter && processStepFilter) {
            fetchOrders();
            fetchProcessStepByProcessStepId();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceFilter, dateFilter, processStepFilter]);

    const getTitleData = () => {
        if (resources?.length) {
            return {label: '', icon: ''};
        }
    }

    const expandCollapseRow = (order) => {
        navigate('/operator-detail', {
            state:  {
                orderId: order.id,
                attributes: steps,
                resourceId: resourceFilter,
                processStepId: processStepFilter,
            }
        });
    };

    const getAttributes = (viewType) => {
        // debugger;
        return steps.filter((step) => {
            return step[viewType];
        })
    }

    useEffect(() => {
        setAttributeFilterTypes(initializeAttributeFilterTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps])

    const sliceIntoChunks = (arr, chunkSize) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    const changeDate = (isAdd = true) => {
        let dateFilterVal = dateFilter;
        if (isAdd) {
            dateFilterVal = dateFilterVal.setDate(dateFilterVal.getDate() + 1);
        } else {
            dateFilterVal = dateFilterVal.setDate(dateFilterVal.getDate() - 1);
        }
        setDateFilter(new Date(dateFilterVal));
    }

    const handleChangeResourceFilter = (val) => {
        socketRef.current.emit(SOCKET_RESPONSE.JOIN_ROOM, `r/${resourceFilter}/${processStepFilter}`, `r/${val}`, (roomIdLeft, roomIdJoined) => {
            console.log('roomIdLeft', roomIdLeft);
            console.log('roomIdJoined', roomIdJoined);
        });
        const [resourceFilterId, processFilterId] = val.split("/");
        setResourceFilter(resourceFilterId);
        setProcessStepFilter(processFilterId);
    }

    // sorting attributes in ascending and descending orders =>
    // esi = externalSystemIdentifier
    const initializeAttributeFilterTypes = () => {
        const localAttributeFilterTypes = {}; // types can be 'ascending', 'descending' or null. Initialising with null,
        getAttributes('isVisibleCollapsed').forEach((attr) => {
            localAttributeFilterTypes[attr?.attribute?.id] = null
        });
        return localAttributeFilterTypes;
    }

    const setSortingAttributes = (attr, index) => {
        const esi = attr?.attribute?.id;
        const filterTypeESI = attributeFilterTypes[esi];
        const filterIcon = filterTypeESI === 'asc' ? iconSortAscending : filterTypeESI === 'desc' ? iconSortDescending : iconSortUnSort;
        const style = { height: "16px", width: "16px", position: "absolute", right: "0px" };
        const label = attr?.attribute?.label;
        return <th key={index} title={label} onClick={changeFilterTypes.bind(this, esi)}>{label}<img src={filterIcon} alt='' style={style}/></th>
    }

    const changeFilterTypes = (esi) => {
        const localFilterTypes = clone(attributeFilterTypes);
        const nullLocalFilterTypes = initializeAttributeFilterTypes();
        let filterType = localFilterTypes[esi];
        if (filterType === null) {
            filterType = 'asc';
        } else if (filterType === 'asc') {
            filterType = 'desc';
        } else if (filterType === 'desc') {
            filterType = null;
        }
        nullLocalFilterTypes[esi] = filterType;
        setAttributeFilterTypes(nullLocalFilterTypes);
    }

    useEffect(() => {
        // console.log(attributeFilterTypes);
        let localFilteredOrders = clone(orders);
        Object.keys(attributeFilterTypes).forEach((esi) => {
            if (attributeFilterTypes[esi] === null) {
                return;
            } else {
                localFilteredOrders = stableSort(orders, getComparator(attributeFilterTypes[esi], esi));
            }
        })
        setFilteredOrders(localFilteredOrders);
    }, [attributeFilterTypes, orders])

    return <>
        <div className="operator">
            <div className="operator-body">
                <div className="body-top">
                    <div className="body-top-left">
                        <HoverDropdownAtom
                            value={`${resourceFilter}/${processStepFilter}`}
                            menuItems={resources}
                            titleData={getTitleData()}
                            onChange={handleChangeResourceFilter}
                            hideAllOption
                        />
                    </div>
                    <div className="body-top-right">
                        <div onClick={() => changeDate(false)}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </div>
                        <div className="custom-date-input" onClick={() => setShowDatePicker(!showDatePicker)}>
                            <span>{getFormattedDate(dateFilter)}</span>
                            <span>{getWeekdayName(dateFilter)}</span>
                        </div>
                        <div className="d-none">
                            <DatePickerAtom
                                open={showDatePicker}
                                selected={dateFilter}
                            
                                onChange={(date) => {
                                    setDateFilter(date);
                                    setShowDatePicker(!showDatePicker);
                                }}
                            />
                        </div>
                        <div onClick={() => changeDate(true)}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                    </div>
                </div>
                
                <div className="body-table">
                    {orders.length ? getAttributes('isVisibleCollapsed').length ? <Table hover>
                        <thead>
                            <tr>
                                <th><FontAwesomeIcon icon={faCircleNotch} className="fa-xl" /></th>
                                <th><FontAwesomeIcon icon={faCircleNotch} className="fa-xl" /></th>
                                {getAttributes('isVisibleCollapsed').map(setSortingAttributes)}
                                <th>
                                    <Button
                                        className="btn btn-primary btn-expand-collapse"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target=".multi-collapse" aria-expanded="false"
                                        onClick={() => setRowsExpanded(!rowsExpanded)}
                                    >
                                        {rowsExpanded ?
                                            <FontAwesomeIcon icon={faSquareCaretUp} className="fa-xs" /> :
                                            <FontAwesomeIcon icon={faSquareCaretDown} className="fa-xs" />
                                        }
                                    </Button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredOrders?.map((order, index) => {
                                    const {icon, title} = getStatusIcon(order, t);
                                    let orderRowClass = '';
                                    if (order.priority === 1) {
                                        orderRowClass = 'row-priority-high';
                                    }
                                    if (order.priority === 2) {
                                        orderRowClass = 'row-priority-medium';
                                    }
                                    return <Fragment key={index}>
                                        <tr
                                            className={orderRowClass}
                                            data-bs-toggle="collapse"
                                            aria-controls={`multiCollapse${index}`}
                                        >
                                            <td>
                                                <img src={icon} alt='' style={{ height: '18px', cursor: 'pointer' }} title={title} />
                                            </td>
                                            <td onClick={() => expandCollapseRow(order)}>
                                                {order.orderProcessSteps[0].status === 2 && <FontAwesomeIcon icon={faCircle} className="fa-xl icon-not-started" />}
                                                {order.orderProcessSteps[0].status === 3 && <FontAwesomeIcon icon={faCirclePlay} className="fa-xl icon-play" />}
                                                {order.orderProcessSteps[0].status === 4 && <FontAwesomeIcon icon={faCirclePause} className="fa-xl icon-pause" />}
                                                {order.orderProcessSteps[0].status === 5 && <FontAwesomeIcon icon={faCircleCheck} className="fa-xl icon-done" />}
                                            </td>
                                            {getAttributes('isVisibleCollapsed').map((attr, index) => {
                                                let value = getCellValue(order?.orderInfo[attr?.attribute?.id]?.value, attr?.attribute);
                                                return <td onClick={() => expandCollapseRow(order)} key={index}>{value}</td>
                                            })}
                                            <td onClick={() => expandCollapseRow(order)}>{' '}</td>
                                        </tr>
                                        <tr className="collapse multi-collapse" id={`multiCollapse${index}`}>
                                            <td>
                                                {sliceIntoChunks(getAttributes('isVisibleExpanded'), 3).map((attr, ind) => {
                                                    return <Row key={ind}>
                                                        <Col>
                                                            <span>{attr[0]?.attribute?.label}</span>
                                                            <p>{getCellValue(order?.orderInfo[attr[0]?.attribute?.id]?.value, attr[0]?.attribute)}</p>
                                                        </Col>
                                                        <Col>
                                                            <span>{attr[1]?.attribute?.label}</span>
                                                            <p>{getCellValue(order?.orderInfo[attr[1]?.attribute?.id]?.value, attr[1]?.attribute)}</p>
                                                        </Col>
                                                        <Col>
                                                            <span>{attr[2]?.attribute?.label}</span>
                                                            <p>{getCellValue(order?.orderInfo[attr[2]?.attribute?.id]?.value, attr[2]?.attribute)}</p>
                                                        </Col>
                                                    </Row>
                                                })
                                                }
                                            </td>
                                        </tr>
                                    </Fragment>
                                }
                                )}
                        </tbody>
                    </Table>
                        : <div>{t('config-error')}</div>: <div className='text-center'>{t('no-order-found')}</div>}
                </div>
            </div>
        </div>
    </>
}
export default OperatorPage;
