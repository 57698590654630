import React, { useState, useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Container, DropdownButton, Dropdown, FormCheck } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import brandLogo from "../../../assets/images/brandLogo.png";
import { languages } from './constant';
import { AuthContext } from '../../../contexts/AuthProvider';
import "./brand-header.scss";
import { VERSION_NUMBER } from "../../../constant/versionInfo";
import { HELP_MODES } from "../../../utils/constant";

function BrandHeaderAtom({ action }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { auth, helpMode, setHelpMode } = useContext(AuthContext);

    const onLogout = () => {
        navigate('/');
        localStorage.setItem('token', '');
        localStorage.setItem('selectedMenu', '');
        localStorage.setItem('filterObj', JSON.stringify({}));
        localStorage.setItem('stepFilter', JSON.stringify([]));
        localStorage.setItem('sortFilter', JSON.stringify([]));
    };

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language === 'en' ? 'EN' : 'DE');
    const handleSelect = (key) => {
        if (key !== i18n.language) {
            setSelectedLanguage(key === 'en' ? 'EN' : 'DE');
            i18n.changeLanguage(key);
        }
    };

    return (
        <Navbar className="p-0">
            <Container fluid>
                <Navbar.Brand className="p-0">
                    <div className="navbar-brand-logo" role="button" onClick={() => {
                        localStorage.setItem('selectedMenu', 'dashboard');
                        navigate('/');
                    }}>
                        <img
                            src={brandLogo}
                            alt="Logo here"
                        />
                        <Navbar.Text className="version-info">
                            {VERSION_NUMBER}
                        </Navbar.Text>
                    </div>
                </Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                    {
                        action &&
                        <Navbar.Text className="mb-5 d-flex">
                            <p className="text-primary">
                                <FontAwesomeIcon icon={faUser} />
                            </p>
                            <p role="button" className="text-primary px-2" onClick={() => {
                                localStorage.setItem('selectedMenu', 'resetPassword');
                                navigate('/resetPassword');
                            }}>{auth.name}</p>
                            <strong className="font-weight-bold text-primary ml-10" 
                                onClick={onLogout}
                                role="button">
                                {t("logout")}
                            </strong>
                        </Navbar.Text>
                    }
                    <div className="mode-language-container">
                        {action && <div className="help-switch">
                            <strong className="text-primary">{t('help')}:</strong>
                            <FormCheck
                                checked={helpMode === HELP_MODES.ADVANCE}
                                onClick={(e) => {
                                    const value = e.target.checked;
                                    let helpMode = 'basic';
                                    if (value) {
                                        helpMode = HELP_MODES.ADVANCE;
                                    }
                                    setHelpMode(helpMode);
                                    localStorage.setItem('help-mode', helpMode);
                                }}
                                type="switch">
                            </FormCheck>
                            <span className="text-primary">{helpMode === HELP_MODES.BASIC ? t('basic') : t('advance')}</span>
                        </div>}
                        <DropdownButton
                            id="language-selector-btn"
                            title={selectedLanguage || languages[0].value}
                            onSelect={handleSelect}
                            className="language-selector"
                        >
                            {
                                languages.map((language) => (
                                    <Dropdown.Item
                                        key={language.key}
                                        eventKey={language.key}
                                    >
                                        {language.value}
                                    </Dropdown.Item>
                                ))
                            }
                        </DropdownButton>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default BrandHeaderAtom;
