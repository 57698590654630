import React from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import { DropdownAtom, ModalAtom } from "../../../components/atoms/atom";

function OrderTemplateModal(props) {
    const { show, onClose, onSubmit, templateList, selectedTemplate, selectTemplate } = props;
    const { t } = useTranslation();

    const getDropdownField = () => {
        const options = templateList.map((template) => ({
            label: template.templateName,
            value: template.id
        }))
        return <DropdownAtom
            className='form-control'
            value={selectedTemplate?.id}
            options={options}
            onChange={(e) => selectTemplate(e.target.value)}
        />
    }
    
    return (
        <ModalAtom 
            title={t('choose-from-template')}
            show={show} 
            handleClose={onClose}
            handleSubmit={onSubmit}
            saveText={t('select')}
            body={<Container>
                <div className='body-inner'>
                    <span>{t('select-template')}</span><span>
                        {getDropdownField()}
                    </span>
                </div>
            </Container>}
        />
    );
}

export default OrderTemplateModal;
