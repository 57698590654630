import { faEdit, faToggleOn, faToggleOff, faTrash } from '@fortawesome/free-solid-svg-icons';
import TableActionButtonAtom from '../../components/atoms/table-action-button/table-action-button';
import { HELP_MODES } from '../../utils/constant';

const getResourceTableColumns = (t, onResourceEdit, onResourceDelete, onResourceStatusChange, role = '', helpMode = HELP_MODES.BASIC) => {
    const tableColumns = [
        {
            field: 'provided',
            headerName: t('provided'),
            unSortIcon: true,
        },
        {
            field: 'name',
            headerName: t('name'),
            unSortIcon: true,
        },
        {
            field: 'type.name',
            headerName: t('type'),
            unSortIcon: true,
        },
        {
            field: 'location.name',
            headerName: t('location'),
            unSortIcon: true,
        },
        {
            field: 'externalSystemIdentifier',
            headerName: t('external-system-identifier'),
            unSortIcon: true,
        },
        {
            field: 'icon',
            headerName: t('icon'),
            sortable: false,
            cellRenderer: (props) => {
                const icon = props.data?.type?.category?.icon;
                if (!icon) {
                    return;
                }
                return <img src={icon} alt='' style={{height: '40px'}} />;
            },
        },
        
    ];
    if (['SUPER_ADMIN', 'ADMIN'].includes(role)) {
        tableColumns.push({
            headerName: t('action'),
            sortable: false,
            cellRenderer: (props) => {
                const {data, rowIndex} = props;
                const statusChangeTitle = +data.isActive ? 'action-deactivate' : 'action-activate';
                const statusChangeIcon = +data.isActive ? faToggleOn : faToggleOff;
                return <div className="d-flex ag-action-cell">   
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faEdit}
                        onClickAction={() => onResourceEdit(data, rowIndex)}
                        tooltipTitle='action-edit'
                    />
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faTrash}
                        className="mx-2"
                        onClickAction={() => onResourceDelete(data, rowIndex)}
                        tooltipTitle='action-delete'
                    />
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={statusChangeIcon}
                        onClickAction={() => onResourceStatusChange(data, rowIndex)}
                        tooltipTitle={statusChangeTitle}
                    />
                </div>
            }
        });
    }
    return tableColumns;
};

export {
    getResourceTableColumns,
}