const getSearchAddFilter = (t, config = { addTextKey: '', addIcon: '' }, additionalButtons = []) => {
    return {
        left: [
            { 
                type: 'search', 
                key: 'search', 
                label: t('search-for'), 
                value: '' 
            }
        ],
        right: [
            { 
                type: 'button', 
                key: 'add', 
                label: <span className="text-uppercase m-0">
                    {t(config?.addTextKey || "add")}
                </span>
            },
            ...additionalButtons,
        ]
    }
}

export {
    getSearchAddFilter,
}