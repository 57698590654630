import React from "react";
import { Link } from "react-router-dom";

function MenuItem(props) {
    const { menuItems, selectedMenuItem } = props;
    return <>
        {menuItems.map((menuItem) => (
            <div className={`${menuItem.key} col-3`} key={menuItem.key}>
                <div className="img"></div>
                <div className="list-container">
                    <p>{menuItem.label}</p>
                    <ul>
                        {menuItem.menus.map((menu) => (
                            <li key={menu.key}>
                                <Link to={menu.url} key={menu.key} className={menu.key === selectedMenuItem ? 'active-tab' : ''}>{menu.label}</Link>
                            </li>
                        ))}
                    </ul>
                    
                </div>
            </div>
        ))}
    </>
}

export default MenuItem;
