const getProcessStepResourceTableColumns = (t, defaultSelectedRow) => {
    return [
        {
            field: 'name',
            headerName: t('name'),
            headerCheckboxSelection: true,
            checkboxSelection: true,
            cellRenderer: (props) => {
                const {id} = props.data;
                if (defaultSelectedRow.includes(id)) {
                    props.node.setSelected(true, false, true);
                }
                return props.value;
            },
        },
        {
            field: 'type.category.name',
            headerName: t('category'),
        },
        {
            field: 'type.manufacturer.name',
            headerName: t('manufacturer'),
        },
        {
            field: 'type.name',
            headerName: t('type'),
        },
        {
            field: 'location.name',
            headerName: t('location'),
        },
        {
            field: 'externalSystemIdentifier',
            headerName: t('external-system-identifier'),
        },
    ];
}

export {
    getProcessStepResourceTableColumns
}