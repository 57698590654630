import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import { ModalAtom, AgGridAtom } from "../../../components/atoms/atom";
import { getProcessStepTableColumns } from './constant';
import clone from 'rfdc/default';
import { AuthContext } from "../../../contexts/AuthProvider";
import { HELP_MODES } from "../../../utils/constant";

function AddNewOrderProcessStepModal(props) {
    const { show, onClose, onSubmit, newOrderData, processSteps = [] } = props;
    const { t } = useTranslation();
    const { helpMode } = useContext(AuthContext);
    const [selectedRow, setSelectedRow] = useState(clone(newOrderData?.processSteps));

    const onSaveProcessStep = () => {
        if (selectedRow.every((selRow) => selRow.resourceId)) {
            onSubmit(selectedRow);
        }
    }

    const onProcessStepResourceUpdate = (updatedSteps) => {
        setSelectedRow(updatedSteps);
    }

    const processStepColumns = getProcessStepTableColumns(t, selectedRow, onProcessStepResourceUpdate);

    const onRowSelection = (selectedRows) => {
        const selectedProcessSteps = selectedRows.map((row) => {
            const exists = selectedRow.find((obj) => obj.processStepId === row.id);
            if (exists) {
                return exists;
            } else {
                return {
                    processStepId: row.id,
                    resourceId: null,
                    processStepPosition: row?.position,
                };
            }
        });
        setSelectedRow(selectedProcessSteps);
    }

    return (
        <ModalAtom 
            title={t('add-process-step')}
            titleHelpText={helpMode === HELP_MODES.ADVANCE ? t('help-advance.order-detail-process-steps'): ''}
            show={show} 
            handleClose={onClose}
            handleSubmit={onSaveProcessStep}
            customClassName='process-step-modal'
            body={ <Container>
                <AgGridAtom 
                    columnDefs={processStepColumns}
                    rowData={processSteps || []}
                    rowSelection={'multiple'}
                    onRowSelection={onRowSelection}
                />
            </Container>}
        />
    );
}

export default AddNewOrderProcessStepModal;
