import React from "react";
import { TextFieldAtom, ButtonAtom, DropdownAtom, DatePickerAtom } from '../../atoms/atom';
import "./filter-bar.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';

function FilterBar(props) {

    const { leftItems, rightItems, onFilterAction } = props;

    const getSearchFilter = (index, itemInfo) => {
        return <TextFieldAtom 
            type="search"
            placeholder={itemInfo?.label}
            value={itemInfo?.value}
            onChange={(e) => onFilterAction(index, itemInfo?.key, { value: e.target.value })}
        />
    }

    const getButton = (index, itemInfo) => {
        return  <ButtonAtom 
            className={itemInfo.className || "filter-bar-btn"}
            disabled={itemInfo.disabled || false}
            onClick={() => onFilterAction(index, itemInfo?.key)}>
            {itemInfo.label}
        </ButtonAtom>
    }

    const getDropDown = (index, itemInfo) =>{
        return <DropdownAtom 
            placeholder={itemInfo.label}
            value={itemInfo?.value}
            options={itemInfo?.options}
            sortOptions={itemInfo.sortOptions}
            hideNoOptionSelect={itemInfo.hideNoOptionSelect}
            onChange={(e) => onFilterAction(index, itemInfo?.key, { value: e.target.value })}
        />
    }

    const getDatePicker = (index, itemInfo) => {
        return <DatePickerAtom 
            placeholder={itemInfo.label}
            value={itemInfo.value}
            onChange={(e) => onFilterAction(index, itemInfo?.key, { value: e })}
        />
    }

    const getBackButton = (index, itemInfo) => {
        return <FontAwesomeIcon icon={faCircleChevronLeft} onClick={() => onFilterAction(index, itemInfo?.key)} className="fa-xl" />
    }

    return (
        <div className="d-flex mt-3 filterbar light">
            {[leftItems || [], rightItems || []].map((items, index) => {
                return <div className="d-flex filterbar-container" key={index}>
                    {items.map((item, idx) => {
                        return <div className= "filter-item" style={ index ? { marginLeft: '5px' } : { marginRight: '5px' }} key ={idx}>
                            {item.type === 'backButton' && getBackButton(index, item)}
                            {item.type === 'search' && getSearchFilter(index, item)}
                            {item.type === 'button' && getButton(index, item)}
                            {item.type === 'dropdown' && getDropDown(index, item)}
                            {item.type === 'datepicker' && getDatePicker(index, item)}
                        </div>
                    })}
                </div>
            })}
        </div>
    );
}

export default FilterBar;
