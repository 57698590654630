import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import { DropdownAtom, ModalAtom } from "../../../components/atoms/atom";
import { priorityOptions, getSalesOrderNumbersFromSelectedOrders } from "../constant";

function SelectPriorityModal(props) {
    const { show, onClose, onSubmit, selectedOrders } = props;
    const { t } = useTranslation();
    const defaultPriority = selectedOrders?.length === 1 ? selectedOrders[0].priority : '';
    const [priorityObj, setPriorityObj] = useState({
        value: defaultPriority,
        error: '',
    });

    const submitPriority = () => {
        if(!priorityObj.value) {
            setPriorityObj({
                ...priorityObj, error: 'error-priority-required'
            })
            return;
        }
        onSubmit(priorityObj.value);
    }
    
    return (
        <ModalAtom 
            title={t('change-priority-for-order', { commaSeparatedSalesOrderNumbers: getSalesOrderNumbersFromSelectedOrders(selectedOrders) })}
            show={show} 
            handleClose={onClose}
            handleSubmit={submitPriority}
            saveText={t('save')}
            body={<Container>
                <div className='body-inner'>
                    <span>{t('action-select-priority')}</span>
                    <span>
                        <DropdownAtom
                            className='form-control'
                            value={priorityObj.value}
                            error={priorityObj.error}
                            options={priorityOptions}
                            onChange={(e) => setPriorityObj({
                                ...priorityObj,
                                value: e.target.value,
                                error: e.target.value? '': 'error-priority-required'
                            } )}
                            sortOptions={false}
                            shouldTranslate={true}
                        />
                    </span>
                </div>
            </Container>}
        />
    );
}

export default SelectPriorityModal;
