import { faCircleInfo, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import TableActionButtonAtom from '../../components/atoms/table-action-button/table-action-button';
import { HELP_MODES } from '../../utils/constant';

const getImportExportTableColumns = (t, onDownload, onUpload, helpMode) => {
    return [
        {
            field: 'name',
            headerName: t('name'),
            unSortIcon: true,
        },
        {
            field: 'externalSystemIdentifier',
            headerName: t('external-system-identifier'),
            unSortIcon: true,
        },
        {
            headerName: t('action'),
            sortable: false,
            autoHeight: true,
            maxWidth: 200,
            cellRenderer: (props) => {
                const {data} = props;
                return <div className="d-flex ag-action-cell flex-wrap">
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faDownload}
                        onClickAction={() => onDownload(data)}
                        tooltipTitle='action-download'
                    />
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faUpload}
                        className="mx-2"
                        onClickAction={() => onUpload(data)}
                        tooltipTitle='action-upload'
                    />
                    {helpMode === HELP_MODES.ADVANCE && <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faCircleInfo}
                        className="mx-2"
                        onClickAction={() => {}}
                        tooltipTitle='import-export-help'
                        style={{color: 'grey'}}
                    />}
                </div>
            }
        }
    ];
}

export {
    getImportExportTableColumns
}