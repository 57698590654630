import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomCheckbox from "./custom-renderer/checkbox";
import { extractValues, lookupValue } from "../../../../../utils/method";
  
const getProcessStepAddAttributeTableColumns = (t, attributeOptions = {}, onDeleteAttribute = () => {}) => {
    const attributes = extractValues(attributeOptions);
    return [
        {
            field: 'position',
            headerName: t('position'),
            headerTooltip: t('position'),
            unSortIcon: true,
            minWidth: 120,
            rowDrag: true,
        },
        {
            field: 'attributeId',
            headerName: `${t('attribute')}`,
            headerTooltip: `${t('attribute')}`,
            unSortIcon: true,
            editable: true,
            cellEditor: 'agSelectCellEditor',
            singleClickEdit : true,
            stopEditingWhenCellsLoseFocus: true,
            filter: 'agSetColumnFilter',
            minWidth: 250,
            cellEditorParams: {
                values: attributes,
            },
            filterParams: {
                valueFormatter: (params) => {
                    return lookupValue(attributeOptions, params.value);
                },
            },
            valueFormatter: (params) => {
                return lookupValue(attributeOptions, params.value);
            },
        },
        {
            field: 'isMandatory',
            headerName: t('mandatory-attribute'),
            headerTooltip: t('mandatory-attribute'),
            minWidth: 150,
            sortable: false,
            headerClass: 'header-align-center',
            cellRenderer: CustomCheckbox
        },
        {
            headerName: t('visibility-mobile'),
            headerTooltip: t('visibility-mobile'),
            children: [
                {
                    field: 'isVisibleCollapsed',
                    headerName: t('collapsed'),
                    maxWidth: 70,
                    sortable: false,
                    headerClass: 'header-align-center',
                    cellRenderer: CustomCheckbox
                },
                {
                    field: 'isVisibleExpanded',
                    headerName: t('expanded'),
                    maxWidth: 70,
                    sortable: false,
                    headerClass: 'header-align-center',
                    cellRenderer: CustomCheckbox
                },
                {
                    field: 'isVisibleDetail',
                    headerName: t('details'),
                    maxWidth: 70,
                    sortable: false,
                    headerClass: 'header-align-center',
                    cellRenderer: CustomCheckbox
                }
            ]
        },
        {
            headerName: t('action'),
            headerTooltip: t('action'),
            sortable: false,
            cellRenderer: (props) => {
                const {data, rowIndex} = props;
                return <div className="d-flex text-uppercase ag-action-cell">
                    <FontAwesomeIcon icon={faTrash} onClick={() => onDeleteAttribute(data, rowIndex)} />
                </div>
            },
        }
    ];
}

export {
    getProcessStepAddAttributeTableColumns
}