import { Fragment, useState, useEffect, useContext, useRef } from 'react';
import clone from 'rfdc/default';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { faCircleNotch, faCirclePlay, faCircleInfo, faCircle, faCirclePause, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import "./operator-detail.scss"
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row, Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { TextFieldAtom, DatePickerAtom, DropdownAtom, TextAreaAtom, ButtonAtom, CheckboxAtom, ModalAtom } from "../../components/atoms/atom";
import { setting } from '../../store/services';
import { getCellValue, getFormattedDate, showError } from '../../utils/method';
import { getStatusIcon } from '../order/utils/status';
import { AuthContext } from '../../contexts/AuthProvider';
import ListGroup from 'react-bootstrap/ListGroup';
import { io } from "socket.io-client";
import SOCKET_RESPONSE from '../../constant/socket';
import { useStateWithRef } from '../../utils/useStateWithRef';
import { SOCKET_URL } from '../../constant/url';

function OperatorDetailPage() {
    const location = useLocation();
    const { orderId, attributes, resourceId, processStepId } = location.state;
    const [orderProcessStepInfo, setOrderProcessStepInfo] = useState(null);
    const { t } = useTranslation();
    const [infoValue, setInfoValue] = useState('');
    const [updatedInfo, setUpdatedInfo] = useState([]);
    const navigate = useNavigate();
    const { auth, setAuth } = useContext(AuthContext);
    const [showInfoBox, setShowInfoBox] = useState(false);
    const [icon, setIcon] = useState(null);
    const [title, setTitle] = useState(null);
    const socketRef = useRef();

    const [orderDetail, setOrderDetail, orderDetailRef] = useStateWithRef(null);

    const orderDetailsUpdatedHandler = (response, message) => {
        if (response) {
            const localOrderDetails = clone(orderDetailRef.current);
            localOrderDetails.isLocked = response?.isLocked;
            localOrderDetails.isUnderClarification = response?.isUnderClarification;
            setOrderDetail(localOrderDetails);
            // showInfo(t, 'data-synced');
            alert(t(message.message, { userName: message.user }));
        }
    };

    const orderNotAvailableHandler = (response) => {
        alert(t(response.message, { userName: response.user }));
        navigate('/operator');
    };

    const orderProcessStepUpdatedHandler = (response, message) => {
        if (response) {
            const localOrderDetails = clone(orderDetailRef.current);
            let orderProcessSteps = localOrderDetails.orderProcessSteps;
            orderProcessSteps[0].status = response?.orderProcessSteps[0].status;
            setOrderDetail({ ...localOrderDetails, orderProcessSteps: orderProcessSteps });
            // showInfo(t, 'data-synced');
            alert(t(message.message, { userName: message.user }))
        }
    };

    useEffect(() => {
        socketRef.current = io(SOCKET_URL, {transports: ['websocket'], upgrade: false, autoConnect: true});

        socketRef.current.on(SOCKET_RESPONSE.CONNECT, () => {
            // console.log(SOCKET_RESPONSE.CONNECT, socketRef.current.id);
            socketRef.current.emit(SOCKET_RESPONSE.JOIN_ROOM, '', `o/${orderId}`, (roomIdLeft, roomIdJoined) => {
                console.log('roomIdLeft', roomIdLeft);
                console.log('roomIdJoined', roomIdJoined);
            });
        });

        socketRef.current.on(SOCKET_RESPONSE.CONNECT_ERROR, () => {
            setTimeout(() => socketRef.current.connect(), 5000);
        });

        socketRef.current.on(SOCKET_RESPONSE.DISCONNECT, () => {
            // console.log(SOCKET_RESPONSE.DISCONNECT);
        });

        socketRef.current.on(SOCKET_RESPONSE.OPERATOR_ORDER_DATA_UPDATED, orderDetailsUpdatedHandler);
        socketRef.current.on(SOCKET_RESPONSE.OPERATOR_ORDER_NOT_AVAILABLE, orderNotAvailableHandler);
        socketRef.current.on(SOCKET_RESPONSE.OPERATOR_ORDER_PROCESS_STEP_UPDATED, orderProcessStepUpdatedHandler);
        return () => {
            socketRef.current.off(SOCKET_RESPONSE.OPERATOR_ORDER_DATA_UPDATED, orderDetailsUpdatedHandler);
            socketRef.current.off(SOCKET_RESPONSE.OPERATOR_ORDER_NOT_AVAILABLE, orderNotAvailableHandler);
            socketRef.current.off(SOCKET_RESPONSE.OPERATOR_ORDER_PROCESS_STEP_UPDATED, orderProcessStepUpdatedHandler);
            socketRef.current.close();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchOrderDetails = async () => {
        try {
            let result = await setting.getOpenOrderDetails(orderId, resourceId);
            const orderDetailObj = result?.data;
            setOrderDetail(orderDetailObj);
        } catch(error){
            showError(t, error);
        }
    }

    useEffect(() => {
        fetchOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId])

    const getAttributes = (viewType) => {
        return attributes?.filter((step) => {
            return step[viewType];
        })
    }

    const isVisible = (fieldName) => {
        return orderDetail?.orderInfo[fieldName]?.isChangeableWorker;
    };

    const initializeDynamicValue = (oInfo = orderDetail) => {
        const initialArray = [];
        attributes.forEach((attribute) => {
            if (attribute.isVisibleDetail && isVisible(attribute.attribute.id)) {
                const val = getCellValue(oInfo?.orderInfo[attribute.attribute.id]?.value, attribute.attribute);
                const valLocal = getCellValue(oInfo?.orderInfo[attribute.attribute.id]?.valueLocal, attribute.attribute);
                if (['DATE', 'DATETIME'].includes(attribute?.attribute.attributeFormat?.name)) {
                    initialArray.push({
                        isUpdated: false,
                        id: attribute.attribute.id,
                        value: new Date(val),
                        valueLocal: new Date(valLocal),
                        valueLocalDB: new Date(valLocal),
                        isMandatory: attribute?.isMandatory,
                        attributeFormat: attribute?.attribute?.attributeFormat?.name,
                    });

                } else if (attribute?.attribute.attributeFormat?.name === 'CHECKBOX') {
                    initialArray.push({
                        isUpdated: false,
                        id: attribute?.attribute?.id,
                        value: !!+oInfo?.orderInfo[attribute?.attribute?.id]?.value,
                        valueLocal: !!+oInfo?.orderInfo[attribute?.attribute?.id]?.valueLocal,
                        valueLocalDB: !!+oInfo?.orderInfo[attribute?.attribute?.id]?.valueLocal,
                        isMandatory: attribute?.isMandatory,
                        attributeFormat: attribute?.attribute?.attributeFormat?.name
                    });
                } else {
                    initialArray.push({
                        isUpdated: false,
                        id: attribute?.attribute?.id,
                        value: val,
                        valueLocal: valLocal,
                        valueLocalDB: valLocal,
                        isMandatory: attribute?.isMandatory,
                        attributeFormat: attribute?.attribute?.attributeFormat?.name
                    });
                }
            }
        });
        setUpdatedInfo(initialArray);
    };

    useEffect(() => {
        if (orderDetail) {
            const orderProcessStepInfo = orderDetail?.orderProcessSteps[0].orderProcessStepInfo?.length
                ? orderDetail?.orderProcessSteps[0].orderProcessStepInfo[0]
                : null;

            const statusInfo = getStatusIcon(orderDetail, t);
            setIcon(statusInfo.icon);
            setTitle(statusInfo.title);
            setOrderProcessStepInfo(orderProcessStepInfo);
            setInfoValue(orderProcessStepInfo?.comment || '');
            initializeDynamicValue();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderDetail]);

    const sliceIntoChunks = (arr, chunkSize) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    const getValue = (fieldId) => {
        const selectedField = updatedInfo.find((row) => {
            return row?.id === fieldId;
        });
        return selectedField?.valueLocal;
    }

    const updateValue = (fieldId, fieldValue) => {
        const fieldIndex = updatedInfo.findIndex((row) => {
            return row?.id === fieldId;
        });
        const localUpdatedInfo = [...updatedInfo];
        localUpdatedInfo[fieldIndex] = { ...localUpdatedInfo[fieldIndex], valueLocal: fieldValue, isUpdated: true };
        setUpdatedInfo(localUpdatedInfo);
    }

    const getEditableField = (attr) => {
        const value = getValue(attr?.attribute?.id);
        const additionalClass = !value ? 'error' : '';
        if (attr?.attribute?.attributeType?.name === 'INPUT') {
            if (attr?.attribute?.attributeFormat?.name === 'DATE') {
                return <div className='body-inner'>
                    <DatePickerAtom
                        className={`form-control ${additionalClass}`}
                        value={value}
                        onChange={(e) => {
                            const value = dayjs(e).format('YYYY-MM-DDTHH:mm:ssZ');
                            updateValue(attr?.attribute?.id, value);
                        }} />
                </div>


            }
            if (attr?.attribute?.attributeFormat?.name === 'TEXT' || attr?.attribute?.attributeFormat?.name === 'NUMBER') {
                return <div className='body-inner'>
                    <TextFieldAtom
                        className={`form-control ${additionalClass}`}
                        value={value}
                        type={attr?.attribute?.attributeFormat?.name === 'NUMBER' ? 'number' : 'text'}
                        onChange={(e) => {
                            updateValue(attr?.attribute?.id, e.target.value);
                        }} />
                </div>
            }
            if (attr?.attribute?.attributeFormat?.name === "TEXTAREA") {
                return <div className='body-inner'>
                    <TextAreaAtom
                        className={`form-control ${additionalClass}`}
                        value={value}
                        onChange={(e) => {
                            updateValue(attr?.attribute?.id, e.target.value);
                        }} />
                </div>
            }
            if (attr?.attribute?.attributeFormat?.name === 'DATETIME') {
                return <div className='body-inner'>
                    <DatePickerAtom
                        className={`form-control ${additionalClass}`}
                        todayButton={t('today')}
                        value={value}
                        onChange={(e) => {
                            const value = dayjs(e).format('YYYY-MM-DDTHH:mm:ssZ');
                            updateValue(attr?.attribute?.id, value);
                        }}
                        dateFormat="dd.MM.yyyy h:mm aa"
                        timeInputLabel={`${t('time')}:`}
                        showTimeInput
                    />
                </div>
            }
            if (attr?.attribute?.attributeFormat?.name === "CHECKBOX") {
                return <div className='body-inner'>
                    <CheckboxAtom
                        checked={value}
                        onChange={(evt) => updateValue(attr?.attribute?.id, evt.target.checked)} />
                </div>
            }
        }
        if (attr?.attribute?.attributeType?.name === 'SELECT') {
            const options = attr?.attribute?.attributeOptions?.split(',').map((opt) => {
                return {
                    label: opt?.trim(),
                    value: opt?.trim()
                }
            })
            return <div className='body-inner'>
                <DropdownAtom
                    className={`form-control ${additionalClass}`}
                    value={value}
                    options={options}
                    onChange={(e) => updateValue(attr?.attribute?.id, e.target.value)}
                    hideNoOptionSelect
                />
            </div>
        }
    }

    const saveOrder = async () => {
        try {
            let result = await setting.updateOrder({
                orders: [orderDetail.id],
                mode: 'WORKER',
                orderInfo: updatedInfo.map((info) => {
                    return {
                        id: info?.id,
                        valueLocal: info?.valueLocal
                    };
                }) || [],
                orderProcessStepInfo: [{
                    id: orderProcessStepInfo?.id || '',
                    comment: infoValue,
                    orderProcessStepId: orderDetail?.orderProcessSteps[0].id,
                }],
                resourceId,
            });
            result = result.data;
            setOrderDetail({...result});
            initializeDynamicValue(result);
            
        } catch (error) {
            showError(t, error);
        }
    }

    const updateOrderStatus = async (ordStatus) => {
        try {
            await setting.updateProcessStepStatus(orderDetail?.id, {
                orderProcessStepId: orderDetail?.orderProcessSteps[0]?.id,
                status: ordStatus,
                mode: 'WORKER',
                resourceId,
                processStepId,
                socketId: socketRef.current.id,
            });
            if (['IN_PROGRESS'].includes(ordStatus)) {
                setAuth({...auth, isOperatorNavigationDisabled: true});
            } else {
                setAuth({...auth, isOperatorNavigationDisabled: false});
            }
        } catch (error) {
            showError(t, error);
        }
    }

    const isFinishedDisabled = () => {
        return updatedInfo.filter((inf) => inf.isMandatory).findIndex((info) => {
            if (info.attributeFormat === 'CHECKBOX') {
                return info.valueLocalDB === false;
            } 
            return info.valueLocalDB === '';
        }) !== -1;
    }

    const getListItem = (heading, val) => {
        return <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
            style={{ paddingInline: 4, background: '#dee1e6', border: '0px 0px 1px 0px' }}
        >
            <div className="me-auto">
                <div className="fw-bold text-uppercase">{t(heading)}</div>
            </div>
            <span>{val}</span>
        </ListGroup.Item>
    };

    return <>
        <div className="operator-detail">
            <div className="operator-detail-body">
                <div className="body-top">
                    <Table hover>
                        <thead>
                            <tr>
                                <th><FontAwesomeIcon icon={faCircleNotch} className="fa-xl" /></th>
                                <th><FontAwesomeIcon icon={faCircleNotch} className="fa-xl" /></th>
                                {getAttributes('isVisibleCollapsed').map((attr) => {
                                    return <th>{attr?.attribute?.label}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <Fragment>
                                <tr
                                    data-bs-toggle="collapse"
                                    aria-controls={`multiCollapse`}
                                >
                                    <td>
                                        <img src={icon} alt='' style={{ height: '18px' }} title={title} />
                                    </td>
                                    <td>
                                        {orderDetail?.orderProcessSteps[0].status === 2 && <FontAwesomeIcon icon={faCircle} className="fa-xl icon-not-started" />}
                                        {orderDetail?.orderProcessSteps[0].status === 3 && <FontAwesomeIcon icon={faCirclePlay} className="fa-xl icon-play" />}
                                        {orderDetail?.orderProcessSteps[0].status === 4 && <FontAwesomeIcon icon={faCirclePause} className="fa-xl icon-pause" />}
                                        {orderDetail?.orderProcessSteps[0].status === 5 && <FontAwesomeIcon icon={faCircleCheck} className="fa-xl icon-done" />}
                                    </td>
                                    {getAttributes('isVisibleCollapsed').map((attr, index) => {
                                        let value = getCellValue(orderDetail?.orderInfo[attr?.attribute?.id]?.value, attr?.attribute);
                                        return <td>{value || ''}</td>
                                    })}
                                </tr>
                                <tr className="collapse multi-collapse show" id={`multiCollapse`}>
                                    <td>
                                        {sliceIntoChunks(getAttributes('isVisibleExpanded'), 3).map((attr, ind) => {
                                            return <Row>
                                                <Col>
                                                    <span>{attr[0]?.attribute?.label}</span>
                                                    <p>{getCellValue(orderDetail?.orderInfo[attr[0]?.attribute?.id]?.value, attr[0]?.attribute)}</p>
                                                </Col>
                                                <Col>
                                                    <span>{attr[1]?.attribute?.label}</span>
                                                    <p>{getCellValue(orderDetail?.orderInfo[attr[1]?.attribute?.id]?.value, attr[1]?.attribute)}</p>
                                                </Col>
                                                <Col>
                                                    <span>{attr[2]?.attribute?.label}</span>
                                                    <p>{getCellValue(orderDetail?.orderInfo[attr[2]?.attribute?.id]?.value, attr[2]?.attribute)}</p>
                                                </Col>
                                            </Row>
                                        })
                                        }
                                    </td>
                                </tr>
                            </Fragment>
                        </tbody>
                    </Table>
                </div>

                <div className="user-detail">
                    <div className='user-row'>
                        <div></div>
                        {/* <div>{t('soll')}</div> */}
                        <div>{t('ist')}</div>
                    </div>
                    {getAttributes('isVisibleDetail').map((attri) => {
                        const value = getCellValue(orderDetail?.orderInfo[attri?.attribute?.id]?.valueLocal, attri?.attribute);
                        const additionalClass = attri?.attribute?.attributeFormat?.name === 'TEXTAREA' ? 'height-auto' : '';
                        return <div className={`user-row ${additionalClass}`}>
                            <div>{attri?.attribute?.label}</div>
                            {/* <div>{getSollValue(orderDetail, attri)}</div> */}
                            {attri?.attribute?.isChangeableWorker ? <div>{getEditableField(attri)}</div> : <div>{value}</div>}
                        </div>
                    })}
                    <div className='user-row'>
                        <div>{t('info')}</div>
                    </div>
                    <div className='user-row textarea'>
                        <TextAreaAtom
                            className={`form-control`}
                            value={infoValue}
                            onChange={(e) => {
                                setInfoValue(e.target.value);
                            }} />
                        <div className='user-submit-wrapper'>
                            <ButtonAtom 
                                disabled={!orderDetail?.orderProcessStepInfo?.length} 
                                onClick={() => setShowInfoBox(true)}>
                                <FontAwesomeIcon icon={faCircleInfo}  />
                            </ButtonAtom>
                                
                            <ButtonAtom
                                onClick={() => saveOrder()}
                                disabled={orderDetail?.orderProcessSteps[0].status === 2}
                            >
                                {t('SAVE')}
                            </ButtonAtom>

                        </div>

                    </div>
                </div>
            </div>
            <div className="operator-detail-footer">
                {orderDetail?.orderProcessSteps[0].status === 2 && 
                        <>
                            <ButtonAtom disabled={orderDetail?.isLocked} onClick={() => updateOrderStatus('IN_PROGRESS')}>
                                {t('start')}
                            </ButtonAtom>
                            <ButtonAtom onClick={() => navigate('/operator')}>
                                {t('back')}
                            </ButtonAtom>
                        </>
                }
                {orderDetail?.orderProcessSteps[0].status === 3 && <>
                    <ButtonAtom className="btn btn-secondary" onClick={() => updateOrderStatus('PAUSE')}>
                        {t('pause')}
                    </ButtonAtom>
                    <ButtonAtom className="btn btn-secondary" onClick={() => updateOrderStatus('AVAILABLE')}>
                        {t('ABORT')}
                    </ButtonAtom>
                    <ButtonAtom disabled={isFinishedDisabled()} onClick={() => updateOrderStatus('DONE')}>
                        {t('FINISHED')}
                    </ButtonAtom>
                </>}
                {orderDetail?.orderProcessSteps[0].status === 4 && <>
                    <ButtonAtom disabled={orderDetail?.isLocked} className="btn btn-secondary" onClick={() => updateOrderStatus('IN_PROGRESS')}>
                        {t('CONTINUE')}
                    </ButtonAtom>
                    <ButtonAtom onClick={() => navigate('/operator')}>
                        {t('back')}
                    </ButtonAtom>
                </>}
                {orderDetail?.orderProcessSteps[0].status === 5 && <>
                    <ButtonAtom onClick={() => navigate('/operator')}>
                        {t('back')}
                    </ButtonAtom>
                </>}
            </div>
            {showInfoBox && <ModalAtom 
                title={t('infobox')}
                show
                hideCloseButton
                handleSubmit={() => {
                    setShowInfoBox(false);
                }}
                saveText="CLOSE"
                body={orderDetail?.orderProcessStepInfo?.map((stepInfo) => {
                    return <div className='mb-4' style={{ fontSize: 11 }}>
                        {getListItem('date', getFormattedDate(stepInfo.createdAt, true))}
                        {getListItem('resource', stepInfo?.resource?.name)}
                        {getListItem('user', `${stepInfo?.user?.firstName} ${stepInfo?.user?.lastName}`)}
                        {getListItem('information', stepInfo.comment)}
                    </div>
                })}
            />
            }
        </div>
    </>
}
export default OperatorDetailPage;