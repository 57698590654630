import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TextFieldAtom, ModalAtom, DropdownAtom, CheckboxAtom } from "../../../../../../components/atoms/atom";
import { EXTERNAL_SYSTEM_IDENTIFIER_REGEX } from "../../../../../../utils/constant";

function AddAttributeModal(props) {
    const { show, onClose, onSubmit, mode, subAttributeInfo = {}, newAttributePosition = 1, attributeTypeList = [] } = props;
    const { t } = useTranslation();
    const [position, setPosition] = useState(
        (subAttributeInfo?.position === null || subAttributeInfo?.position === undefined) ? newAttributePosition : subAttributeInfo?.position
    );
    const [description, setDescription] = useState(subAttributeInfo?.description || '');
    const [label, setLabel] = useState(subAttributeInfo?.label || '');
    const [externalSystemIdentifier, setExternalSystemIdentifier] = useState(subAttributeInfo?.externalSystemIdentifier || '');
    const [isChangeableAV, setIsChangeableAV] = useState(subAttributeInfo?.isChangeableAV || false);
    const [isChangeableWorker, setIsChangeableWorker] = useState(subAttributeInfo?.isChangeableWorker || false);

    const [isRequired, setIsRequired] = useState(subAttributeInfo?.isRequired || false);
    const [attributeType, setAttributeType] = useState(subAttributeInfo?.attributeType?.id || '');
    const [selectedAttributeTypeObj, setSelectedAttributeTypeObj] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [attributeTypesOptions, setAttributeTypesOptions] = useState(attributeTypeList.map((attType) => { 
        return {
            label: attType.name,
            value: attType.id,
        }
    }));
    const [attributeFormat, setAttributeFormat] = useState(subAttributeInfo?.attributeFormat?.id || '');
    const [attributeOptions, setAttributeOptions] = useState(subAttributeInfo?.attributeOptions || '');
    const [isVisibleSticky, setIsVisibleSticky] = useState(subAttributeInfo?.isVisibleSticky);
    const [isVisibleDesktop, setIsVisibleDesktop] = useState(subAttributeInfo?.isVisibleDesktop);
    const [isVisibleHistory, setIsVisibleHistory] = useState(subAttributeInfo?.isVisibleHistory);
    const [isEditable] = useState(
        subAttributeInfo?.isEditable ? true :
            (subAttributeInfo?.isEditable === null || subAttributeInfo?.isEditable === undefined) ? true : false
    );
    const isDisabled = isEditable ? '' : 'disabled';

    const [errorObj, setErrorObj] = useState({
        position: '',
        description: '',
        label: '',
        attributeType: '',
        externalSystemIdentifier: '',
        attributeFormat: '',
        attributeOptions: '',
    });

    useEffect(() => {
        const attrTypeId = subAttributeInfo?.attributeType?.id;
        if (attrTypeId) {
            const attrType = attributeTypeList.find(attrType => attrType.id === attrTypeId);
            setSelectedAttributeTypeObj(attrType);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validatePosition = (positionValue) => {
        if (positionValue === 0) {
            return 'error-attribute-position-validation';
        }
        if (!positionValue) {
            return 'error-process-step-position-required';
        }
        if (positionValue > newAttributePosition) {
            return 'error-attribute-position-greater-than-error';
        }
        return '';
    };

    const onAddSubAttribute = () => {
        let localErrorObj = {};
        const positionValidationError = validatePosition(position);
        if (positionValidationError) {
            localErrorObj.position = positionValidationError;
        }
        if (!description) { 
            localErrorObj.description = 'error-sub-attribute-description-required';
        }
        if (!label) {
            localErrorObj.label = 'error-sub-attribute-label-required';
        }
        if (!attributeType) {
            localErrorObj.attributeType = 'error-attribute-type-required';
        }
        // if (!externalSystemIdentifier) {
        //     localErrorObj.externalSystemIdentifier = 'error-external-system-identifier-required';
        // }
        if (selectedAttributeTypeObj?.name === 'INPUT' && !attributeFormat) {
            localErrorObj.attributeFormat = 'error-attribute-format-required';
        }
        if (selectedAttributeTypeObj?.name === 'SELECT' && !attributeOptions) {
            localErrorObj.attributeOptions = 'error-attribute-options-required';
        }
        setErrorObj({...localErrorObj});
        const hasError = Object.keys(localErrorObj).length ;
        if (!hasError){
            onSubmit({
                ...subAttributeInfo,
                position,
                description,
                label,
                attributeType,
                isRequired,
                isChangeableAV,
                isChangeableWorker,
                externalSystemIdentifier,
                attributeFormat,
                attributeOptions,
                isVisibleSticky,
                isVisibleDesktop,
                isVisibleHistory,
            })
        }     
    }

    const onChangeAttributeType = (e) => {
        const value = e.target.value;
        setAttributeType(value);
        setErrorObj({
            ...errorObj,
            attributeType: value ? '' : 'error-attribute-type-required',
            attributeFormat: '',
            attributeOptions: '',
        });

        const attrType = attributeTypeList.find(attrType => attrType.id === value);
        setSelectedAttributeTypeObj(attrType);
        setAttributeFormat('');
        setAttributeOptions('');
    };

    const getAttributeFieldType = () => {
        if (selectedAttributeTypeObj?.name === 'INPUT') {
            // setAttributeTypeName(attrType?.name);
            const attributeFormats = selectedAttributeTypeObj?.attributeFormat || [];
            const attributeFormatOptions = attributeFormats.map((attFormat) => { 
                return {
                    label: attFormat.name,
                    value: attFormat.id,
                }
            });
            return <DropdownAtom 
                placeholder={t('select-attribute-format')}
                required
                disabled={isDisabled}
                value={attributeFormat}
                options={attributeFormatOptions}
                error={errorObj.attributeFormat}
                onChange={(e) => {
                    setAttributeFormat(e.target.value);
                    setErrorObj({ ...errorObj, attributeFormat: e.target.value ? '' : 'error-attribute-format-required' })
                }}                       
            />
        } else if (selectedAttributeTypeObj?.name === 'SELECT') {
            // setAttributeTypeName(attrType?.name);
            return <TextFieldAtom 
                type="text"
                required
                disabled={isDisabled}
                placeholder={t('select-attribute-options')}
                value={attributeOptions}
                error={errorObj.attributeOptions}
                onChange={(e) => {
                    setAttributeOptions(e.target.value);
                    setErrorObj({...errorObj, 
                        attributeOptions: e.target.value ? '' : 'error-attribute-options-required'
                    })
                }}
            />
        }
    }

    return (
        <ModalAtom 
            title={t(mode === 'add' ? 'add-sub-attribute' : 'edit-sub-attribute')}
            show={show} 
            handleClose={onClose}
            handleSubmit={onAddSubAttribute}
            body={ <Container>
                <Row>
                    <Col>
                        <TextFieldAtom 
                            type="number"
                            placeholder={t('position')}
                            required
                            value={position}
                            error={errorObj.position}
                            onChange={(e) => {
                                const position = parseInt(e.target.value);
                                const positionValidationError = validatePosition(position);
                                setPosition(position);
                                setErrorObj({...errorObj, 
                                    position: positionValidationError
                                })
                            }}
                            errorTextPlaceholder={{position: newAttributePosition}}
                        />
                    </Col>
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            placeholder={t('description')}
                            required
                            value={description}
                            error={errorObj.description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                setErrorObj({...errorObj, 
                                    description: e.target.value ? '' : 'error-sub-attribute-description-required'
                                })
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            placeholder={t('label')}
                            required
                            value={label}
                            error={errorObj.label}
                            onChange={(e) => {
                                setLabel(e.target.value);
                                setErrorObj({...errorObj, 
                                    label: e.target.value ? '' : 'error-sub-attribute-label-required'
                                })
                            }}
                        />
                    </Col>
                    <Col className="d-flex">
                        <CheckboxAtom
                            label={t('changeable_av')}
                            checked={isChangeableAV}
                            onChange={(evt) => setIsChangeableAV(evt.target.checked)} />
                        
                        <CheckboxAtom
                            className="ml-4"
                            label={t('changeable_worker')}
                            checked={isChangeableWorker}
                            onChange={(evt) => setIsChangeableWorker(evt.target.checked)} />
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            placeholder={t('external-system-identifier')}
                            disabled={isDisabled}
                            // required
                            value={externalSystemIdentifier}
                            error={errorObj.externalSystemIdentifier}
                            onChange={(e) => {
                                if (e.target.value.match(EXTERNAL_SYSTEM_IDENTIFIER_REGEX)) {
                                    setExternalSystemIdentifier(e.target.value.toUpperCase());
                                }
                            }}
                        />
                    </Col>
                    <Col>
                        <CheckboxAtom
                            label={t('required')}
                            checked={isRequired}
                            onChange={(evt) => setIsRequired(evt.target.checked)} />
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <DropdownAtom 
                            placeholder={t('select-attribute-type')}
                            disabled={isDisabled}
                            required
                            value={attributeType}
                            options={attributeTypesOptions}
                            error={errorObj.attributeType}
                            onChange={onChangeAttributeType}                       
                        />
                    </Col>
                    <Col>
                        {getAttributeFieldType()}
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col className="d-flex">
                        {t('visibility-desktop')}:
                        <CheckboxAtom
                            label={t('sticky-label')}
                            checked={isVisibleSticky}
                            onChange={(evt) => setIsVisibleSticky(evt.target.checked)} />

                        <CheckboxAtom
                            label={t('desktop-label')}
                            checked={isVisibleDesktop}
                            onChange={(evt) => setIsVisibleDesktop(evt.target.checked)} />
                        <CheckboxAtom
                            label={t('history-label')}
                            checked={isVisibleHistory}
                            onChange={(evt) => setIsVisibleHistory(evt.target.checked)} />
                    </Col>
                </Row>
            </Container>}
        />
    );
}

export default AddAttributeModal;
