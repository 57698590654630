import React, { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from "../../../contexts/AuthProvider";
import { HELP_MODES } from "../../../utils/constant";

function TabAtom(props) {
    const { selectedTab, tabs, onTabClick } = props;

    const { t } = useTranslation();
    const { helpMode } = useContext(AuthContext);

    return <ol className="d-flex operation-container">
        <li>{t('orders')}&nbsp;
            {helpMode === HELP_MODES.ADVANCE &&
                <OverlayTrigger overlay={<Tooltip>{t('help-advance.order')}</Tooltip>}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                </OverlayTrigger>
            }
        </li>
        {tabs.map((tab, index) => {
            return <li key={index}><div
                role="button"
                className={selectedTab === tab.key ? "operation-btn active-tab"  : "operation-btn" }
                onClick={() => onTabClick(tab.key)}>{tab.label}</div></li>
        })}
    </ol>;

}

export default TabAtom;
