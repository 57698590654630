import React from "react";
import { TextFieldAtom, ButtonAtom, DropdownAtom, DatePickerAtom } from '../../../components/atoms/atom';
import "./order-filter-bar.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';

function FilterBar(props) {

    const { items, onFilterAction } = props;

    const getSearchFilter = (index, itemInfo) => {
        return <TextFieldAtom
            type="search"
            placeholder={itemInfo?.label}
            value={itemInfo?.value}
            onChange={(e) => onFilterAction(index, itemInfo?.key, { value: e.target.value })}
        />
    }

    const getButton = (index, itemInfo) => {
        return <ButtonAtom
            className={itemInfo.className || "filter-bar-btn"}
            disabled={itemInfo.disabled || false}
            onClick={() => onFilterAction(index, itemInfo?.key)}>
            {itemInfo.label}
        </ButtonAtom>
    }

    const getDropDown = (index, itemInfo) => {
        return <DropdownAtom
            label={itemInfo.label}
            value={itemInfo.value}
            options={itemInfo?.options}
            onChange={(e) => onFilterAction(index, itemInfo?.key, { value: e.target.value })}
            hideNoOptionSelect={itemInfo?.hideNoOptionSelect || false}
            sortOptions={itemInfo?.sortOptions}
            disabled={itemInfo?.disabled}
        />
    }

    const getDatePicker = (index, itemInfo) => {
        const defaultProps = {
            label: itemInfo.label,
            value: itemInfo.value,
            onChange: (e) => onFilterAction(index, itemInfo?.key, { value: e })
        }
        if (itemInfo.isDateRangePicker) {
            defaultProps['startDate'] = itemInfo.value;
            defaultProps['endDate'] = itemInfo.endDate;
            defaultProps['selectsRange'] = true;
        }
        return <DatePickerAtom
            {...defaultProps}
        />
    }

    const getBackButton = (index, itemInfo) => {
        return <FontAwesomeIcon icon={faCircleChevronLeft} onClick={() => onFilterAction(index, itemInfo?.key)} className="fa-xl" />
    }

    return (
        <div className='d-flex filterbar-container full-width-left'>
            {items.map((item, index) => {
                const additionalClass = item.type === 'datepicker' && item.isDateRangePicker ? 'width-15' : ''; 
                return <div className={`filter-item ${additionalClass}`} key={index} >
                    {item.type === 'backButton' && getBackButton(index, item)}
                    {item.type === 'search' && getSearchFilter(index, item)}
                    {item.type === 'button' && getButton(index, item)}
                    {item.type === 'dropdown' && getDropDown(index, item)}
                    {item.type === 'datepicker' && getDatePicker(index, item)}
                </div>
            })}
        </div>

    );
}

export default FilterBar;
