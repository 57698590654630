import React from "react";
import Form from 'react-bootstrap/Form';
import './checkbox.scss';

function CheckboxAtom(props) {
    const { id, label, checked, onChange, ...rest } = props;
    return (
        <Form.Check 
            type='checkbox'
            id={id}
            label={label}
            checked={checked}
            onChange={onChange}
            {...rest}
        />
    );
}

export default CheckboxAtom;
