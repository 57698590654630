import React, { useState } from "react";
import { HoverDropdownAtom } from '../../../components/atoms/atom';

function StepItem(props) {
    const { data, onFilterAction, selectedKey } = props;
    const { resources } = data;
    const [selectedOption, setSelectedOption] = useState(selectedKey || '');

    const sortedResources = (resources || []).sort((op1, op2) => {
        return op1.name.localeCompare(op2.name);
    });

    const onChangeStepDropdown = (val) => {
        setSelectedOption(val);
        onFilterAction(null, 'processStepFilter', { processStepId: data.id, resourceId: val })
    };

    return (

        <div className="filter-item-right margin-adjust">
            <label>{data?.label}</label>
            <div className="dropdown-container">
                <div className="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <HoverDropdownAtom
                        value={selectedOption}
                        onChange={onChangeStepDropdown}
                        menuItems={sortedResources}
                        titleData={{label: data.label, icon: data.icon}}
                    />
                </div>
            </div>
        </div>

    );
}

export default StepItem;
