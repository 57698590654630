import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ModalAtom } from '../../../../components/atoms/atom';
import { FilterBar } from "../../../../components/molecule/molecule";
import { getSearchAddFilter } from '../../../../utils/get-filter-items';
import AddAttributeGroupModal from "./add/add-attribute-group";
import { showToast, getFormattedDate, showError } from '../../../../utils/method';
import { setting } from '../../../../store/services/index';
import SettingsMenu from '../../settings-menu/settings-menu';
import AttributeGroupSortableList from './attribute-group-sortable-list';
import "./process-attributes.scss";

function AttributeGroup(props) {
    const { selectedProcess, onBack } = props;
    const { t } = useTranslation();
    const [attributeModalView, setAttributeModalView] = useState('');
    const [selectedProcessAttribute, setSelectedProcessAttribute] = useState({});
    const [showDeleteModal, setShowdeleteModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [processAttributeList, setProcessAttributeList] = useState([]);
    const [filteredProcessAttributeList, setFilteredProcessAttributeList] = useState([]);
    const [activeAccordionId, setActiveAccordionId] = useState(null);
    const [attributeTypeList, setAttributeTypeList] = useState([]);

    const filters =  getSearchAddFilter(t, { addTextKey: 'add-group-attribute' });
    const leftFilterItems = [
        {
            type: 'backButton',
            key: 'back'
        },
        { 
            type: 'button', 
            key: 'add',
            disabled: true,
            label: <div className="d-flex">
                <p className="text-uppercase m-0">{selectedProcess?.name}</p>
            </div>, 
            className: "selected-process filter-bar-btn"
        }
    ];

    const onFilterAction = (index, key, info) => {
        if (key === 'add') {
            setSelectedProcessAttribute({});
            setAttributeModalView('add');
        }
        if (key === 'back') {
            onBack();
        }
    };

    const fetchProcessAttributes = async () => {
        try{
            let result = await setting.getProcessAttributesByProcessId(selectedProcess?.id);
            result = (result?.result || []).map((record) => {
                return {
                    ...record,
                    provided: getFormattedDate(record.createdAt)
                }
            })
            setProcessAttributeList(result);
            setFilteredProcessAttributeList(result);
        } catch(error){
            showError(t, error);
        }
    }

    const fetchAttributeTypes = async () => {
        try {
            const result = await setting.getAttributeTypes();
            setAttributeTypeList(result.data);
        } catch (error) {
            showError(t, error);
        }
    };
    
    useEffect(() => {
        fetchProcessAttributes();
        fetchAttributeTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPosition = () => {
        if (!filteredProcessAttributeList.length) {
            return 1;
        }
        const positions = filteredProcessAttributeList.map(attr => attr.position); 
        return Math.max(...positions) + 1;
    };
        
    const onAddAttributeGroup = async (groupAttributeInfo) => {
        try{
            if (attributeModalView === 'add') {
                const res = await setting.addAttributeGroup({
                    name: groupAttributeInfo.name,
                    position: groupAttributeInfo.position,
                    processId: selectedProcess?.id,
                });
                showToast(t(res?.message), { type: 'success' });
               
            } else if (attributeModalView === 'edit') {
                const res = await setting.updateAttributeGroup({
                    name: groupAttributeInfo.name,
                    position: groupAttributeInfo.position,
                    processId: selectedProcess?.id,
                }, groupAttributeInfo.id);
                showToast(t(res?.message), { type: 'success' });
               
            }
            fetchProcessAttributes();
            setAttributeModalView('');  
        } catch(error){
            showToast(t(error?.message), { type: 'error' });
        }
    }

    const onDeleteProcessAttribute = async () => {
        try {
            const res = await setting.deleteAttributeGroups(selectedProcessAttribute?.id);
            showToast(t(res?.message), { type: 'success' });
            fetchProcessAttributes();
            setShowdeleteModal(false);
        } catch(error) {
            showToast(t(error?.message), { type: 'error' });
        }
    }

    const onListItemSortEnd = async (items) => {
        try {
            const payload = items.map((item, index)=> {
                return {
                    attributeGroup: item?.id,
                    position: index + 1,
                }
            });
            const res = await setting.reorderAttributeGroups(payload);
            showToast(t(res?.message), { type: 'success' });
            fetchProcessAttributes();
        } catch(error) {
            showToast(t(error?.message), { type: 'error' });
        }
    }

    const onSubAttributeEdit = async (row, rowIndex) => {
        setSelectedProcessAttribute(row);
        setAttributeModalView('edit');
    };

    const onSubAttributeDelete = async (row, rowIndex) => {
        setSelectedProcessAttribute(row);
        setShowdeleteModal(true);
    };

    return (
        <>
            <SettingsMenu />
            <FilterBar 
                leftItems={leftFilterItems}
                rightItems={filters.right}
                onFilterAction={onFilterAction} />

            <AttributeGroupSortableList
                selectedProcess={selectedProcess}
                listItems={filteredProcessAttributeList}
                onListItemSortEnd={onListItemSortEnd}
                onEdit={onSubAttributeEdit}
                onDelete={onSubAttributeDelete}
                fetchProcessAttributes={fetchProcessAttributes}
                activeAccordionId={activeAccordionId}
                setActiveAccordionId={setActiveAccordionId}
                attributeTypeList={attributeTypeList}
            /> 

            { attributeModalView && <AddAttributeGroupModal 
                mode={attributeModalView}
                attributeInfo={selectedProcessAttribute}
                show
                onClose={() => {setAttributeModalView('');} }
                onSubmit={onAddAttributeGroup}
                position={getPosition()}
            />}

            <ModalAtom  
                title={t('delete-attribute')}
                body={<p>{t('delete-attribute-warning')}</p>}
                saveText="delete"
                show={showDeleteModal} 
                handleClose={() => setShowdeleteModal(false) }
                handleSubmit={onDeleteProcessAttribute}
            />
        </>
    );
}

export default AttributeGroup;
