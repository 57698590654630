import { getFormattedDate } from "./method";

const byString = (o, s) => {
    if (!s) {
        return;
    }
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    const a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        const x = a[i];
        if (o && x in o) {
            o = o[x];
        } else {
            return;
        }
    }
    return o;
};

const getFieldValue = (rowData, columnDef) => (typeof rowData[columnDef.field] !== 'undefined'
    ? rowData[columnDef.field]
    : byString(rowData, columnDef.field));

// eslint-disable-next-line array-callback-return
export const searchData = (searchText, data, columns) => (data || []).filter((row) => columns.some((columnDef) => {
    const value = getFieldValue(row, columnDef);
    if (value) {
        return value
            .toString()
            .toUpperCase()
            .includes(searchText.toUpperCase());
    }
}));

const getOrderInfoFieldValue = (rowData, columnDef) => (typeof rowData[columnDef.id] !== 'undefined'
    ? rowData[columnDef.id].value
    : '');

// eslint-disable-next-line array-callback-return
export const searchOrderData = (searchText, data, columns) => (data || []).filter((row) => columns.some((columnDef) => {
    let value = getOrderInfoFieldValue(row?.orderInfo, columnDef);
    if (columnDef?.attributeFormat?.name === 'DATE') {
        value = getFormattedDate(value)
    }
    if (columnDef?.attributeFormat?.name === 'DATETIME') {
        value = getFormattedDate(value, true)
    }
    if (value) {
        return value
            .toString()
            .toUpperCase()
            .includes(searchText.toUpperCase());
    }
}));

const getArchiveOrderFieldValue = (rowData, columnDef) => (typeof rowData[columnDef.field] !== 'undefined'
    ? rowData[columnDef.field]
    : '');

// eslint-disable-next-line array-callback-return
export const searchArchiveOrderData = (searchText, data, columns) => (data || []).filter((row) => columns.some((columnDef) => {
    const value = getArchiveOrderFieldValue(row?.orderInfo, columnDef);
    if (value) {
        return value
            .toString()
            .toUpperCase()
            .includes(searchText.toUpperCase());
    }
}));
