import React,  { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import { ModalAtom, AgGridAtom } from "../../../components/atoms/atom";
import { getInfoBoxColumns } from './constant';
import { AuthContext } from "../../../contexts/AuthProvider";
import '../info-box/info-box.scss';
import { HELP_MODES } from "../../../utils/constant";

const getNewInfoRow = (user, resource) => ({
    createdAt: new Date(),
    user,
    resource,
    comment: '',
    mode: 'new'
});

function NewOrderInfoBoxModal(props) {
    const { show, newOrderData = {}, onClose, onSubmit, processSteps} = props;
    const { t } = useTranslation();
    const { auth, helpMode } = useContext(AuthContext);
    const [resources, setResources] = useState({});
    const [stepInfo, setStepInfo] = useState([]);

    const onSubmitClick = () => {
        const steps = stepInfo.map((step) => ({
            createdAt: step.createdAt,
            user: step.user,
            comment: step.comment,
            processStepId: step.resource.processStepId,
            resourceId: step.resource.id,
        }));
        onSubmit(steps)
    }

    const onDeleteInfo = (data, rowIndex) => {
        const localStepInfo = [...stepInfo];
        localStepInfo.splice(rowIndex, 1);
        setStepInfo([...localStepInfo]);
    }

    const colDefs = getInfoBoxColumns(t, resources, onDeleteInfo);

    const onAddInfo = () => {
        const newInfoObj = getNewInfoRow(auth.name, resources[0]);
        const updatedStepInfo = [newInfoObj, ...stepInfo];
        setStepInfo(updatedStepInfo);
    }

    const findDetailedResource = (resource) => {
        const resourceId = resource.resourceId;
        const processStepId = resource.processStepId;
        const processStep = processSteps?.find((ps) => ps.id === processStepId);
        const resourceFound = processStep?.resources?.find((res) => res.id === resourceId);
        
        return { ...resourceFound, resourceId, processStepId }
    }

    useEffect(() => {
        const detailedProcessStepInfoData = newOrderData?.orderProcessStepInfo?.map((psi) => ({
            ...psi,
            resource: findDetailedResource(psi),
            mode: 'new',
        }))
        setStepInfo(detailedProcessStepInfoData || []);
        setResources(() => {
            return newOrderData?.processSteps?.map((ps) => {
                return findDetailedResource(ps);
            })
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newOrderData])

    return (
        <ModalAtom 
            title={t('infobox')}
            titleHelpText={helpMode === HELP_MODES.ADVANCE ? t('help-advance.order-detail-infobox-title') : ''}
            customClassName='info-box-modal'
            show={show} 
            handleClose={onClose}
            showMiddleButton={auth.role !== 'SALES'}
            handleSubmit={onSubmitClick}
            saveText="SAVE"
            onMiddleButtonAction={onAddInfo}
            body={ <Container>
                <AgGridAtom
                    columnDefs={colDefs}
                    rowData={stepInfo || []}
                />
            </Container>}
        />
    );
}

export default NewOrderInfoBoxModal;
