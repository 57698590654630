import CustomHeaderGroup from "../../../components/atoms/header-group/header-group";
import { formatValueToLocale, getFormattedDate } from '../../../utils/method';

// DATE COMPARATOR FOR SORTING
const dateComparator = (date1, date2) => {
    var date1Number = _monthToNum(getFormattedDate(date1));
    var date2Number = _monthToNum(getFormattedDate(date2));
  
    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }
  
    return date1Number - date2Number;
};
  
// HELPER FOR DATE COMPARISON
const _monthToNum = (date) => {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }

    const [dayNumber, monthNumber, yearNumber] = date.split('.');
  
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
};

const numberComparator = (number1, number2) => { 
    if (number1 === null && number2 === null) {
        return 0;
    }
    if (number1 === null) {
        return -1;
    }
    if (number2 === null) {
        return 1;
    }
    number1 = number1.toString().replace(/\./g, '').replace(',', '.');
    number2 = number2.toString().replace(/\./g, '').replace(',', '.');
    return number1 - number2;
};

const dynamicSort = (property) => {
    return (obj1, obj2) => {
        const ord1OrderAttribute = obj1?.orderInfo[property?.value];
        const ord2OrderAttribute = obj2?.orderInfo[property?.value];

        if (ord1OrderAttribute?.attributeFormat === 'NUMBER') {
            return numberComparator(ord1OrderAttribute?.value, ord2OrderAttribute?.value)
        } else if (ord1OrderAttribute?.attributeFormat === 'DATE') {
            return dateComparator(ord1OrderAttribute?.value, ord2OrderAttribute?.value)
        } else {
            return (ord1OrderAttribute?.value || '').localeCompare((ord2OrderAttribute?.value || ''));
        }
    };
}

const dynamicSortMultipleParams = (props) => {
    return (ord1, ord2) => {
        let i = 0;
        let result = 0;
        let numberOfProperties = props.length;
        while(result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(ord1, ord2);
            i++;
        }
        return result;
    };
}

const getOrderTableAttributes = (attributeGroups) => {
    const dynamicColumn = [];
    attributeGroups.forEach((attributeGroup) => {
        dynamicColumn.push({
            headerName: attributeGroup.name,
            headerGroupComponent: CustomHeaderGroup,
            children: attributeGroup?.attributes.filter((attr) => attr.isVisibleDesktop).map((attr, index) => {
                let returnData = {
                    field: `orderInfo.${attr?.id}.value`,
                    headerName: attr?.label,
                    unSortIcon: true,
                    columnGroupShow: !attr?.isVisibleSticky ? 'open' : '',
                    minWidth: 150,
                    resizable: true,
                    suppressMovable: true,
                    headerTooltip: attr?.label,
                };
                if (['DATE', 'DATETIME'].includes(attr.attributeFormat?.name)) {
                    returnData = {
                        ...returnData,
                        comparator: dateComparator,
                        cellRenderer: (props) => {
                            return (attr.attributeFormat?.name === 'DATE' ? getFormattedDate(props.value) : getFormattedDate(props.value, true));
                        }
                    };
                }
                if (attr.attributeFormat?.name === 'NUMBER') {
                    returnData = {
                        ...returnData,
                        type: 'numericColumn',
                        maxWidth: 100,
                        comparator: numberComparator,
                        cellRenderer: (props) => {
                            let val = props.value;
                            if (attr?.externalSystemIdentifier === 'WEIGHT_P') {
                                val = formatValueToLocale(val, false, 3);
                            }
                            return val || '';
                        }
                    }
                }
                return returnData;
            })
        });
    });
    return dynamicColumn;
};

const getFiltersFromStorage = () => {
    const filters = localStorage.getItem('filterObj');
    if (!filters) {
        localStorage.setItem('filterObj', JSON.stringify({}));
        return {};
    }
    return JSON.parse(filters);
};

export {
    dateComparator,
    numberComparator,
    getOrderTableAttributes,
    getFiltersFromStorage,
    dynamicSortMultipleParams,
}