import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import "./modal.scss";

function ModalAtom(props) {
    const {
        show, handleClose, handleSubmit, title, body, centered = true,
        saveText, footer, customClassName = '', middleButtonText = '',
        showMiddleButton = false, onMiddleButtonAction, hideCloseButton = false,
        middleButtonTitle = '', titleHelpText = ''
    } = props;
    const { t } = useTranslation();

    return (
        <Modal show={show} onHide={handleClose} centered={centered} className={customClassName} backdrop="static">
            <Modal.Header>
                <Modal.Title>{title}&nbsp;
                    {titleHelpText && <OverlayTrigger overlay={<Tooltip>{titleHelpText}</Tooltip>}>
                        <FontAwesomeIcon icon={faCircleInfo} />
                    </OverlayTrigger>}
                </Modal.Title>
                <div className='modal-btn-container'>
                    {!hideCloseButton && <Button variant="secondary" onClick={handleClose} className="modal-close-btn">
                        {t('close')}
                    </Button>}
                    {showMiddleButton && 
                        <OverlayTrigger overlay={middleButtonTitle ? <Tooltip>{middleButtonTitle}</Tooltip> : <></>}>
                            <Button className="mx-2 text-white btn btn-primary" onClick={onMiddleButtonAction}>
                                {t(middleButtonText || `+ ${t('INFO')}`)}
                            </Button>
                        </OverlayTrigger>
                    }
                    <Button className="mx-2 text-white btn btn-primary" onClick={handleSubmit}>
                        {t(saveText || 'save')}
                    </Button>
                </div>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            {footer && <Modal.Footer>
                {footer}
            </Modal.Footer>
            }
        </Modal>
    );
}
export default ModalAtom;
