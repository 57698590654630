const SOCKET_RESPONSE = {
    CONNECT: 'connect',
    STATUS: 'update-order-status-response',
    ORDER: 'update-order-response',
    NEW_ORDER: 'order-list/new-order',
    OPERATOR_ORDER_LIST: 'operator/order-list-updated',
    ERROR: 'error',
    DISCONNECT: 'disconnect',
    JOIN_ROOM: 'join-room',
    CONNECT_ERROR: 'connect_error',
    OPERATOR_ORDER_DATA_UPDATED: 'operator/order-data-updated',
    OPERATOR_ORDER_NOT_AVAILABLE: 'operator/order-not-available',
    OPERATOR_ORDER_PROCESS_STEP_UPDATED: 'operator/order-process-step-updated',
    DELETE_ORDER: 'order-list/delete-order',
};

export default SOCKET_RESPONSE;