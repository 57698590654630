import React,  { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import { ModalAtom, AgGridAtom } from "../../../components/atoms/atom";
import { getInfoBoxColumns } from './constant';
import { AuthContext } from "../../../contexts/AuthProvider";
import './info-box.scss';
import { HELP_MODES } from "../../../utils/constant";

const getNewInfoRow = (user, resources) => ({
    id: '',
    createdAt: new Date(),
    user,
    resource: resources[0] || {
        id: '',
        processStepId: '',
        resourceId: '',
        icon: '',
        name: '',
        locationName: '',
    },
    comment: '',
    mode: 'new'
});

function InfoBoxModal(props) {
    const { show, selectedRowInfo = {}, onClose, onSubmit, refresh, selectedOrders} = props;
    const { t } = useTranslation();
    const { auth, helpMode } = useContext(AuthContext);
    const [resources, setResources] = useState({});
    const [stepInfo, setStepInfo] = useState({});

    const onSubmitClick = () => {
        const steps = stepInfo.filter((step) => step.mode === 'new').map((step) => ({
            id: '',
            comment: step.comment,
            orderProcessStepId: step.resource.id
        }));
        onSubmit({steps});
    }

    const onDeleteInfo = (data, rowIndex) => {
        const localStepInfo = [...stepInfo];
        localStepInfo.splice(rowIndex, 1);
        setStepInfo([...localStepInfo]);
    }

    const colDefs = getInfoBoxColumns(t, selectedRowInfo?.orderProcessSteps, resources, onDeleteInfo);

    const onAddInfo = () => {
        const newInfoObj = getNewInfoRow(auth.name, resources);
        const updatedStepInfo = [newInfoObj, ...stepInfo];
        setStepInfo(updatedStepInfo);
    }

    useEffect(() => {
        setStepInfo(() => selectedRowInfo?.orderProcessStepInfo?.map((stepIn) => {
            const selectedProcessStep = selectedRowInfo?.orderProcessSteps?.find((step) => step.id === stepIn.orderProcessStepId);
            return {
                createdAt: stepIn.createdAt,
                user: `${stepIn?.user?.firstName} ${stepIn?.user?.lastName}`,
                resource: {
                    id: selectedProcessStep?.id,
                    processStepId: selectedProcessStep?.processStep?.id,
                    resourceId: selectedProcessStep?.resource.id,
                    icon: selectedProcessStep?.resource?.icon,
                    name: selectedProcessStep?.resource?.label,
                    locationName: selectedProcessStep?.resource?.locationName,
                },
                comment: stepIn.comment,
                mode: 'view',
            }
        }))
        setResources(() => selectedRowInfo?.orderProcessSteps?.map((ps) => ({
            ...ps.resource,
            resourceId: ps.resource.id,
            id: ps.id,
        })));
    }, [selectedRowInfo, refresh, selectedOrders])

    return (
        <ModalAtom 
            title={t('infobox')}
            titleHelpText={helpMode === HELP_MODES.ADVANCE ? t('help-advance.order-detail-infobox-title') : ''}
            customClassName='info-box-modal'
            show={show} 
            handleClose={onClose}
            showMiddleButton={auth.role !== 'SALES'}
            handleSubmit={onSubmitClick}
            saveText="SAVE"
            onMiddleButtonAction={onAddInfo}
            refresh={refresh}
            body={ <Container>
                <AgGridAtom
                    columnDefs={colDefs}
                    rowData={stepInfo || []}
                    refresh={refresh}
                />
            </Container>}
        />
    );
}

export default InfoBoxModal;
