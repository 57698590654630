import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCirclePlay, faCirclePause, faCircleCheck } from '@fortawesome/free-solid-svg-icons';


const STEP_STATUS = {
    1: 'NOT_AVAILABLE',
    2: 'AVAILABLE',
    3: 'IN_PROGRESS',
    4: 'PAUSE',
    5: 'DONE'  
};

const statusOptions = [
    {
        value: STEP_STATUS[1],
        label: '',
        status: 1,
    },
    {
        value: STEP_STATUS[2],
        label: <FontAwesomeIcon icon={faCircle} className="icon-not-started" />,
        status: 2,
    },
    {
        value: STEP_STATUS[3],
        label: <FontAwesomeIcon icon={faCirclePlay} className="icon-play" />,
        status: 3,
    },
    {
        value: STEP_STATUS[4],
        label: <FontAwesomeIcon icon={faCirclePause} className="icon-pause" />,
        status: 4,
    },
    {
        value: STEP_STATUS[5],
        label: <FontAwesomeIcon icon={faCircleCheck} className="icon-done" />,
        status: 5,
    }      
]

export {
    statusOptions,
    STEP_STATUS
}