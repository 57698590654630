/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import clone from 'rfdc/default';

import "./archive.scss";
import { AgGridAtom } from "../../../components/atoms/atom";
import { getArchiveFilter } from './constant';
import { getFiltersFromStorage } from "../utils/method";
import { getFormattedDate, showError } from '../../../utils/method';
import { setting } from '../../../store/services/index';
import FilterBar from "../order-filter-bar/order-filter-bar";
import { AuthContext } from '../../../contexts/AuthProvider';
import { searchArchiveOrderData } from '../../../utils/search';

function ArchivePage() {
    const { orderMasterData } = useContext(AuthContext);
    const { orderMasterDataloaded, processList = []} = orderMasterData;

    const { t } = useTranslation();
    const [filterItems, setFilterItems] = useState(getArchiveFilter(t, getFiltersFromStorage()));
    const [archiveColumns, setArchiveColumns] = useState([]);
    const [selectedProcessId, setSelectedProcessId] = useState(null);

    const [orders, setOrders] = useState([]);

    const initializeFilter = async () => {
        try {
            const fItems = clone(filterItems);
            const processFilter = fItems.find((f) => f.key === 'processId');
            const processId = processFilter?.value || processList[0]?.id;
            const processOptions = [];
            processList?.forEach((re) => processOptions.push({ label: re?.name, value: re?.id }));
            processFilter.value = processId;
            processFilter.options = processOptions;
            setFilterItems(fItems);
            setSelectedProcessId(processId);
            fetchOrders(fItems);
        } catch(error) {
            showError(t, error);
        }
    }

    useEffect(() => {
        if (selectedProcessId) {
            fetchProcessAttributes();
        }
    }, [selectedProcessId]);

    const fetchProcessAttributes = async () => {
        const attributeGroupResult = await setting.getProcessAttributesByProcessId(selectedProcessId);
        const aGroups = attributeGroupResult?.result || [];
        let cols = []
        if (aGroups.length) {
            cols  = aGroups.reduce((group = {}, gr = {}) => {
                let t  = group;
                t.attributes = [...t.attributes, ...gr.attributes];
                return t;
            }).attributes.filter((tt) => tt.isVisibleHistory).map((col) => {
                return {
                    field: col.externalSystemIdentifier,
                    headerName: col.label,
                    resizable: true,
                    suppressMovable: true,
                    unSortIcon: true,
                }
            });
        }
        setArchiveColumns(cols);
    };

    useEffect(() => {
        if (orderMasterDataloaded && processList.length) {
            initializeFilter();
        }
    }, [orderMasterDataloaded]);


    useEffect(() => {
        const filObj = {};
        filterItems.forEach((item) => filObj[item.key] = item.value);
        localStorage.setItem('filterObj', JSON.stringify(filObj));
    }, [filterItems]);

    const fetchOrders = async (fItems = filterItems) => {
        try {
            const filters = fItems.reduce((acc, {key, value, endDate}) => {
                if (['date', 'timePeriod', 'processId'].includes(key)) {
                    if (key === 'date') {
                        value = getFormattedDate(value);
                        if (endDate) {
                            endDate = getFormattedDate(endDate);
                            acc.push(`endDate=${endDate}`)
                        }
                    }
                    acc.push(`${key}=${value}`)
                }
                return acc;
            }, []);
            let queryString = filters.join('&');
            let result = await setting.getArchiveOrders(queryString);
            setOrders(result?.data || []);
        } catch(error) {
            showError(t, error);
        }
    }

    const onFilterAction = (index, key, info) => {
        if (['date', 'timePeriod', 'onTime', 'processId', 'search'].includes(key)) {
            const value = info?.value;
            const localLeftFilterItems = clone(filterItems);
            const filterToUpdate = localLeftFilterItems.find((filter) => filter.key === key);
            if (!filterToUpdate) {
                return;
            }
            if (key === 'timePeriod') {
                const dateFilter = localLeftFilterItems.find((filter) => filter.key === 'date');
                dateFilter.isDateRangePicker = false;
                dateFilter.endDate = new Date();
                if (info?.value === 'variable') {
                    dateFilter.isDateRangePicker = true;
                }
            }
            if (key === 'date') {
                if (Array.isArray(value)) {
                    const [start, end] = value;
                    filterToUpdate.value = start;
                    filterToUpdate.endDate = end;
                } else {
                    filterToUpdate.value = value;
                }
            } else {
                filterToUpdate.value = value;
            }
            setFilterItems(localLeftFilterItems);
            if ('processId' === key) {
                setSelectedProcessId(value);
            }
            if (key !== 'search' && key !== 'onTime') {
                fetchOrders(localLeftFilterItems);
            }
        }
    };

    const getFilteredOrders = () => {
        let onTimeValue = filterItems.find((fItem) => fItem.key === 'onTime').value;
        const searchValue = filterItems.find((fItem) => fItem.key === 'search').value;
        let lOrders = clone(orders);
        if (['on-time', 'late'].includes(onTimeValue)) {
            onTimeValue = onTimeValue === 'on-time' ? true: false;
            lOrders = lOrders.filter((ord) => ord.completedOnTime === onTimeValue);
        }
        lOrders = searchArchiveOrderData(searchValue, lOrders, archiveColumns);
        return lOrders;
    };

    return <div className="archive">
        <div className="d-flex mt-2 filterbar light">
            <FilterBar 
                items={filterItems}
                onFilterAction={onFilterAction}
            />
        </div>
        <AgGridAtom
            columnDefs={archiveColumns}
            rowData={getFilteredOrders().map((ord) => ord.orderInfo)}
        />
    </div>

}

export default ArchivePage;
