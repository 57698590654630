import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

function IconUpload(props) {
    const fileUploadRef = useRef();
    const { label, iconFile = null, iconPreview = '', error = '', onIconUpload = () => {} } = props;
    const { t } = useTranslation();

    const handleUpload = () => {
        fileUploadRef.current?.click();
    }

    const onFileUpload = () => {
        const file = fileUploadRef.current?.files;
        if (!file?.length) {
            return;
        }
        onIconUpload(file[0]);
    }

    return <>
        <label>{label}:*</label>
        <input
            ref={fileUploadRef}
            className="d-none"
            type="file"
            accept="image/png"
            onChange={onFileUpload}
        />
        <button onClick={handleUpload} className="btn btn-outline-primary mx-3">
            {t('browse')}
        </button>
        {iconFile && <img style={{ height: '50px' }} className="logo-preview" src={iconPreview} alt='' /> }
        {error ? <p className="text-danger">{t(error)}</p> : ''}
    </>
}

export default IconUpload;
