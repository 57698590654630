import React, { useEffect, useState } from 'react';
import './header-group.scss';

function CustomHeaderGroup(props) {
    const {
        showExpandCollapseIcons = true,
    } = props;
    const [expandState, setExpandState] = useState('collapsed');

    const expandOrCollapse = () => {
        let currentState = props.columnGroup.getProvidedColumnGroup().isExpanded();
        props.setExpanded(!currentState);
    };

    const syncExpandButtons = () => {
        setExpandState(
            props.columnGroup.getProvidedColumnGroup().isExpanded()
                ? 'expanded'
                : 'collapsed'
        );
    };

    useEffect(() => {
        props.columnGroup
            .getProvidedColumnGroup()
            .addEventListener('expandedChanged', syncExpandButtons);
        syncExpandButtons();

        return () => {
            props.columnGroup
                .getProvidedColumnGroup()
                .removeEventListener('expandedChanged', syncExpandButtons);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ag-header-group-cell-label custom-header-group">
            {showExpandCollapseIcons && <div
                className={`customExpandButton ${expandState}`}
                onClick={() => expandOrCollapse()}
            >
            </div>}
            <div className="customHeaderLabel">{props.displayName}</div>
        </div>
    );
};

export default CustomHeaderGroup;