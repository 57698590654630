import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import clone from 'rfdc/default';
import { setting } from '../../../store/services';
import { showError, showToast } from '../../../utils/method';
import OrderTemplateModal from '../new-order/order-template-modal';
import NewOrderInfoBoxModal from '../new-order/info-box';
import NewOrderModal from '../new-order/new-order';
import AddNewOrderProcessStepModal from '../new-order/process-step';
import SaveAsTemplateModal from '../new-order/save-as-template';

const NewOrderModals = (props) => {
    const { orderType, attributeGroupsList, processSteps, newOrderData, setNewOrderData, showNewOrderModal, setShowNewOrderModal, initializeNewOrderObject, showOrderTemplateModal, setShowOrderTemplateModal, templateList = [], copyAndInitializeOrderObject } = props;

    const clonedAttributeGroup = clone(attributeGroupsList);
    const { t } = useTranslation();
    
    const [showNewOrderAddProcessStep, setShowNewOrderAddProcessStep] = useState(false);
    const [showNewOrderInfoBox, setShowNewOrderInfoBox] = useState(false);
    const [showSaveAsTemplateModal, setShowSaveAsTemplateModal] = useState(false);

    const [selectedTemplate, setSelectedTemplate] = useState({});

    const processStepDataComparer = (oldData, newData) => {
        // console.log('data comparer', oldData, newData);
        const missingOrModifiedProcessStepIds = [];
        for (const od of oldData) {
            let tip = 0;
            for (const nd of newData) {
                if (od.processStepId !== nd.processStepId) {
                    tip = tip + 1
                } else {
                    if (od.resourceId !== nd.resourceId) {
                        missingOrModifiedProcessStepIds.push(od.processStepId)
                        continue;
                    } else {
                        continue;
                    }
                }
            }
            if (tip === newData.length) {
                missingOrModifiedProcessStepIds.push(od.processStepId)
            }
        }
        return missingOrModifiedProcessStepIds;
    }

    const eliminateProcessStepInfo = (processStepInfo, processStepsIdArray) => {
        // for those process steps who are removed or altered.
        for (const id of processStepsIdArray) {
            var newProcessStepInfo = processStepInfo?.filter((psi) => (psi.processStepId !== id));
        }
        return newProcessStepInfo;
    }

    const onNewAddOrderProcessStep = (selectedRow) => {
        // Elimininating the process step info, for a particular process step, if that process step is popped from the new process step list.
        const processStepsIds = processStepDataComparer(newOrderData?.processSteps, selectedRow);
        const newProcessStepInfoArray = eliminateProcessStepInfo(newOrderData?.orderProcessStepInfo, processStepsIds);
        setNewOrderData({ ...newOrderData, processSteps: selectedRow, orderProcessStepInfo: newProcessStepInfoArray })
        setShowNewOrderAddProcessStep(false);
    }

    const onInfoClick = (newData) => {
        setShowNewOrderInfoBox(true);
        setNewOrderData(newData);
    }

    const onSaveNewOrderInfo = (steps) => {
        setNewOrderData({ ...newOrderData, orderProcessStepInfo: steps });
        setShowNewOrderInfoBox(false);
    }

    const onSubmitNewOrder = async () => {
        const orderProcessStepInfo = newOrderData?.orderProcessStepInfo;
        if (orderProcessStepInfo?.length) {
            orderProcessStepInfo.forEach((psInfo) => {
                delete psInfo.user;
                delete psInfo.createdAt;
            });
        }
        const orderInfo = newOrderData.orderInfo;
        Object.keys(orderInfo).forEach((k) => (orderInfo[k] === null || orderInfo[k] === '' || orderInfo[k] === undefined) && delete orderInfo[k]);
        try {
            const response = await setting.createNewOrder(newOrderData);
            if (response) {
                if (newOrderData.isTemplate) {
                    showToast(t('template-save-success', {templateName: newOrderData?.templateName }), { type: 'success' });
                } else {
                    showToast(t('order-create-success', {salesOrderNumber: response?.data }), { type: 'success' });
                }
                setShowSaveAsTemplateModal(false);
                setShowNewOrderModal(false);
            }
        } catch(error) {
            showError(t, error);
        }
    }

    const changeTemplateName = (templateName) => {
        setNewOrderData({ ...newOrderData, templateName });
    }

    useEffect(() => {
        if (showSaveAsTemplateModal) {
            setNewOrderData({ ...newOrderData, isTemplate: true });
        } else {
            setNewOrderData({ ...newOrderData, isTemplate: false, templateName: null });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSaveAsTemplateModal]);

    const selectTemplate = (id) => {
        const templateSelected = templateList.find((template) => {
            return template.id === id;
        })
        setSelectedTemplate(templateSelected);
    }

    const onSelectedTemplate = () => {
        setNewOrderData(copyAndInitializeOrderObject(selectedTemplate));
        setShowOrderTemplateModal(false);
        setShowNewOrderModal(true);
    }

    useEffect(() => {
        if (!showOrderTemplateModal) {
            setSelectedTemplate({});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOrderTemplateModal]);

    return (
        <>
            {showNewOrderModal && <NewOrderModal
                orderType={orderType}
                attributeGroups={clonedAttributeGroup}
                newOrderData={newOrderData}
                setNewOrderData={setNewOrderData}
                show={showNewOrderModal}
                onClose={() => {
                    setShowNewOrderModal(false);
                    setNewOrderData(initializeNewOrderObject());
                }}
                processSteps={processSteps} 
                onSubmit={onSubmitNewOrder}
                hideFooter
                onAddProcessStepClick={() => {
                    setShowNewOrderAddProcessStep(true);
                }} 
                onClickInfo={() => onInfoClick(newOrderData)}
                onClickSaveAsTemplate={() => setShowSaveAsTemplateModal(true)}
            />}
        
            {showNewOrderAddProcessStep && <AddNewOrderProcessStepModal
                show={showNewOrderAddProcessStep}
                onClose={() => setShowNewOrderAddProcessStep(false)}
                onSubmit={onNewAddOrderProcessStep}
                newOrderData={newOrderData}
                processSteps={processSteps} />}

            {showNewOrderInfoBox && <NewOrderInfoBoxModal
                show={showNewOrderInfoBox}
                onClose={() => setShowNewOrderInfoBox(false)}
                onSubmit={onSaveNewOrderInfo}
                newOrderData={newOrderData}
                processSteps={processSteps}
            />}
            {showSaveAsTemplateModal && <SaveAsTemplateModal 
                show={showSaveAsTemplateModal}
                onClose={() => setShowSaveAsTemplateModal(false)}
                onSubmit={onSubmitNewOrder}
                changeTemplateName={changeTemplateName}
            />}
            {showOrderTemplateModal && <OrderTemplateModal 
                show={showOrderTemplateModal}
                onClose={() => setShowOrderTemplateModal(false)}
                onSubmit={onSelectedTemplate}
                templateList={templateList}
                selectedTemplate={selectedTemplate}
                selectTemplate={selectTemplate}
            />}
        </>
    )
}

export default NewOrderModals;