import CustomDropdown from "./custom-renderer/dropdown";
import { HoverDropdownAtom, TextFieldAtom } from "../../../components/atoms/atom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getFormattedDate } from "../../../utils/method";

const getProcessStepTableColumns = (t, defaultSelectedRow, onProcessStepResourceUpdate = () => {}) => {
    return [
        {
            field: 'label',
            headerName: t('process-step'),
            headerCheckboxSelection: true,
            cellRenderer: (props) => {
                const {id} = props.data;
                if (defaultSelectedRow.find((row) => row.processStepId === id)) {
                    props.node.setSelected(true, false, true);
                } else {
                    props.node.setSelected(false, false, true);
                }
                return props.value;
            },
            checkboxSelection : function(props){
                const { position } = props.data;
                return position > -1;
            }
        },
        {
            field: 'description',
            headerName: t('resource'),
            cellRenderer: CustomDropdown,
            width: 200,
            cellRendererParams: {
                defaultSelectedRow,
                onProcessStepResourceUpdate,
            }
        },
    ];
}

const getInfoBoxColumns = (t, resources, onDeleteInfo) => {
    return [
        {
            field: 'createdAt',
            headerName: t('date'),
            unSortIcon: true,
            cellRenderer: (props) => {
                const { data } = props;
                return getFormattedDate(data.createdAt, true);
            }
        },
        {
            field: 'resource',
            headerName: t('resource'),
            unSortIcon: true,
            cellRenderer: (props) => {
                const { data } = props;
              
                return <HoverDropdownAtom
                    menuItems={resources}
                    value={data.resource.id}
                    hideAllOption
                    disabled={data.mode !== 'new'}
                    titleData={{ label: data.resource.name, icon: data.resource.icon }}
                    onChange={(val) => {
                        const resource = resources.find((res) => res.id === val);
                        const colId = props.column.colId;
                        props.node.setDataValue(colId, {
                            id: resource.id,
                            icon: resource.icon,
                            name: resource.name,
                            locationName: resource.locationName,
                            processStepId: resource.processStepId,
                        });
                    }}
                />
            }
        },
        {
            field: 'user',
            headerName: t('user'),
            unSortIcon: true,
        },
        {
            field: 'comment',
            headerName: t('information'),
            unSortIcon: true,
            cellRenderer: (props) => {
                const { data } = props;
                if (data.mode !== 'new') {
                    return data.comment;
                }
                
                return <TextFieldAtom 
                    type="text"
                    placeholder={t('information')}
                    required
                    value={data.comment}
                    onChange={(e) => {
                        const colId = props.column.colId;
                        props.node.setDataValue(colId, e.target.value);
                    }}
                />
            }
        },
        {
            headerName: t('action'),
            headerTooltip: t('action'),
            sortable: false,
            cellRenderer: (props) => {
                const {data, rowIndex} = props;
                if (data?.mode === 'new') {
                    return <div className="d-flex text-uppercase ag-action-cell">
                        <FontAwesomeIcon icon={faTrash} onClick={() => onDeleteInfo(data, rowIndex)} />
                    </div>
                }
                return <></>;
            },
        }
    ]
}

export {
    getProcessStepTableColumns,
    getInfoBoxColumns
}