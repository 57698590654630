import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TextFieldAtom, ModalAtom } from "../../../../../components/atoms/atom";

function AddAttributeModal(props) {
    const { show, onClose, onSubmit, mode, attributeInfo = {}, position = 1 } = props;
    const { t } = useTranslation();

    const [nameObj, setNameObj] = useState({
        value: attributeInfo?.name || '',
        error: '',
    });
    const [positionObj, setPositionObj] = useState({
        value: (attributeInfo?.position === null || attributeInfo?.position === undefined) ? position: attributeInfo?.position,
        error: '',
    });

    const validatePosition = (positionValue) => {
        if (positionValue === 0) {
            return 'error-attribute-position-validation';
        }
        if (!positionValue) {
            return 'error-process-step-position-required';
        }
        if (positionValue > position) {
            return 'error-attribute-position-greater-than-error';
        }
        return '';
    };

    const onAddAttribute = () => {
        if (!nameObj.value) {
            setNameObj({...nameObj, error: 'error-attribute-name-required'});
            return;
        }
        const positionValidationError = validatePosition(positionObj.value);
        if (positionValidationError) {
            setPositionObj({...positionObj, error: positionValidationError});
            return;
        }
        onSubmit({
            ...attributeInfo,
            name: nameObj.value,
            position: positionObj.value,
        });      
    }

    return (
        <ModalAtom 
            title={t(mode === 'add' ? 'add-attribute' : 'edit-attribute')}
            show={show} 
            handleClose={onClose}
            handleSubmit={onAddAttribute}
            body={ <Container>
                <Row>
                    <Col>
                        <TextFieldAtom 
                            type="number"
                            placeholder={t('position')}
                            value={positionObj.value}
                            required
                            error={positionObj.error}
                            onChange={(e) => {
                                const position = parseInt(e.target.value);
                                const positionValidationError = validatePosition(position);
                                setPositionObj({...positionObj, 
                                    value: position, 
                                    error: positionValidationError
                                })
                            }}
                            errorTextPlaceholder={{position}}
                        />
                    </Col>
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            placeholder={t('name')}
                            value={nameObj.value}
                            required
                            error={nameObj.error}
                            onChange={(e) => {
                                setNameObj({...nameObj, 
                                    value: e.target.value, 
                                    error: e.target.value ? '' : 'error-attribute-name-required'
                                })
                            }}
                        />
                    </Col>
                </Row>
            </Container>}
        />
    );
}

export default AddAttributeModal;
