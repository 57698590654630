import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { auth } from '../../../store/services/index';
import { TextFieldAtom, ButtonAtom } from '../../../components/atoms/atom';
import { showToast } from '../../../utils/method';
import './reset-password.css';

function ResetPasswordPage() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [errorObj, setErrorObj] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
 
    const resetPassword = async () => {
        let localErrorObj = {};
        if (!currentPassword) {
            localErrorObj.currentPassword = 'error-current-password-required';
        }
        if (!newPassword) {
            localErrorObj.newPassword = 'error-new-password-required';
        }
        if (!confirmPassword) {
            localErrorObj.confirmPassword = 'error-confirm-password-required';
        }
        setErrorObj({...localErrorObj});
        const hasError = Object.keys(localErrorObj).length ;
        if (!hasError){
            try {
                const res = await auth.resetPassword({
                    currentPassword,
                    newPassword,
                });
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                navigate('/')
                showToast(t(res?.message), { type: 'success' });
            } catch(error){
                showToast(t(error?.message), { type: 'error' });
            }
        }
        
    }
    return (
        <div className="auth">
            <div className="auth-wrapper">
                <div className="auth-inner">
                    <h3>{t('reset-password')}</h3>
                    <div className="mb-3">
                        <TextFieldAtom 
                            label={t('current-password')}
                            type="password"
                            placeholder={t('enter-password')}
                            value={currentPassword}
                            error={errorObj.currentPassword}
                            onChange={(e) => {
                                setCurrentPassword(e.target.value);
                                setErrorObj({ ...errorObj, currentPassword: e.target.value ? '' : 'error-current-password-required' })
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <TextFieldAtom 
                            label={t('new-password')}
                            type="password"
                            placeholder={t('enter-password')}
                            value={newPassword}
                            error={errorObj.newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                                setErrorObj({ ...errorObj, newPassword: e.target.value ? '' : 'error-new-password-required' })
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <TextFieldAtom 
                            label={t('confirm-password')}
                            type="password"
                            placeholder={t('enter-password')}
                            value={confirmPassword}
                            error={errorObj.confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                setErrorObj({ ...errorObj, confirmPassword: e.target.value ? '' : 'error-confirm-password-required' })
                            }}                       
                        />
                    </div>
                    <div className="d-grid">
                        <ButtonAtom onClick={resetPassword}>
                            {t('reset-password')}
                        </ButtonAtom>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ResetPasswordPage;
