import CustomDropdown from "./custom-renderer/dropdown";

const getProcessStepTableColumns = (t, defaultSelectedRow, maxPositionToEnable, onProcessStepResourceUpdate = () => {}) => {
    // debugger;
    return [
        {
            field: 'label',
            headerName: t('process-step'),
            headerCheckboxSelection: true,
            cellRenderer: (props) => {
                const {id} = props.data;
                if (defaultSelectedRow.find((row) => row.processStepId === id)) {
                    props.node.setSelected(true, false, true);
                } else {
                    props.node.setSelected(false, false, true);
                }
                return props.value;
            },
            checkboxSelection : function(props){
                const { position } = props.data;
                return position > maxPositionToEnable;
            }
        },
        {
            field: 'description',
            headerName: t('resource'),
            cellRenderer: CustomDropdown,
            width: 200,
            cellRendererParams: {
                defaultSelectedRow,
                onProcessStepResourceUpdate,
            }
        },
    ];
}

export {
    getProcessStepTableColumns
}