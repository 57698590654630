import React, { useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { BrandHeaderAtom } from '../../components/atoms/atom';

import NavBar from "./nav-bar/nav-bar";
import NavBarMobile from "./nav-bar/nav-bar-mobile";
import { setting } from '../../store/services/index';
import { AuthContext } from '../../contexts/AuthProvider';

function AuthenticatedRoutes(props) {
    const { element, showHeader = true } = props;
    const navigate = useNavigate();
    const { auth, setAuth } = useContext(AuthContext);

    const getUserInfo = async () => {
        try {
            const res = await setting.getUserInfo();
            setAuth({ ...auth, role: res.data.role, name: res.data.name, resources: res.data.resources});
        } catch(error){
            navigate('/');
            localStorage.setItem('token', '');
            localStorage.setItem('selectedMenu', '');
        }
    };

    useEffect(() => {
        if (!auth.role || !auth.resources) {
            getUserInfo();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.role]);

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem('token')]);

    const onNavigationItemClick = (navigationKey) => {
        navigate(`/${navigationKey}`);
    };

    return (
        <>
            {showHeader && <BrandHeaderAtom action /> }
            {showHeader && <NavBar onNavigationItemClick={onNavigationItemClick} />}
            {!showHeader && <NavBarMobile onNavigationItemClick={onNavigationItemClick} />}
            <Container fluid>
                {element}
            </Container>

        </>
    );
}

export default AuthenticatedRoutes;
