import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { HELP_MODES } from "../../../utils/constant";

const getRoleTableColumns = (t, helpMode = HELP_MODES.BASIC) => {
    return [
        {
            field: 'provided',
            headerName: t('provided'),
            unSortIcon: true,
        }, {
            field: 'name',
            headerName: t('role'),
            unSortIcon: true,
            cellRenderer: (props) => {
                const {name, roleKey} = props.data;
                let tooltip = t(`help-${helpMode}.role-worker`);
                switch (roleKey) {
                case 'ADMIN':
                    tooltip = t(`help-${helpMode}.role-admin`);
                    break;
                case 'AV': 
                    tooltip = t(`help-${helpMode}.role-av`);
                    break;
                case 'SALES':
                    tooltip = t(`help-${helpMode}.role-sales`);
                    break;
                default:
                    tooltip = t(`help-${helpMode}.role-worker`);    
                    break;
                }
                return <>
                    {name} &nbsp;
                    {helpMode === HELP_MODES.ADVANCE && <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
                        <FontAwesomeIcon icon={faCircleInfo} className="fa-lg" />
                    </OverlayTrigger>}
                </>
            },
        },
    ];
}

export {
    getRoleTableColumns,
}