import React, { useState, createContext } from 'react';
import { HELP_MODES } from '../utils/constant';

export const AuthContext = createContext({
    auth: {
        token: localStorage.getItem('token'),
        role: null,
        _id: '',
        name: '',
        resources: null,
        isOperatorNavigationDisabled: false,
    },
    orderListMasterData: {
        processes: [],
        processSteps: [],
        attributeGroups: [],
    },
    loader: false,
    snackbarConfig: {},
    helpMode: localStorage.getItem('help-mode') || HELP_MODES.BASIC,
});

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        token: localStorage.getItem('token'),
        role: null,
        _id: '',
        name: '',
        resources: null,
        isOperatorNavigationDisabled: false,
    });
    const [loader, setLoader] = useState(false);
    const [snackbarConfig, setSnackbarConfig] = useState({});
    const [orderMasterData, setOrderMasterData] = useState({
        orderMasterDataloaded: false,
        processList: [],
        processStepList: [],
        attributeGroupsList: [],
    });
    const [helpMode, setHelpMode] = useState(localStorage.getItem('help-mode') || HELP_MODES.BASIC)

    return (
        <AuthContext.Provider value={{
            auth, setAuth, loader, setLoader, snackbarConfig, setSnackbarConfig, orderMasterData, setOrderMasterData, helpMode, setHelpMode
        }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
