import { faEdit, faTrash, faSitemap } from '@fortawesome/free-solid-svg-icons';
import TableActionButtonAtom from '../../../../../components/atoms/table-action-button/table-action-button';
import { HELP_MODES } from '../../../../../utils/constant';

const getProcessStepTableColumns = (t, onProcessStepEdit, onProcessStepDelete, onProcessStepResource, helpMode = HELP_MODES.BASIC) => {
    return [
        {
            field: 'position',
            headerName: t('position'),
            unSortIcon: true,
        },
        {
            field: 'description',
            headerName: t('description'),
            unSortIcon: true,
        },
        {
            field: 'label',
            headerName: t('label'),
            unSortIcon: true,
        },
        {
            field: 'externalSystemIdentifier',
            headerName: t('external-system-identifier'),
            unSortIcon: true,
        },
        {
            field: 'icon',
            headerName: t('icon'),
            sortable: false,
            cellRenderer: (props) => {
                const {icon} = props.data;
                if (!icon) {
                    return;
                }
                return <img src={icon} alt='' style={{height: '40px'}} />;
            },
        },
        {
            headerName: t('action'),
            sortable: false,
            maxWidth: 120,
            cellRenderer: (props) => {
                const {data, rowIndex} = props;
                return <div className="d-flex text-uppercase ag-action-cell">
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faEdit}
                        onClickAction={() => onProcessStepEdit(data, rowIndex)}
                        tooltipTitle='action-edit'
                    />
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faSitemap}
                        className="mx-2"
                        onClickAction={() => onProcessStepResource(data, rowIndex)}
                        tooltipTitle='action-resource'
                    />
                    <TableActionButtonAtom
                        t={t}
                        helpMode={helpMode}
                        icon={faTrash}
                        onClickAction={() => onProcessStepDelete(data, rowIndex)}
                        tooltipTitle='action-delete'
                    />
                </div>
            }
        }
    ];
}

export {
    getProcessStepTableColumns
}