const languages = [
    {
        key: "en",
        value: "EN"
    },
    {
        key: "de",
        value: "DE"
    },
];

export {
    languages,
}