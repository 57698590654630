import React from "react";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);

function DatePickerAtom(props) {
    const { className="", placeholder, value, onChange, error ='', dateFormat = "dd.MM.yyyy", label, ...rest } = props;
    const { t } = useTranslation();

    
    return (
        <>
            {label ? <label>{label}</label> : ''}
            <DatePicker 
                locale="de"
                className={className || "form-control"} 
                placeholderText={placeholder} 
                selected={value}
                onChange={onChange}
                dateFormat={dateFormat}
                {...rest}
            />
            {error ? <p className="text-danger">{ t(error)}</p> : '' }
        </>
    );
}

export default DatePickerAtom;
