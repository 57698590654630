const getArchiveFilter = (t, filObj = {}) => {
    return [
        {
            key: 'date',
            type: 'datepicker',
            label: t('date'), 
            value: filObj?.date ? new Date(filObj?.date) : new Date(), 
        },
        { 
            key: 'timePeriod',
            type: 'dropdown',
            label: t('period'),
            value: filObj?.timePeriod || 'month',
            hideNoOptionSelect: true,
            sortOptions: false,
            options: [{
                value: 'day',
                label: t('day')
            },
            {
                value: 'week',
                label: t('week')
            },
            {
                value: 'month',
                label: t('month')
            },
            {
                value: 'quarter',
                label: t('quarter')
            },
            {
                value: 'variable',
                label: t('variable')
            }]
        },
        {
            key: 'processId',
            type: 'dropdown',
            label: t('process'),
            value: filObj?.processId || '',
            hideNoOptionSelect: true,
            options: [],
        },
        {
            key: 'onTime',
            type: 'dropdown',
            label: t('on-time'),
            value: filObj?.onTime || 'all',
            hideNoOptionSelect: true,
            options: [{
                value: 'all',
                label: t('all')
            },
            {
                value: 'on-time',
                label: t('archive-filter-on-time')
            }, {
                value: 'late',
                label: t('archive-filter-late')
            }],
        },
        { 
            type: 'search', 
            key: 'search', 
            label: t('search-for'), 
            value: filObj?.search || '',
        }
    ]
}
export {
    getArchiveFilter,
}