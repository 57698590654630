import React from 'react';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import Accordion from 'react-bootstrap/Accordion';
import { ButtonAtom } from '../../../../components/atoms/atom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import AttributesList from "./attribute/list/attributes-list";


const DragHandle = sortableHandle(() => <FontAwesomeIcon icon={faBars} style={{cursor: 'pointer' }} />);

const arrayMove = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};


const AttributeGroupSortableList = (props) => {
    const {
        selectedProcess, listItems, onListItemSortEnd, onEdit, onDelete,
        fetchProcessAttributes, activeAccordionId, setActiveAccordionId,
        attributeTypeList,
    } = props;

    const onSortEnd = ({oldIndex, newIndex}) => {
        onListItemSortEnd(arrayMove(listItems, oldIndex, newIndex));
    };

    const SortableItem = sortableElement(({key, item, index}) => {
        return <Accordion className="mt-4 process-accordian" defaultActiveKey={activeAccordionId} onSelect={(e) => {
            if (e) {
                setActiveAccordionId(item?.id);
            } else {
                setActiveAccordionId(null);
            }
        }}>
            <Accordion.Item eventKey={item?.id}>
                <Accordion.Header>
                    <DragHandle />
                    <div style={{ marginLeft: 10 }}>{item?.name} {key}</div>
                    <div>
                        <ButtonAtom 
                            className="btn btn-primary text-white mx-2"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                onEdit(item);
                            }}>
                            <FontAwesomeIcon icon={faEdit} />
                        </ButtonAtom>
                        <ButtonAtom 
                            className="btn btn-primary text-white"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                onDelete(item);
                            }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </ButtonAtom>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <AttributesList
                        selectedProcess={selectedProcess}
                        fetchProcessAttributes={fetchProcessAttributes}
                        processAttribute={item}
                        attributeTypeList={attributeTypeList}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    });
    

    const SortableList = sortableContainer(({items}) => {
        return (
            <div className='attr-group-sortable-container'>
                {items.map((item, index) => (
                    <SortableItem key={`item-${item?.id}`} index={index} item={item} />
                ))}
            </div>
        );
    });

    return <SortableList items={listItems} onSortEnd={onSortEnd} useDragHandle />;
}
export default AttributeGroupSortableList;