import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setting } from '../../store/services/index';
import { AuthContext } from '../../contexts/AuthProvider';

function RootOperator(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const navigate = useNavigate();

    const getUserInfo = async () => {
        let res = '';
        let role = auth?.role;
        let resources = auth?.resources;
        if (!role || !resources?.length) {
            res = await setting.getUserInfo();
            role = res.data.role;
            setAuth({ ...auth, role: res.data.role, name: res.data.name, resources: res.data.resources})
        }
        if (role !== 'WORKER') {
            localStorage.setItem('selectedMenu', 'order');
            navigate('/order/open');
            return;
        }
    };

    useEffect(() => {
        getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{props.children}</>

}

export default RootOperator;
