import CustomHeaderGroup from '../../components/atoms/header-group/header-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCirclePlay,
    faCircle,
    faCirclePause,
    faLock,
    faQuestion,
    faEdit,
    faCircleCheck,
    faCopy,
    faTrash,
    faArrowTrendUp,
    faSquareArrowUpRight,
    faPlus,
    faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import { getStatusIcon } from './utils/status';
import clone from 'rfdc/default';
import { getFormattedDate } from '../../utils/method';
import statusIcon from '../../assets/images/status.svg'
import iconPriorityHigh from '../../assets/images/priority/high.png';
import iconPriorityMedium from '../../assets/images/priority/medium.png';
import iconPriorityLow from '../../assets/images/priority/low.png';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HELP_MODES } from '../../utils/constant';

const getOperationTabs = (t) => {
    return [{
        label: t('planning'),
        key: 'planning'
    },
    {
        label: t('release'),
        key: 'release'
    }, 
    {
        label: t('implement'),
        key: 'implement'
    }]
};

const getPlanningFilter = (t, filObj = {}) => {
    return [
        {
            key: 'timePeriod',
            type: 'dropdown',
            label: t('period'),
            value: filObj?.timePeriod || 'month',
            hideNoOptionSelect: true,
            sortOptions: false,
            options: [{
                value: 'day',
                label: t('day')
            },
            {
                value: 'week',
                label: t('week')
            },
            {
                value: 'month',
                label: t('month')
            },
            {
                value: 'quarter',
                label: t('quarter')
            },
            {
                value: 'variable',
                label: t('variable')
            }]
        },
        {
            key: 'date',
            type: 'datepicker',
            label: t('date'),
            value: filObj?.date ? new Date(filObj?.date) : new Date(), 
            isDateRangePicker: false,
            endDate: null,
        },
        {
            key: 'status',
            type: 'dropdown',
            label: t('status'),
            hideNoOptionSelect: true,
            sortOptions: false,
            value: filObj?.status || 'all',
            options: [{
                value: 'all',
                label: t('status-all')
            }, {
                value: 'normal',
                label: t('status-normal')
            }, {
                value: 'under-clarification',
                label: t('status-under-clarification')
            }, {
                value: 'locked',
                label: t('status-locked')
            }, ]
        },
        {
            key: 'processId',
            type: 'dropdown',
            label: t('process'),
            value: filObj?.processId || '',
            hideNoOptionSelect: true,
            options: [],
        },
        {
            type: 'search',
            key: 'search',
            label: t('search-for'),
            value: filObj?.search || '',
        }
    ]
};

const getTableColumns = (t, selectedRow, onStatusClick, hideDateField = false, helpMode = HELP_MODES.BASIC) => {
    return [
        {
            checkboxSelection: true,
            headerCheckboxSelection: true,
            maxWidth: 50,
            cellRenderer: (props) => {
                const {id} = props.data;
                if (selectedRow.find((row) => row.id === id)) {
                    props.node.setSelected(true, false, true);
                }
            },
        },
        {
            field: 'status',
            headerName: t('status'),
            headerTooltip: t(`help-${helpMode}.status-column`),
            maxWidth: 55,
            cellStyle: {'backgroundColor': 'white'},
            headerComponentParams: {
                template:
                    `<div class="ag-cell-label-container" role="presentation">
                        <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                        <div ref="eLabel" class="ag-header-cell-label status-img-header" role="presentation">
                            <img src='${statusIcon}' alt='' />
                        </div>
                    </div>`
            },
            cellRenderer: (props) => {
                const { data } = props;
                if (!data) {
                    return <></>;
                }
                const {icon, title} = getStatusIcon(data, t);
                return <div className="status-icon">
                    <OverlayTrigger overlay={<Tooltip>{title}</Tooltip>}>
                        <img src={icon} alt='' style={{ height: '18px' }} onClick={() => onStatusClick(data)} />
                    </OverlayTrigger>
                </div>;
            },
        },
        {
            field: 'priority',
            headerName: t('priority'),
            headerTooltip: t(`help-${helpMode}.priority-column`),
            resizable: true,
            unSortIcon: true,
            maxWidth: 70,
            cellRenderer: (props) => {
                const {priority} = props.data;
                let icon = iconPriorityLow;
                let title = t('low');
                if (priority === 1) {
                    icon = iconPriorityHigh;
                    title = t('high');
                }
                if (priority === 2) {
                    icon = iconPriorityMedium;
                    title = t('medium');
                }
                return <OverlayTrigger overlay={helpMode === HELP_MODES.ADVANCE ? <Tooltip>{title}</Tooltip> : <></>}>
                    <img src={icon} alt='' style={{ height: '24px' }} />
                </OverlayTrigger>
            },
            cellStyle: () => {
                return {
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                }
            }
        },
        {
            field: 'source',
            headerName: t('source'),
            headerTooltip: t(`help-${helpMode}.source-column`),
            maxWidth: 70,
            unSortIcon: true,
            cellRenderer: (props) => {
                const {source} = props.data;
                let icon = faSquareArrowUpRight;
                let title = t(`help-${helpMode}.source-ext`);
                if (source === 'EXCEL') {
                    icon = faFileExcel;
                    title = t(`help-${helpMode}.source-excel`);
                }
                if (source === 'INTERFACE') {
                    icon = faPlus;
                    title = t(`help-${helpMode}.source-interface`);
                }
                return <OverlayTrigger overlay={helpMode === HELP_MODES.ADVANCE ? <Tooltip>{title}</Tooltip> : <></>}>
                    <FontAwesomeIcon icon={icon} className="fa-lg" />
                </OverlayTrigger>
            },
            cellStyle: () => {
                return {
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                }
            }
        },
        {
            field: 'createdAt',
            headerName: t('order-date'),
            headerTooltip: t('order-date'),
            hide: !hideDateField,
            resizable: true,
            unSortIcon: true,
            cellRenderer: (props) => {
                const {createdAt} = props.data;
                return getFormattedDate(createdAt, true);
            },
        },
        {
            headerName: t('order-process'),
            headerGroupComponent: CustomHeaderGroup,
            headerGroupComponentParams : {
                showExpandCollapseIcons: false
            },
            children: [
                {
                    field: 'next',
                    headerName: t('order-next'),
                    headerClass: 'header-margin-left',
                    unSortIcon: true,
                    comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
                        const {orderProcessSteps: orderProcessStepsNodeA} = nodeA.data;
                        const {orderProcessSteps: orderProcessStepsNodeB} = nodeB.data;
                        const processStepA = orderProcessStepsNodeA?.reverse().find((step) => step.status > 1) || {};
                        const processStepB = orderProcessStepsNodeB?.reverse().find((step) => step.status > 1) || {};
                        const resourceA = processStepA?.resource?.name;
                        const resourceB = processStepB?.resource?.name;
                        return (resourceA === resourceB) ? 0 : (resourceA > resourceB) ? 1 : -1;
                    },
                    cellStyle: (cell) => ({ borderLeft: '20px solid white', display: 'flex', alignItems: 'center' }),
                    cellRenderer: (props) => {
                        const processSteps = clone(props?.data?.orderProcessSteps).reverse().find((step) => step.status > 1) || {};
                        const icon = processSteps?.resource?.icon || '';
                        return <div className="d-flex ag-cell-next align-items-center">
                            {processSteps.status === 2 && <FontAwesomeIcon icon={faCircle} className="fa-xl icon-not-started" />}
                            {processSteps.status === 3 && <FontAwesomeIcon icon={faCirclePlay} className="fa-xl icon-play" />}
                            {processSteps.status === 4 && <FontAwesomeIcon icon={faCirclePause} className="fa-xl icon-pause" />}
                            {processSteps.status === 5 && <FontAwesomeIcon icon={faCircleCheck} className="fa-xl icon-done" />}
                            <img src={icon} alt='' style={{ height: '24px', marginLeft: 5 }} />
                            <div className="title-container">
                                <span className="title">{processSteps?.resource?.name}</span>
                                <span className="subtitle">{processSteps?.resource?.locationName}</span>
                            </div>
                        </div>;
                    },
                    minWidth: 150
                }
            ]
        }
    ]
};

const actionButton = [
    {
        key: 'edit',
        icon: faEdit,
        disabled: true,
        tooltip: 'edit-order'
    },
    {
        key: 'lock',
        icon: faLock,
        disabled: true,
        isLocked: true,
        tooltip: 'lock-order'
    },
    {
        key: 'clarification',
        icon: faQuestion,
        disabled: true,
        isUnderClarification: true,
        tooltip: 'order-under-clarification'
    },
    {
        key: 'select-priority',
        icon: faArrowTrendUp,
        disabled: true,
        selectPriority: true,
        tooltip: 'select-priority'
    },
];

const newCopyActionButton = [
    {
        key: 'copy',
        icon: faCopy,
        disabled: true,
        copyOrder: true,
        tooltip: 'copy-order'
    },
    {
        key: 'delete',
        icon: faTrash,
        disabled: true,
        copyOrder: true,
        tooltip: 'delete-order'
    },
]

const priorityOptions = [
    {
        label: 'high',
        value: 1
    },
    {
        label: 'medium',
        value: 2
    },
    {
        label: 'low',
        value: 3
    }
]

const getSalesOrderNumbersFromSelectedOrders = (selectedOrders) => {
    const localSelectedOrders = clone(selectedOrders);
    const salesOrderNumberArray = localSelectedOrders.map((selectedOrder) => {
        return selectedOrder.salesOrderNumber;
    })
    return salesOrderNumberArray.join(', ');
}

export {
    getOperationTabs,
    getPlanningFilter,
    getTableColumns,
    actionButton,
    newCopyActionButton,
    priorityOptions,
    getSalesOrderNumbersFromSelectedOrders,
}
