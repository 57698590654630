import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import { ModalAtom, FileUpload } from "../../components/atoms/atom";

function UploadExcelModal(props) {
    const { show, onClose, onSubmit, selectedProcessId } = props;
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileError, setFileError] = useState('');

    const onFileUpload = (file) => {
        setSelectedFile(file);
        setFileError('');
    }

    const uploadFile = () => {
        if (!selectedFile) {
            setFileError(t('error-upload-file'));
            return;
        }
        onSubmit(selectedFile, selectedProcessId);
    }
    
    return (
        <ModalAtom 
            title={t('upload-file')}
            show={show} 
            handleClose={onClose}
            handleSubmit={uploadFile}
            saveText={t('upload')}
            body={<Container>
                <div className='body-inner'>
                    <span>
                        <FileUpload
                            label={t('choose-excel-file')}
                            excelFile={selectedFile}
                            error={fileError}
                            onExcelUpload={onFileUpload}
                        />
                    </span>
                </div>
            </Container>}
        />
    );
}

export default UploadExcelModal;
