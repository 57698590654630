import React, { useContext, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import dayjs from 'dayjs';
import { ButtonAtom, ModalAtom, TextFieldAtom, DatePickerAtom, DropdownAtom, TextAreaAtom, CheckboxAtom } from "../../../components/atoms/atom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck, faCircleInfo, faCirclePause, faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import '../detail/detail.scss';
import { AuthContext } from '../../../contexts/AuthProvider';
import { setting } from '../../../store/services/index';
import clone from 'rfdc/default';
import { HELP_MODES } from "../../../utils/constant";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function NewOrderModal(props) {
    const { show, onClose, onSubmit, attributeGroups, onAddProcessStepClick, onClickInfo, newOrderData, setNewOrderData, processSteps, onClickSaveAsTemplate } = props;
    const { t } = useTranslation();
    const { auth, setAuth, helpMode } = useContext(AuthContext);

    const getUserInfo = async () => {
        let res = '';
        let role = auth?.role;
        if (!role) {
            res = await setting.getUserInfo();
            role = res.data.role;
            setAuth({ ...auth, role: res.data.role, name: res.data.name })
        }
    };
    
    useEffect(() => {
        getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newOrderData]);

    const getValue = (attribute) => {
        let value = '';
        if (newOrderData?.orderInfo) {
            value = newOrderData?.orderInfo[attribute.id];
            const attributeFormat = attribute?.attributeFormat?.name;
            if (['DATE', 'DATETIME'].includes(attributeFormat)) {
                return value ? new Date(value) : '';
            }
            if (attributeFormat === 'CHECKBOX') {
                return !!+value;
            }
        }
        return value || '';
    }

    const updateValue = (id, fieldValue) => {
        let localOrderCopy = clone(newOrderData);
        localOrderCopy = { ...localOrderCopy, orderInfo: { ...localOrderCopy?.orderInfo, [id]: fieldValue }}
        setNewOrderData(localOrderCopy);
    }

    const getEditableField = (attr) => {
        let value = getValue(attr);
        const additionalClass = !value && attr?.isRequired ? 'error' : '';
        const isDisabled = !isAttributeEditable(attr);
        const attributeType = attr?.attributeType?.name;
        const attributeFormat = attr?.attributeFormat?.name;
        if (attributeType === 'INPUT') {
            if (attributeFormat === 'DATE') {
                return <div className='body-inner'>
                    <span className={additionalClass}>{attr.label}</span><span>
                        <DatePickerAtom
                            className={`form-control ${additionalClass}`}
                            value={value}
                            disabled={isDisabled}
                            onChange={(e) => {
                                const value = dayjs(e).format('YYYY-MM-DDTHH:mm:ssZ');
                                updateValue(attr.id, value);
                            }} /></span>
                </div>
            }
            if (['TEXT', 'NUMBER'].includes(attributeFormat)) {
                return <div className='body-inner'>
                    <span className={additionalClass}>{attr.label}</span><span>
                        <TextFieldAtom
                            className={`form-control ${additionalClass}`}
                            value={value}
                            disabled={isDisabled}
                            type={attributeFormat === 'NUMBER' ? 'number' : 'text'}
                            onChange={(e) => {
                                updateValue(attr.id, e.target.value);
                            }} /></span>
                </div>
            }
            if (attributeFormat === 'TEXTAREA') {
                return <div className='body-inner'>
                    <span className={additionalClass}>{attr.label}</span><span>
                        <TextAreaAtom
                            className={`form-control ${additionalClass}`}
                            value={value}
                            disabled={isDisabled}
                            onChange={(e) => {
                                updateValue(attr.id, e.target.value);
                            }} /></span>
                </div>
            }
            if (attributeFormat === 'DATETIME') {
                return <div className='body-inner'>
                    <span className={additionalClass}>{attr.label}</span><span>
                        <DatePickerAtom
                            className={`form-control ${additionalClass}`}
                            todayButton={t('today')}
                            value={value}
                            disabled={isDisabled}
                            onChange={(e) => {
                                const value = dayjs(e).format('YYYY-MM-DDTHH:mm:ssZ');
                                updateValue(attr.id, value);
                            }}
                            dateFormat="dd.MM.yyyy h:mm aa"
                            timeInputLabel={`${t('time')}:`}
                            showTimeInput
                        /></span>
                </div>
            }
            if (attributeFormat === 'CHECKBOX') {
                return <div className='body-inner'>
                    <span className={additionalClass}>{attr.label}</span><span>
                        <CheckboxAtom
                            disabled={isDisabled}
                            checked={value}
                            onChange={(evt) => updateValue(attr.id, evt.target.checked)} /></span>
                </div>
            }
        }
        if (attributeType === 'SELECT') {
            const options = attr?.attributeOptions?.split(',').map((opt) => {
                return {
                    label: opt?.trim(),
                    value: opt?.trim()
                }
            })
            return <div className='body-inner'>
                <span className={additionalClass}>{attr.label}</span><span>
                    <DropdownAtom
                        className={`form-control ${additionalClass}`}
                        value={value}
                        disabled={isDisabled}
                        options={options}
                        onChange={(e) => updateValue(attr.id, e.target.value)}
                    />
                </span>
            </div>
        }
    }

    const getResourceDetail = (orderStep) => {
        const { processStepId, resourceId } = orderStep;
        const step = processSteps.find((processStep) => {
            return processStep.id === processStepId
        })
        const resource = step?.resources?.find((resource) => {
            return resourceId === resource.id
        })
        return <>
            <span className='process-content'>
                <img src={step.icon} alt='' style={{ height: '24px' }} />
                <div className="title-container">
                    <span className="title">{resource?.name}</span>
                    <span className="subtitle">{resource?.locationName}</span>
                </div>
            </span>
            <span className='process-content'>{step?.label}</span>  
        </>
    }

    const processStepsContent = () => {
        const enabledInfoButton = newOrderData?.processSteps?.length > 0;
        return <>
            <div className='body-inner-title'>PROZESS&nbsp;
                {helpMode === HELP_MODES.ADVANCE && <OverlayTrigger overlay={<Tooltip>
                    <Trans i18nKey="help-advance.order-detail-process">
                        <FontAwesomeIcon icon={faCirclePlay} className="icon-play" />Starten<br/>
                        <FontAwesomeIcon icon={faCirclePause} className="icon-pause" /> Pausieren<br />
                        <FontAwesomeIcon icon={faCircleCheck} className="icon-done" /> Fertigmelden<br />
                        <FontAwesomeIcon icon={faCircle} className="icon-not-started" /> zu vorherigem Prozessschritt
                    </Trans>
                </Tooltip>}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                </OverlayTrigger>}
            </div>
            <div className="modal-inner-wrapper process-step-list-wrapper">
                {
                    newOrderData?.processSteps.map((orderStep) => {
                        return <div className='body-inner' key={orderStep?.processStepId}>
                            <span className='process-content'>
                                <FontAwesomeIcon icon={faCircle} className="icon-not-started" />
                            </span>
                            {getResourceDetail(orderStep)}
                        </div>
                    })
                }
            </div>
            <div className="process-btn-container">
                <div>
                    <FontAwesomeIcon icon={faCircleInfo} className={enabledInfoButton ? '' : 'icon-not-started'} />
                    <u className={`ms-2 ${enabledInfoButton ? 'text-dark' : 'text-muted'}`} role="button" onClick={enabledInfoButton && onClickInfo} title={!enabledInfoButton && t('info-select-process-steps')}>Info</u>
                </div>
                <ButtonAtom onClick={onAddProcessStepClick} disabled={auth.role === 'SALES'}>{t('add-process-step')}</ButtonAtom>
            </div>
        </>
    };

    const getProcessSteps = () => {
        return processStepsContent();
    };

    const getSortedAttributes = (attributeGroup) => {
        return attributeGroup.attributes?.sort(
            ({ isRequired: isRequiredA = false }, { isRequired: isRequiredB = false }) => Number(isRequiredB) - Number(isRequiredA)
        );
    }

    const isAttributeEditable = (att) => att.isChangeableAV && ['AV', 'ADMIN', 'SUPER_ADMIN'].includes(auth?.role);

    return (
        <ModalAtom
            title={t('new-order-modal-title')}
            customClassName='detail-modal'
            show={show}
            handleClose={onClose}
            handleSubmit={onSubmit}
            showMiddleButton
            middleButtonText={'save-as-template'}
            middleButtonTitle={t(`help-${helpMode}.save-as-template`)}
            onMiddleButtonAction={onClickSaveAsTemplate}
            body={<Container>
                <div className='modal-body-wrapper'>
                    {attributeGroups?.map((attributeGroup) => {
                        return <div className='modal-body-content' key={attributeGroup?.id}>
                            <div className="body-inner-title">{attributeGroup?.name}</div>
                            <div className="modal-inner-wrapper">
                                {getSortedAttributes(attributeGroup).map((attr) => {
                                    return getEditableField(attr);
                                })}
                            </div>
                        </div>
                    })}
                    <div className='modal-body-content'>
                        {getProcessSteps()}
                    </div>
                </div>
            </Container>}
        />
    );
}

export default NewOrderModal;
