import React, { useState, useEffect, useRef, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { TextFieldAtom, ModalAtom, MultiSelectAtom, IconUpload, AgGridAtom } from "../../../../../components/atoms/atom";
import { getProcessStepAddAttributeTableColumns } from './constant';
import './add-process-step.scss';
import { EXTERNAL_SYSTEM_IDENTIFIER_REGEX, HELP_MODES } from "../../../../../utils/constant";
import { AuthContext } from "../../../../../contexts/AuthProvider";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const getNewAttribute = (position) => ({
    position,
    attributeId: null,
    isMandatory: {
        disabled: false,
        value: false,
    },
    isVisibleCollapsed: {
        disabled: false,
        value: false,
    },
    isVisibleExpanded: {
        disabled: false,
        value: false,
    },
    isVisibleDetail: {
        disabled: false,
        value: false,
    },
});

const mapAttributeDataToUI = (attributes) => {
    if (!attributes || !attributes.length) {
        return null;
    }
    const clonedAttributes = [...attributes];
    const x = clonedAttributes.map((obj) => {
        const isSwitching = obj.isSwitching;
        for (const key in obj) {
            if (['isSwitching', 'isMandatory', 'isVisibleSticky', 'isVisibleDesktop', 'isVisibleCollapsed',
                'isVisibleExpanded', 'isVisibleDetail', 'isVisibleHistory'].includes(key)
                && typeof obj[key] === 'boolean'
            ) {
                obj[key] = {
                    disabled: (key === 'isSwitching' || isSwitching) ? true : false,
                    value: obj[key],
                };
            }
        }
        return obj;
    });
    return x;
};

function AddProcessStepModal(props) {
    const childRef = useRef();
    const { show, onClose, onSubmit, mode, processStepInfo = {}, attributeList = [], resourceList = [], newProcessStepPosition = 1 } = props;
    const { t } = useTranslation();
    const { helpMode } = useContext(AuthContext);
    const [position, setPosition] = useState(
        (processStepInfo?.position === null || processStepInfo?.position === undefined) ? newProcessStepPosition : processStepInfo?.position
    );
    const [externalSystemIdentifier, setExternalSystemIdentifier] = useState(processStepInfo?.externalSystemIdentifier || '');
    const [description, setDescription] = useState(processStepInfo?.description || '');
    const [label, setLabel] = useState(processStepInfo?.label || '');
    const [resource, setResource] = useState(processStepInfo?.resource?.sort((rA, rB) => {
        let resA = rA.label.toLowerCase();
        let resB = rB.label.toLowerCase();
        if (resA < resB) {
            return -1;
        }
        if (resA > resB) {
            return 1;
        }
        return 0
    }) || []);
    const [iconFile, setIconFile] = useState(processStepInfo?.icon || null);
    const [iconPreview, setIconPreview] = useState(processStepInfo?.icon || null);
    const [resourceOptions, setResourceOptions] = useState([]);
    const [attributeOptions, setAttributeOptions] = useState({});
    const [errorObj, setErrorObj] = useState({
        position: '',
        description: '',
        label: '',
        attribute: '',
        resource: '',
        iconFile: '',
        externalSystemIdentifier: '',
    });
    const [processAttributes, setProcessAttributes] = useState(mapAttributeDataToUI(processStepInfo?.attributes) || []);

    const onDeleteAttribute = (row, rowIndex) => {
        const currentProcessAttributes = [...processAttributes];
        currentProcessAttributes.splice(rowIndex, 1);

        currentProcessAttributes.forEach((pa, index) => pa.position = index + 1);
        setProcessAttributes(currentProcessAttributes);
    };

    const attributeTableColumns = getProcessStepAddAttributeTableColumns(t, attributeOptions, onDeleteAttribute);

    const validatePosition = (positionValue) => {
        if (positionValue === 0) {
            return 'error-process-step-position-validation';
        }
        if (!positionValue) {
            return 'error-process-step-position-required';
        }
        if (positionValue > newProcessStepPosition) {
            return 'error-process-step-position-greater-than-error';
        }
        return '';
    };

    const onAddProcessStep = () => {
        let localErrorObj = {};
        const positionValidationError = validatePosition(position);
        if (positionValidationError) {
            localErrorObj.position = positionValidationError;
        }
        if (!description) { 
            localErrorObj.description = 'error-process-step-description-required';
        }
        if (!label) {
            localErrorObj.label = 'error-process-step-label-required';
        }
        if (!resource.length) {
            localErrorObj.resource =  'error-resource-required';
        }
        if (!iconFile) {
            localErrorObj.iconFile =  'error-icon-required';
        }
        if (processAttributes.some(at => !at.attributeId)) {
            localErrorObj.attribute = 'error-process-step-attribute-required';
        }
        if (!externalSystemIdentifier) {
            localErrorObj.externalSystemIdentifier = 'error-external-system-identifier-required';
        }
        setErrorObj({...localErrorObj});
        const hasError = Object.keys(localErrorObj).length ;
        if (!hasError){
            const getIds = (inputArray) => {
                const resultantId = [];
                inputArray.forEach((option) => resultantId.push(option.value));
                return resultantId;
            }
            const attributes = processAttributes.map((at) => ({
                ...at,
                isMandatory: at?.isMandatory?.value || false,
                isVisibleCollapsed: at?.isVisibleCollapsed?.value || false,
                isVisibleExpanded: at?.isVisibleExpanded?.value || false,
                isVisibleDetail: at?.isVisibleDetail?.value || false,
            }));
            onSubmit({
                ...processStepInfo,
                position,
                description,
                label,
                externalSystemIdentifier,
                attributes,
                resource: getIds(resource),
                iconFile
            })
        }       
    };

    const onFileUpload = (file) => {
        setIconFile(file);
        const objectUrl = URL.createObjectURL(file);
        setIconPreview(objectUrl);
        setErrorObj({
            ...errorObj, 
            logoFile: ''
        });
    }

    useEffect(() => {
        setResourceOptions(resourceList.map((resource) => { 
            return {
                label: resource.name,
                value: resource.id,
            }
        }));

        const attributesDisplayOptions = attributeList.reduce((arr, obj) => {
            if (obj.attributes) {
                obj.attributes.forEach((a) => {
                    arr[a.id] = `${obj.name} (${a.label})`;
                });
            }
            return arr;
        }, {});
        setAttributeOptions(attributesDisplayOptions);

        // childRef.current.pinLastRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPosition = () => {
        if (!processAttributes.length) {
            return 1;
        }
        const positions = processAttributes.map(attr => attr.position); 
        return Math.max(...positions) + 1;
    };

    const addNewAttribute = () => {
        const newAttribute = getNewAttribute(getPosition());
        const currentProcessAttributes = [...processAttributes, newAttribute];
        currentProcessAttributes.forEach((pa, index) => pa.position = index + 1);
        setProcessAttributes(currentProcessAttributes);
    };

    return (
        <ModalAtom 
            title={t(mode === 'add' ? 'add-process-step' : 'edit-process-step')}
            show={show} 
            handleClose={onClose}
            handleSubmit={onAddProcessStep}
            body={ <Container>
                <Row>
                    <Col>
                        <TextFieldAtom 
                            type="number"
                            placeholder={t('position')}
                            value={position}
                            required
                            error={errorObj.position}
                            onChange={(e) => {
                                const position = parseInt(e.target.value);
                                const positionValidationError = validatePosition(position);
                                setPosition(position);
                                setErrorObj({...errorObj, 
                                    position: positionValidationError
                                });
                            }}
                            errorTextPlaceholder={{position: newProcessStepPosition}}
                        />
                    </Col>
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            placeholder={t('external-system-identifier')}
                            required
                            value={externalSystemIdentifier}
                            error={errorObj.externalSystemIdentifier}
                            onChange={(e) => {
                                if (e.target.value.match(EXTERNAL_SYSTEM_IDENTIFIER_REGEX)) {
                                    setExternalSystemIdentifier(e.target.value.toUpperCase());
                                    setErrorObj({...errorObj, 
                                        externalSystemIdentifier: e.target.value ? '' : 'error-external-system-identifier-required'
                                    })
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            placeholder={t('description')}
                            value={description}
                            required
                            error={errorObj.description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                setErrorObj({...errorObj, 
                                    description: e.target.value ? '' : 'error-process-step-description-required'
                                })
                            }}
                        />
                    </Col>
                    <Col>
                        <TextFieldAtom 
                            type="text"
                            placeholder={t('label')}
                            value={label}
                            required
                            error={errorObj.label}
                            onChange={(e) => {
                                setLabel(e.target.value);
                                setErrorObj({...errorObj, 
                                    label: e.target.value ? '' : 'error-process-step-label-required'
                                })
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <MultiSelectAtom
                            selectedOption={resource}
                            placeholder={t('select-resource')}
                            options={resourceOptions}
                            error={errorObj.resource}
                            required
                            onChange={(e) => {
                                setResource(e);
                                setErrorObj({ ...errorObj, resource: e && e.length ? '' : 'error-resource-required' })
                            }}
                        />
                    </Col>
                    <Col>
                        <IconUpload 
                            label={t('icon')}
                            iconFile={iconFile}
                            iconPreview={iconPreview}
                            error={errorObj.iconFile}
                            onIconUpload={onFileUpload}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <strong>{t('attributes')}</strong>
                        <Button className="mx-2 text-white btn btn-primary" onClick={addNewAttribute}>
                            {t('add-attribute')}
                        </Button>
                        {helpMode === HELP_MODES.ADVANCE && <OverlayTrigger overlay={<Tooltip>
                            <Trans i18nKey="help-advance.process-step-add-attribute">
                            Über Attribut hinzufügen wird bestimmt, welche Attribute bei dem gewählten Prozessschritt angezeigt werden, und wie sie angezeigt werden. Pflichtfeld: die Eingabe der Information ist zwingend, um den Prozesschritt fertig zu melden.<br />C: in eingeklappter Auftragsinformation des Werkers sichtbar<br />E: in ausgeklappter Auftragsinformation des Werker sichtbar<br />D: Zeilenweise Auflistung der Attribute in Auftragsansicht
                            </Trans>
                        </Tooltip>}>
                            <FontAwesomeIcon icon={faCircleInfo} />
                        </OverlayTrigger>}
                        {errorObj.attribute ? <p className="text-danger" style={{display: 'inline-block'}}>{t(errorObj.attribute)}</p> : ''}
                    </Col>
                </Row>
                <Row  className="process-step-attribute-list">
                    <AgGridAtom
                        ref={childRef}
                        columnDefs={attributeTableColumns}
                        rowData={processAttributes}
                        gridHeight='40vh'
                        updateRowOrder={(updatedProcessAttributes) => setProcessAttributes(updatedProcessAttributes)}
                        rowDragManaged={true}
                    />
                </Row>
            </Container>}
        />
    );
}

export default AddProcessStepModal;
