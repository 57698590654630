import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import dayjs from 'dayjs';
import { ButtonAtom, ModalAtom, TextFieldAtom, DatePickerAtom, DropdownAtom, TextAreaAtom, CheckboxAtom } from "../../../components/atoms/atom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleInfo, faCirclePlay, faCirclePause, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import './detail.scss';
import { AuthContext } from '../../../contexts/AuthProvider';
import { setting } from '../../../store/services/index';
import { formatValueToLocale, getFormattedDate, showError } from "../../../utils/method";
import Dropdown from "react-bootstrap/Dropdown";
import clone from 'rfdc/default';
import { statusOptions } from './constant';
import { HELP_MODES } from "../../../utils/constant";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function DetailModal(props) {
    const { show, onClose, onSubmit, attributeGroups, selectedOrders, onAddProcessStepClick, hideFooter = false, orderType = '', onClickInfo, socketId, updateLocalProcessStep } = props;
    const clonedAttributeGroup = clone(attributeGroups);
    const { t } = useTranslation();
    const { auth, setAuth, helpMode } = useContext(AuthContext);
    const [updatedInfo, setUpdatedInfo] = useState([]);

    const getUserInfo = async () => {
        let res = '';
        let role = auth?.role;
        if (!role) {
            res = await setting.getUserInfo();
            role = res.data.role;
            setAuth({ ...auth, role: res.data.role, name: res.data.name })
        }
        initializeDynamicValue();
    };

    const mapStatusOptions = (statusNoArray) => {
        const mappedStatus = statusNoArray.map((val) => {
            return statusOptions.find((st) => st.status === val) || {};
        });
        return mappedStatus;
    }

    const getStatusOptionsInfo = () => {
        const existProcessSteps = clone(selectedOrders[0].orderProcessSteps);
        const isStartedPause = existProcessSteps.find((step) => step.status === 3 || step.status === 4);
        let resultObj = {};
        let options = [];
        let optionsVisibleStepId = '';

        if (isStartedPause) {
            options = isStartedPause.status === 3 ? mapStatusOptions([2, 4, 5]) : mapStatusOptions([2, 3, 5]);
            optionsVisibleStepId = isStartedPause?.id || '';

        } else {
            const lastDoneAvailabelStep = clone(existProcessSteps).reverse().find((step) => step.status === 2 || step.status === 5);
            if (lastDoneAvailabelStep?.status === 2) {
                options = mapStatusOptions([3, 4, 5]);
                const visibleIndex = existProcessSteps.findIndex((step) => step.id === lastDoneAvailabelStep.id);
                if (visibleIndex > 0) {
                    resultObj.secondaryOptions = mapStatusOptions([2, 3, 4]);
                    resultObj.secondaryOptionsVisbleStepId = existProcessSteps[visibleIndex-1].id;
                }
            }
            if (lastDoneAvailabelStep?.status === 5) 
                options = mapStatusOptions([2, 3, 4]);

            optionsVisibleStepId = lastDoneAvailabelStep?.id || '';
        }
        return { ...resultObj, options, optionsVisibleStepId }
    }

    const isVisible = (fieldName) => {
        if (selectedOrders.length === 1) {
            return true;
        }
        if (selectedOrders.length > 1) {
            if (selectedOrders[0]?.missingValues?.length) {
                if (selectedOrders[0].missingValues.includes(fieldName)) {
                    return true;
                }
                return false;
            } else {
                const isSame = selectedOrders.every((ord) => {
                    return ord.orderInfo[fieldName].value === selectedOrders[0].orderInfo[fieldName].value;
                });
                return isSame;
            }
        }
    };

    const initializeDynamicValue = () => {
        const initialArray = [];
        clonedAttributeGroup.forEach((attributeGroup) => {
            attributeGroup.attributes.forEach((attribute) => {
                if (attribute.isChangeableAV && ['AV', 'ADMIN', 'SUPER_ADMIN'].includes(auth?.role) && isVisible(attribute.id)) {
                    if (['DATE', 'DATETIME'].includes(attribute?.attributeFormat?.name)) {
                        const val = selectedOrders[0]?.orderInfo[attribute.id]?.value;
                        initialArray.push({
                            isUpdated: false,
                            id: attribute.id,
                            value: new Date(val),
                            attributeFormat: attribute?.attributeFormat?.name
                        });

                    } else if (attribute?.attributeFormat?.name === 'CHECKBOX') {
                        const val = selectedOrders[0]?.orderInfo[attribute.id]?.value;
                        initialArray.push({
                            isUpdated: false,
                            id: attribute.id,
                            value: !!+val,
                            attributeFormat: attribute?.attributeFormat?.name
                        });

                    } else {
                        initialArray.push({
                            isUpdated: false,
                            id: attribute.id,
                            value: selectedOrders[0]?.orderInfo[attribute.id].value,
                            attributeFormat: attribute?.attributeFormat?.name
                        });
                    }
                }
            });
        });
        setUpdatedInfo(initialArray);
    };
    
    useEffect(() => {
        getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrders]);

    const onSubmitClick = () => {
        onSubmit(updatedInfo);
    }

    const getValue = (fieldId) => {
        const selectedField = updatedInfo.find((row) => {
            return row?.id === fieldId;
        });
        return selectedField?.value;
    }

    const updateValue = (fieldId, fieldValue) => {
        const fieldIndex = updatedInfo.findIndex((row) => {
            return row?.id === fieldId;
        });
        const localUpdatedInfo = [...updatedInfo];
        localUpdatedInfo[fieldIndex] = { ...localUpdatedInfo[fieldIndex], value: fieldValue, isUpdated: true };
        setUpdatedInfo(localUpdatedInfo);
    }

    const getEditableField = (attr) => {
        const value = getValue(attr.id);
        const additionalClass = !value && attr?.isRequired ? 'error' : '';
        const attributeType = attr?.attributeType?.name;
        const attributeFormat = attr?.attributeFormat?.name;
        if (attributeType === 'INPUT') {
            if (attributeFormat === 'DATE') {
                return <div className='body-inner'>
                    <span className={additionalClass}>{attr.label}</span><span>
                        <DatePickerAtom
                            className={`form-control ${additionalClass}`}
                            value={value}
                            onChange={(e) => {
                                const value = dayjs(e).toDate();
                                updateValue(attr.id, value);
                            }} /></span>
                </div>


            }
            if (attributeFormat === 'TEXT' || attributeFormat === 'NUMBER') {
                return <div className='body-inner'>
                    <span className={additionalClass}>{attr.label}</span><span>
                        <TextFieldAtom
                            className={`form-control ${additionalClass}`}
                            value={value}
                            type={attributeFormat === 'NUMBER' ? 'number' : 'text'}
                            onChange={(e) => {
                                updateValue(attr.id, e.target.value);
                            }} /></span>
                </div>
            }
            if (attributeFormat === "TEXTAREA") {
                return <div className='body-inner'>
                    <span className={additionalClass}>{attr.label}</span><span>
                        <TextAreaAtom
                            className={`form-control ${additionalClass}`}
                            value={value}
                            onChange={(e) => {
                                updateValue(attr.id, e.target.value);
                            }} /></span>
                </div>
            }
            if (attributeFormat === 'DATETIME') {
                return <div className='body-inner'>
                    <span className={additionalClass}>{attr.label}</span><span>
                        <DatePickerAtom
                            className={`form-control ${additionalClass}`}
                            todayButton={t('today')}
                            value={value}
                            onChange={(e) => {
                                const value = dayjs(e).toDate();
                                updateValue(attr.id, value);
                            }}
                            dateFormat="dd.MM.yyyy h:mm aa"
                            timeInputLabel={`${t('time')}:`}
                            showTimeInput
                        /></span>
                </div>
            }
            if (attributeFormat === "CHECKBOX") {
                return <div className='body-inner'>
                    <span className={additionalClass}>{attr.label}</span><span>
                        <CheckboxAtom
                            // label={t('changeable_av')}
                            checked={value}
                            onChange={(evt) => updateValue(attr.id, evt.target.checked)} /></span>
                </div>
            }
        }
        if (attributeType === 'SELECT') {
            const options = attr?.attributeOptions?.split(',').map((opt) => {
                return {
                    label: opt?.trim(),
                    value: opt?.trim()
                }
            })
            return <div className='body-inner'>
                <span className={additionalClass}>{attr.label}</span><span>
                    <DropdownAtom
                        className={`form-control ${additionalClass}`}
                        value={value}
                        options={options}
                        onChange={(e) => updateValue(attr.id, e.target.value)}
                    />
                </span>
            </div>
        }
    }
    
    const updateProcessStepStatus = async (info) => {
        try {
            const res = await setting.updateProcessStepStatus(selectedOrders[0]?.id, {
                orderProcessStepId: info.stepId,
                status: info.value,
                resourceId: info.resourceId,
                oldStatus: info.oldStatus,
                socketId: socketId,
                processStepId: info.processStepId,
            });
            updateLocalProcessStep(res.data);
        } catch (error) {
            showError(t, error);
        }
    }

    const getStatusDropDown = (dropDownInfo) => {
        const { options, stepId, resourceId, oldStatus, processStepId } = dropDownInfo;
        return  <Dropdown className="status-dropdown">
            <Dropdown.Toggle disabled={auth.role === 'SALES'} variant="success" id="dropdown-basic" />
            <Dropdown.Menu>
                {options.map((opt) => <Dropdown.Item 
                    value={opt.value}
                    onClick={((e) => updateProcessStepStatus({ value: opt.value, stepId, resourceId, oldStatus, processStepId }))}>{opt.label}</Dropdown.Item>)}
            </Dropdown.Menu>
        </Dropdown>
    };

    const showStatusDropDown = (orderStep) => {
        // In place of finding the resourceId and oldStatus from orderStep, get it from 'getStatusOptionsInfo()'.
        const stepId = orderStep.id;
        const resourceId = orderStep.resource.id;
        const processStepId = orderStep.processStep.id;
        const oldStatus = orderStep.status;
        const { options, optionsVisibleStepId, secondaryOptionsVisbleStepId = '', secondaryOptions = [] } = getStatusOptionsInfo();

        if (optionsVisibleStepId === stepId)
            return getStatusDropDown({ options, stepId: optionsVisibleStepId, resourceId, oldStatus, processStepId });
        
        if (secondaryOptionsVisbleStepId === stepId)
            return getStatusDropDown({ options : secondaryOptions, stepId: secondaryOptionsVisbleStepId, resourceId, oldStatus, processStepId });
        
        return '';

    };

    const processStepsContent = () => <>
        <div className='body-inner-title'>PROZESS&nbsp;
            {helpMode === HELP_MODES.ADVANCE && <OverlayTrigger overlay={<Tooltip>
                <Trans i18nKey="help-advance.order-detail-process">
                    <FontAwesomeIcon icon={faCirclePlay} className="icon-play" /> Starten<br/>
                    <FontAwesomeIcon icon={faCirclePause} className="icon-pause" /> Pausieren<br />
                    <FontAwesomeIcon icon={faCircleCheck} className="icon-done" /> Fertigmelden<br />
                    <FontAwesomeIcon icon={faCircle} className="icon-not-started" /> zu vorherigem Prozessschritt
                </Trans>
            </Tooltip>}>
                <FontAwesomeIcon icon={faCircleInfo} />
            </OverlayTrigger>}
        </div>
        <div className="modal-inner-wrapper process-step-list-wrapper">
            {
                selectedOrders[0].orderProcessSteps.map((orderStep) => {
                    return <div className='body-inner'>
                        <span className='process-content'>
                            {(orderStep.status === 1 || orderStep.status === 2) && <FontAwesomeIcon icon={faCircle} className="icon-not-started" />}
                            {orderStep.status === 3 && <FontAwesomeIcon icon={faCirclePlay} className="icon-play" />}
                            {orderStep.status === 4 && <FontAwesomeIcon icon={faCirclePause} className="icon-pause" />}
                            {orderStep.status === 5 && <FontAwesomeIcon icon={faCircleCheck} className="icon-done" />}
                            {[3, 4].includes(selectedOrders[0].orderStatus) && !selectedOrders[0].isLocked ? showStatusDropDown(orderStep) : ''}
                        </span>
                        <span className='process-content'>
                            <img src={orderStep?.resource?.icon} alt='' style={{ height: '24px' }} />
                            <div className="title-container">
                                <span className="title">{orderStep?.resource?.name}</span>
                                <span className="subtitle">{orderStep?.resource?.locationName}</span>
                            </div>
                        </span>
                        <span className='process-content'>{orderStep?.processStep?.label}</span>
                    </div>
                })
            }
        </div>
        <div className="process-btn-container">
            {selectedOrders.length === 1 && <div>
                <FontAwesomeIcon icon={faCircleInfo} />
                <u className="ms-2 text-dark" role="button" onClick={onClickInfo}>Info</u>
            </div>}
            <ButtonAtom onClick={onAddProcessStepClick} disabled={auth.role === 'SALES'}>{t('order-detail-change-process')}</ButtonAtom>
        </div>
    </>;

    const getProcessSteps = () => {
        const count = selectedOrders?.length;
        if (count) {
            if (count === 1) {
                return processStepsContent();
            } else {
                const selectedProcessResourceId = selectedOrders[0].selectedProcessResourceId;
                const hasSimilarProcessSteps = selectedOrders.every((so) => JSON.stringify(so.selectedProcessResourceId) === JSON.stringify(selectedProcessResourceId));
                if (hasSimilarProcessSteps) {
                    return processStepsContent();
                }
            }
        }
    };

    const getSortedAttributes = (attributeGroup) => {
        return attributeGroup.attributes?.sort(
            ({ isRequired: isRequiredA = false }, { isRequired: isRequiredB = false }) => Number(isRequiredB) - Number(isRequiredA)
        );
    }

    const getModalTitle = () => {
        return `${t('detail')} (${selectedOrders.map((so) => so.salesOrderNumber).join(', ')})`;
    }

    const isAttributeEditable = (att) => att.isChangeableAV && ['AV', 'ADMIN', 'SUPER_ADMIN'].includes(auth?.role) && isVisible(att.id);

    return (
        <ModalAtom
            title={getModalTitle()}
            customClassName='detail-modal'
            show={show}
            handleClose={onClose}
            handleSubmit={onSubmitClick}
            hideCloseButton={orderType !== 'planning'}
            saveText={orderType !== 'planning' ? "close" : "save"}
            body={<Container>
                <div className='modal-body-wrapper'>
                    {clonedAttributeGroup?.map((attributeGroup) => {
                        let showAttributeGroup = false;
                        if (selectedOrders.length > 1) {
                            showAttributeGroup = attributeGroup?.attributes?.findIndex(isAttributeEditable) > -1;
                        } else {
                            showAttributeGroup = true;
                        }
                        return showAttributeGroup && <div className='modal-body-content'>
                            <div className="body-inner-title">{attributeGroup?.name}</div>
                            <div className="modal-inner-wrapper">
                                {getSortedAttributes(attributeGroup).map((attr) => {
                                    if ( orderType === 'planning' && isAttributeEditable(attr)) {
                                        return getEditableField(attr);
                                    }
                                    if (selectedOrders.length > 1) {
                                        return <></>;
                                    }
                                    let value = selectedOrders[0]?.orderInfo[attr.id].value;
                                    if (attr?.attributeType?.name === 'INPUT') {
                                        if (attr?.attributeFormat?.name === 'DATE') {
                                            value = getFormattedDate(value);
                                        }
                                        if (attr?.attributeFormat?.name === 'DATETIME') {
                                            value = getFormattedDate(value, true);
                                        }
                                        if (attr?.attributeFormat?.name === 'NUMBER') {
                                            if (attr.externalSystemIdentifier === 'WEIGHT_P') {
                                                value = formatValueToLocale(value, false, 3)
                                            } else {
                                                value = value || '';
                                            }
                                        }
                                    }
                                    return <div className='body-inner'><span>{attr.label}</span><span>{value}</span></div>
                                })}
                            </div>
                        </div>
                    })}
                    <div className='modal-body-content'>
                        {getProcessSteps()}
                    </div>
                </div>
            </Container>}
            footer={!hideFooter ? <div>
                <ButtonAtom className="gray-btn">{t('paused')}</ButtonAtom>
                <ButtonAtom className="gray-btn">{t('abort')}</ButtonAtom>
                <ButtonAtom>{t('finished')}</ButtonAtom>
            </div> : ''}
        />
    );
}

export default DetailModal;
